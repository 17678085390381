import { message } from "antd";
import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { TIME } from "../../util";

import {
    setLoadingorderComment,
    unsetLoadingorderComment,
} from "./style";

const { lastNDay, currentTimeMilliSecond } = TIME;

export const get_order_comment_request = (ordid) => (dispatch) => {

    dispatch(setLoadingorderComment());
  
    const startTime = lastNDay(90);
    const endTime = currentTimeMilliSecond()

    API.getOrderComment({
        ordid,
        startTime,
        endTime,
    })
    .then((info) => {
        const { status, msg, orderComment } = info;

        if (status === 200) {
            dispatch(get_order_comment_success(orderComment));
            message.success("successfully get comments");
        } else {
            message.warning(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingorderComment());
    });
};
  
  const get_order_comment_success = (orderComment) => {
      return {
          type: ActionTypes.GET_ORDER_COMMENT,
          orderComment,
      };
  };

export const create_order_comment_data = (items) => (dispatch) => {

    dispatch(setLoadingorderComment());
  
    API.createUpdateOrderComment({
        ordid: items.ordid,
        action: "C",
        orderComment: items,
    })
    .then((info) => {
        const { status, msg, orderComment } = info;

        if (status === 200) {
            message.success(msg);
            dispatch(create_order_comment_success(orderComment));
        } else {
            message.warning(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingorderComment());
    });
};
  
const create_order_comment_success = (orderComment) => {
    return {
        type: ActionTypes.CREATE_ORDER_COMMENT,
        orderComment,
    };
};
  
export const edit_order_comment_data = (items) => (dispatch) => {

    dispatch(setLoadingorderComment());

    API.createUpdateOrderComment({
        ordid: items.ordid,
        action: "U",
        items,
    })
    .then((info) => {
        const { status, msg, orderComment } = info;

        if (status === 200) {
            message.success(msg);
            dispatch(edit_order_comment_success(orderComment));
        } else {
            message.warning(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingorderComment());
    });
};

export const edit_order_comment_success = (orderComment) => {
    return {
        type: ActionTypes.EDIT_ORDER_COMMENT,
        orderComment,
    };
};
  
export const archive_order_comment_data = (items) => (dispatch) => {
    
    dispatch(setLoadingorderComment());

    const newItems = {
        ...items,
        active: 0,
    };

    API.createUpdateOrderComment({
        ordid: items.ordid,
        action: "U",
        items: newItems,
    })
    .then((info) => {
        const { status, orderComment, msg } = info;

        if (status === 200) {
            dispatch(edit_order_comment_success(orderComment));
            message.success("Order comment deleted successfully");
        } else {
            message.warning(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingorderComment());
    });
}
  