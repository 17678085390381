import * as ActionTypes from "../action-types"

export const order_completed_defaultState = {
    byID: {},
    allIDs: []
}

export const order_completed = (state = order_completed_defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState;

    switch(action.type) {
        case ActionTypes.GET_ORDER_COMPLETED_THRU_HUBID: {
            /* 
                Initialize the reducer into empty object, 
                since reducer often filled with previous order (order from different status)
            */
            newState.byID = {}

            const tasks = action.tasks

            tasks
            &&
            tasks.length
            &&
            tasks.map(task => {
                const { ordid } = task

                newState.byID[ordid] = task

                if(!newState.allIDs.includes(ordid)) {
                    newState.allIDs.push(ordid)
                }

                return null
            })

            return newState
        }
        default: {
            return state
        }
    }
}