import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space} from 'antd';
// import React, { useRef, useState } from 'react';


export const getColumnSearchProps = (name, handleSearch) => {
//   const searchInput = useRef(null);

  // const handleFilter = (value, record) => {
  //   const text1 = dataIndex1 ? record[dataIndex1].toString().toLowerCase() : '';
  //   const searchTextLower = value.toLowerCase();

  //   return text1.includes(searchTextLower) 
  // };

    /* 
        this handler cannot use the react hooks
        so i tried to not used the hooks, thats why it readjusted  
    */

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div
            style={{
                padding: 5,
            }}
        >
            <Input
                placeholder={"Search"} 
                onChange={(e) => handleSearch(name, e.target.value)}
                onPressEnter={(e) => handleSearch(name, e.target.value)}
                style={{
                    marginBottom: 8,
                    display: 'block',
                }}
            />
            <Space>
            {/* <Button
                type="primary"
                // onClick={(e) => handleSearch(selectedKeys, confirm)}
                onClick={(e) => handleSearch(name, e.target.value)}
                // icon={<SearchOutlined />}
                size="small"
                style={{
                    width: 90,
                }}
            >
                Search
            </Button> */}
            <Button type="link" size="small" style={{ marginLeft: '8vw' }} onClick={() => confirm()}>
                Close
            </Button>
            </Space>
        </div>
        ),
        filterIcon: (filtered) => (
        <SearchOutlined
            style={{
            color: filtered ? '#1677ff' : undefined,
            }}
        />
        ),
        // onFilter: (value, record) => handleFilter(value, record), 
        // onFilterDropdownOpenChange: (visible) => {
        //   if (visible) {
        //     setTimeout(() => searchInput.current?.select(), 100);
        //   }
        // },
    };
};


