import * as ORDER from '../order'

const { ORDER_STATUS } = ORDER

export const SCAN_TABLE_LIST_INDEX = {
    ordid: 'ordid',
    scannedBy: 'scannedBy',
    scanStatus: 'scanStatus',
    orderStatus: 'orderStatus',
    origpid: 'origpid',
    origid: 'origid',
    dstid: 'dstid',
    dstpid: 'dstpid',
    customer: 'customer',
    est_dimension: 'est_dimension',
    est_volume: 'est_volume',
    est_price: 'est_price',
    type: 'type'
}

export const SCAN_TABLE_LIST_TITLE = {
    ordid: 'Scanned Order ID',
    scannedBy: 'Success Check-in By',
    scanStatus: 'Scan Status',
    orderStatus: 'Order Status',
    origpid: 'Origin Point',
    origid: 'Origin Hub',
    dstid: 'Destination Hub',
    dstpid: 'Destination Point',
    customer: 'Customer Name/ID/Contact',
    est_dimension: 'Est. Parameter (L/W/H x W)',
    est_volume: 'Est. Volume (m³)',
    est_price: 'Est. Price (RM)',
    type: 'Type'
}

const SCAN_STATUS_INDEX = {
    SUCCESS: "1",
    FAILED: "0"
} 

export const SCAN_STATUS = {
    [SCAN_STATUS_INDEX.SUCCESS]: {
        color: 'green',
        text: 'SUCCESS'
    }, 
    [SCAN_STATUS_INDEX.FAILED]: {
        color: 'red',
        text: 'FAILED'
    }, 
}

export const PARCEL_TYPE_INDEX = {
    BOX: "001",
    DOCUMENT: "002"
}

export const PARCEL_TYPE = {
    [PARCEL_TYPE_INDEX.BOX]: "Box",
    [PARCEL_TYPE_INDEX.DOCUMENT]: "Document"
}

// export const SCAN_BY_STATUS = {
//     [ORDER_STATUS.PENDING_SELF_LODGE]: {
//         nextStatus: ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB,
//         action: 'check-in'
//     },
//     [ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER]: {
//         nextStatus: ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB,
//         action: 'check-in'
//     },
//     [ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT]: {
//         nextStatus: ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB,
//         action: 'check-in'
//     },
//     [ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB]: {
//         nextStatus: ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB,
//         action: 'check out'
//     },
//     [ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB]: {
//         nextStatus: ORDER_STATUS.PENDING_SELF_COLLECTION,
//         nextStatus2: ORDER_STATUS.PENDING_DOORSTEP_DELIVERY,
//         // action: 'unload'
//         action: 'check in'
//     },
//     [ORDER_STATUS.DEPARTED_FROM_ORIGIN_POINT]: {
//         nextStatus: ORDER_STATUS.PENDING_SELF_COLLECTION,
//         nextStatus2: ORDER_STATUS.PENDING_DOORSTEP_DELIVERY,
//         // action: 'unload'
//         action: 'check out'
//     },
//     [ORDER_STATUS.PENDING_SELF_COLLECTION]: {
//         nextStatus: ORDER_STATUS.FULFILLED,
//         action: 'checkout'
//     },
//     [ORDER_STATUS.PENDING_DOORSTEP_DELIVERY]: {
//         nextStatus: ORDER_STATUS.FULFILLED,
//         action: 'checkout'
//     },
// }