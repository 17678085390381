import React, { useState } from "react";

// Constant
import { TEMPLATE } from "../../../../../../constants";

// Components
import { ButtonLink } from "../../../../../../components";

// Styles
import styles from "../../../../common.module.css";

const { SIDEBAR_MENU } = TEMPLATE.ACCOUNT;

const ContentSidebar = (props) => {
  const { onChangePage } = props;

  const [clickedDiv, setClickedDiv] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState("Profile");

  const changeColor = (div, label) => {
    if (clickedDiv === div && selectedLabel === label) {
      setClickedDiv(null);
      setSelectedLabel(null);
    } else {
      setClickedDiv(div);
      setSelectedLabel(label);
    }
  };

  return (
    <div className={styles["sidebar-nav"]}>
      {SIDEBAR_MENU.map((i, key) => (
        <ButtonLink
          key={key}
          className={styles["sidebar-navlink"]}
          onClick={() => {
            onChangePage(i.onChangeParam);
            changeColor(key + 1, i.label);
          }}
          style={{
            backgroundColor: clickedDiv === key + 1 ? "#fff6ea" : "transparent",
          }}
        >
          {i.icon}
          {i.label}
        </ButtonLink>
      ))}
    </div>
  );
};
export default ContentSidebar;
