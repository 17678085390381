import { useSelector } from "react-redux";

// Components
// import ProfileInfo from "./components/ProfileInfo";
import ProfileUser from "./components/ProfileUser";

// Services
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

// Styles
import styles from "./common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

// const { parseYear } = TIME;

const Profiles = () => {
  const user = useSelector(reduxStoreWrapper("user"));
  // const userRoles = useSelector(reduxStoreWrapper("userRoles.byID"));
//   console.log(user)
  return (
    <div className={styles.container}>
      <ProfileUser user={user} 
      // userRoles={userRoles} 
      />
    </div>
  );
};

export default Profiles;
