import * as XLSX from "xlsx";

const exportToExcel = ({
  data,
  sheetName = "Sheet1",
  filename = "data",
  template = [],
}) => {

  const exportTitleCOLXLSX =
    (template && template.length && template.map((c) => c.title)) || [];
  const exportDataCOLXSX = data.map((d) => {
    return template.map((c) => {
      if (Object.prototype.hasOwnProperty.call(c, "dataIndex")) {
        return c.render(d[c.dataIndex]);
      } else {
        return c.render(d);
      }
    });
  });

  const exportData =
    (template &&
      template.length && [exportTitleCOLXLSX, ...exportDataCOLXSX]) ||
    data;

  const wb = XLSX.utils.book_new();
  const ws =
    (template && template.length && XLSX.utils.aoa_to_sheet(exportData)) ||
    XLSX.utils.json_to_sheet(exportData);
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  XLSX.writeFile(wb, `${filename}.xlsx`);
};

export const handleExportClick = (dataSource) =>
  exportToExcel({
    data: dataSource,
  });

export const exportWithTemplate = ({ data, filename, template }) =>
  exportToExcel({
    data,
    filename,
    template,
  });
