import * as ActionTypes from '../action-types'

const orderLog_defaultState = {
    byID: {},
    allIDs: []
}

export const orderLog = (state = orderLog_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.ORDER_LOG_GET_THRU_ORDID: {
            const orderLogs = action.orderLogs;
            const ordid = action.ordid

            if(ordid) {
                newState.byID[ordid] = orderLogs 

                if(!newState.allIDs.includes(ordid)) newState.allIDs.push(ordid)
            }

            return newState;
        }
        default: {
            return state;
        }
    }
}