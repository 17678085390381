import * as ActionTypes from "../action-types";

const admin_defaultState = {
    byID: {},
    allIDs: [],
};

export const users_admin = (state = admin_defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    if (!action) return newState;

    switch (action.type) {
        case ActionTypes.GET_ALL_ADMINS: {
            const admins = action.admins;

            admins 
            &&
            admins.length 
            &&
            admins.map(admin => {
                const { memid } = admin;

                newState.byID[memid] = admin;

                if(!newState.allIDs.includes(memid)) {
                    newState.allIDs.push(memid)
                }
                
                return null;
                
            });

            return newState;
        }

        default: {
            return state;
        }
    }
};
