// Third-party Library
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, DatePicker, Select, Input } from "antd";

// Constants 
import { TEMPLATE } from "../../../../constants";

// Components
import { BtnSubmit } from "../../../../components"

// Services Library
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles
import style from "../../index.module.css"

const { RangePicker } = DatePicker
const { reduxStoreWrapper } = REDUX_UTIL

const {
    REPORT_TYPE_MODE,
    INPUT_FIELD_INDEX,
} = TEMPLATE.REPORT_HISTORY

const { 
    dayJSMilliSecond,
    lastNDay,
    currentTimeMilliSecond
} = TIME

const InputSearch = () => {

    const dispatch = useDispatch()

    const [ form ] = Form.useForm()

    const hub = useSelector(reduxStoreWrapper('hub'))
    const orders = useSelector(reduxStoreWrapper("order.allOrderIDs"))
    const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"))

    useEffect(() => {
        dispatch(REDUX_ACTION.v1_order.get_order_id_by_hubid_request(hub.hubid))
    }, [])

    const onFinish = (e) => {

        const { type, date, ordids = [], statuses = [] } = e

        const items = {
            hubs: [`${hub.hubid}`], // requirement in Hub/Point portal
            ordids: ordids.includes('all') ? [] : ordids,
            statuses: statuses.includes('all') ? [] : statuses,
            startTime: date?.length && dayJSMilliSecond(date[0]) || lastNDay(90),
            endTime: date?.length && dayJSMilliSecond(date[1]) || currentTimeMilliSecond()
        }

        dispatch(REDUX_ACTION.v1_report.get_report_list_request(items, type))
    }

    const [ mode, setMode ] = useState('') // to remove the status column if the report if scan log

    return (
        <Form
            form={form}
            onFinish={onFinish}
            style={{ minWidth: '75em', width: '100%' }}
        >
            <Row justify={'center'} gutter={15}>
                <Col span={4} style={{ position: 'relative' }}>
                    <label htmlFor="type" className={style["label"]} >Report</label>
                    <Form.Item name='type' rules={[{ required: true }]}>
                        <Select 
                            size="large"
                            options={REPORT_TYPE_MODE}
                            onChange={e => setMode(e)}
                        />
                    </Form.Item>
                </Col>
                <Col span={4} style={{ position: 'relative' }}>
                    <label htmlFor={'hubs'} className={style["label"]}>{'Hub/ Point'}</label>
                    <Input size="large" value={`${hub.code} ${hub?.hubid.includes('hub') ? "Hub" : "Point" || ''}`} readOnly/>
                </Col>
                <Col span={4} style={{ position: 'relative' }}>
                    <label htmlFor={'ordids'} className={style["label"]}>{'Order ID'}</label>
                    <Form.Item name={'ordids'}>
                        <Select 
                            mode="multiple"
                            size="large"
                            options={INPUT_FIELD_INDEX(orders)}
                        />
                    </Form.Item>
                </Col>
                {
                    mode !== 'scan'
                    &&
                    <Col span={4} style={{ position: 'relative' }}>
                        <label htmlFor={'statuses'} className={style["label"]}>{'Status'}</label>
                        <Form.Item name={'statuses'}>
                            <Select 
                                mode="multiple"
                                size="large"
                                options={INPUT_FIELD_INDEX(orderStatuses, 'display', 'status')}
                            />
                        </Form.Item>
                    </Col>
                }
                <Col span={5} style={{ position: 'relative' }}>
                    <label htmlFor="date" className={style["label"]} >Date Range</label>
                    <Form.Item name='date'>
                        <RangePicker size="large" />
                    </Form.Item>
                </Col>
                    <BtnSubmit 
                        text={'Search'}
                        padding="9px 25px"
                        bgColor="var(--themeBackgroundColor)"
                        color="var(--creamColor)"
                    />
            </Row>
        </Form>
    )
}

export default InputSearch