// React Thirty Party Library
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE, ROOT_CONSTANT } from "../../../../../../constants";

// Components Library
import { BtnSubmit, TableList } from "../../../../../../components";

// Handler Library
import { exportHDLR } from "../../../../../../handlers";

// Services Library

// Redux Actions
import { REDUX as REDUX_UTIL, TIME } from "../../../../../../services/util";

// Styles Library
import classes from "../../../../../index.module.css";

const { TABLE_PARK_ORDER_INDEX, TABLE_PARK_ORDER_TITLE } = TEMPLATE.PARKORDER;
const { PARKORDER_SUMMARY_COL } = TEMPLATE.REPORT.PARKORDER;

const { reduxStoreWrapper } = REDUX_UTIL;
const { parseExportTime } = TIME;

const SearchParkedList = (props) => {
  const { filteredData, setFilteredData, dataSource } = props;

    const userList = useSelector(reduxStoreWrapper("users_customer.byID"));
    const admin = useSelector(reduxStoreWrapper('users_admin.byID'))
    const comments = useSelector(reduxStoreWrapper("orderComment"))

  const options = [
    // {
    //   value: TABLE_PARK_ORDER_INDEX.uid,
    //   label: TABLE_PARK_ORDER_TITLE.uid,
    //   type: "text",
    // },
    {
      value: TABLE_PARK_ORDER_INDEX.ordid,
      label: TABLE_PARK_ORDER_TITLE.ordid,
      type: "text",
    },
    {
      value: TABLE_PARK_ORDER_INDEX.createdAt,
      label: "Custom Date",
      type: "timeframe",
    },
  ];
  return (
    <div className={classes.headerPage}>
      <TableList.SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setFilteredData}
        options={options}
        defaultValue={TABLE_PARK_ORDER_INDEX.ordid}
      />
      <div>
      <BtnSubmit
          display={"inline-block"}
          text={"Export"}
          bgColor="transparent"
          color={"#4CAF50"}
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: filteredData,
              filename: `PARKORDER_SUMMARY_${parseExportTime()}`,
              template: PARKORDER_SUMMARY_COL({
                redux: {
                    userList,
                    admin,
                    comments
                },
                exportState: true,
              }),
            })
          }
          showIcon={true}
          icon={ROOT_CONSTANT.IMG.ICON.icon_export}
          icon_alt={"export"}
        />
      </div>
    </div>
  );
};

export default SearchParkedList;
