import { Col, Row } from "antd";

const ProfileData = ({
    forms,
    userRole
}) => {

    const {
        name = "-",
        memid = "-",
        email = "-",
        contact = "-",
        address = "-",
        postcode = "-",
        city = "-",
        state = "-"
    } = forms;

  return (
    <div style={{ padding: "25px", fontSize: "16px" }}>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="userName" style={{ color: "#414141" }}>
                Username
            </label>
            </Col>
            <Col span={16}>{name}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="userRole" style={{ color: "#414141" }}>
                User Role
            </label>
            </Col>
            <Col span={16}>{userRole}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="customerId" style={{ color: "#414141" }}>
                Customer ID
            </label>
            </Col>
            <Col span={16}>{memid}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="email" style={{ color: "#414141" }}>
                Email
            </label>
            </Col>
            <Col span={16}>{email}</Col>
        </Row>
        <Row style={{ marginBottom: "35px" }}>
            <Col span={8}>
            <label htmlFor="contact" style={{ color: "#414141" }}>
                Contact
            </label>
            </Col>
            <Col span={16}>{contact}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="address" style={{ color: "#414141" }}>
                Address
            </label>
            </Col>
            <Col span={16}>{address}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="postCode" style={{ color: "#414141" }}>
                Postcode
            </label>
            </Col>
            <Col span={16}>{postcode}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="city" style={{ color: "#414141" }}>
                City
            </label>
            </Col>
            <Col span={16}>{city}</Col>
        </Row>
        <Row style={{ marginBottom: "8px" }}>
            <Col span={8}>
            <label htmlFor="state" style={{ color: "#414141" }}>
                State
            </label>
            </Col>
            <Col span={16}>{state}</Col>
        </Row>
    </div>
  );
};

export default ProfileData;
