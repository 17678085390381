import { message } from "antd";

import * as API from "../../api";
import * as ActionTypes from "../action-types";

// Neccessary Actions
import { TIME } from "../../util";

import {
    setLoadingGetOrderThruScan,
    unsetLoadingGetOrderThruScan
} from "./style"

const { lastNDay, currentTimeMilliSecond } = TIME;


export const add_order_thru_scan_request = (items, prevStatus, hubid) => dispatch => {

    dispatch(setLoadingGetOrderThruScan())

    API.addOrderThruScan(
        items
    )
    .then(info => {
        const { status, msg } = info

        if(status === 200) {
            setTimeout(() => {
                dispatch(get_order_thru_scan_timeframe_request(hubid, prevStatus))
            }, 3000)
            message.success(msg)
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
        dispatch(unsetLoadingGetOrderThruScan())
    })
}

export const get_order_thru_scan_timeframe_request = (hubid, status) => dispatch => {

    dispatch(setLoadingGetOrderThruScan())

    API.getOrderThruScan({
        hubid,
        status,
        startTime: lastNDay(90),
        endTime: currentTimeMilliSecond()
    })
    .then(info => {
        const {status, scanned, msg}  = info

        if(status === 200) {
            dispatch(get_order_request_thru_scan_success(scanned))
            message.success(msg)
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
        dispatch(unsetLoadingGetOrderThruScan())
    })
}

const get_order_request_thru_scan_success = (order) => {
    return {
        type: ActionTypes.GET_ORDER_THRU_SCAN,
        order
    }
}

export const clear_order_request_thru_scan_request = (value, cb = () => {}) => dispatch => {
    API.clearOrderThruScan({
        scanned: value
    })
    .then(info => {
        const { msg, status } = info
        
        if(status === 200) {
            dispatch(clear_order_request_thru_scan_success())
        } else {
            message.warning(msg)
        }
    })
    .finally(() => {
        cb()
    })

}

const clear_order_request_thru_scan_success = () => {
    return {
        type: ActionTypes.CLEAR_ORDER_THRU_SCAN,
    }
}