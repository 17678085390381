// React Thirty Party Library
import { useNavigate } from "react-router";
import { Tag } from "antd";

// Constant
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../constants";
import { IMG } from "../../../../../constants";

// Components
import { ButtonLink } from "../../../components";

// Service
import { TIME } from "../../../services/util";

const {
  TABLE_PARK_ORDER_TITLE,
  TABLE_PARK_ORDER_INDEX,
  OPTION_PARK_ORDER_STATUS_LABEL,
} = TEMPLATE.PARKORDER;
const { ORDER_STATUS } = TEMPLATE.ORDER;

const { ROUTE_URL } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;

const { parseTimeMin } = TIME;

const { icon_comment_parcel } = IMG.ICON;

export const PARKORDER_SUMMARY_COL = ({
  redux = {},
  func = {},
  exportState = false,
}) => {

  const { user, userList, admin, comments } = redux;

  const { setIsOpenComment, setData, NavigateOrderSummary } = func;

  return [
    {
      title: TABLE_PARK_ORDER_TITLE.oparkid,
      key: TABLE_PARK_ORDER_INDEX.parking_ticket,
      width: 150,
      fixed: "left",
      render: (record) => {
        const { oparkid } = record;
        return exportState ? oparkid : <p>{oparkid}</p>;
      },
    },
    {
      title: TABLE_PARK_ORDER_TITLE.ordid,
      key: TABLE_PARK_ORDER_INDEX.ordid,
      width: 150,
      fixed: "left",
      render: (record) => {
        const { ordid } = record;
        return exportState ? (
          ordid
        ) : (
          <ButtonLink onClick={() => NavigateOrderSummary(ordid)}>
            {ordid}
          </ButtonLink>
        );
      },
    },
    {
      title: TABLE_PARK_ORDER_TITLE.status,
      key: TABLE_PARK_ORDER_INDEX.status,
      render: (record) => {
        const { status } = record;
        const { tag, color } = OPTION_PARK_ORDER_STATUS_LABEL[status];

        return exportState ? tag : <Tag color={color}>{tag}</Tag>;
      },
    },
    {
      title: `${TABLE_PARK_ORDER_TITLE.custid}/${TABLE_PARK_ORDER_TITLE.name}/${TABLE_PARK_ORDER_TITLE.contact}`,
      key: TABLE_PARK_ORDER_INDEX.custid,
      width: 220,
      render: (record) => {
        const { uid } = record;
        const userDetails = Object.values(userList).find((p) => p.uid === uid);
        if (userDetails) {
          const { memid, name, contact } = userDetails;
          return exportState ? (
            `${memid} / ${name} / ${contact}`
          ) : (
            <p>
              {memid}/{name}/<br />
              {contact}
            </p>
          );
        }
      },
    },
    {
      title: TABLE_PARK_ORDER_TITLE.remark,
      key: TABLE_PARK_ORDER_INDEX.remark,
      render: (record) => {
        const { remark } = record;
        return exportState ? remark : <p>{remark || '-' }</p>;
      },
    },
    {
      title: TABLE_PARK_ORDER_TITLE.createdAt,
      key: TABLE_PARK_ORDER_INDEX.createdAt,
      render: (record) => {
        const { createdAt } = record;
        return exportState ? (
          parseTimeMin(createdAt)
        ) : (
          <p>{parseTimeMin(createdAt)}</p>
        );
      },
    },
    {
      title: TABLE_PARK_ORDER_TITLE.createdBy,
      key: TABLE_PARK_ORDER_INDEX.updatedBy,
      render: (record) => {
        const { updatedBy } = record;

        return <p>{updatedBy}/{admin[updatedBy]?.name || '-'}
        </p>;
      },
    },
    {
      title: TABLE_PARK_ORDER_TITLE.updatedAt,
      key: TABLE_PARK_ORDER_INDEX.updatedAt,
      render: (record) => {
        const { updatedAt } = record;
        return exportState ? (
          parseTimeMin(updatedAt)
        ) : (
          <p>{parseTimeMin(updatedAt)}</p>
        );
      },
    },
    {
      title: TABLE_PARK_ORDER_TITLE.updatedBy,
      // dataIndex: TABLE_PARK_ORDER_INDEX.updatedBy,
      key: TABLE_PARK_ORDER_INDEX.updatedBy,
      render: () => {
        // const { memid, name } = user;
        // return exportState ? (
        //   `${memid} / ${name} `
        // ) : (
        //   <p>
        //     {memid}/<br />
        //     {name}
        //   </p>
        // );
        return <p>{"-"}</p>
      },
    },
    {
      title: TABLE_PARK_ORDER_TITLE.resolved_remarks,
    //   dataIndex: TABLE_PARK_ORDER_INDEX.resolved_remarks,
      key: TABLE_PARK_ORDER_INDEX.resolved_remarks,
        render: () => {
        //   const { resolved_remarks } = user;
        //   return exportState ? (
        //     resolved_remarks || "-"
        //   ) : (
            return <p>{"-"}</p>
        //   );
        },
    },
    {
      title: TABLE_PARK_ORDER_TITLE.comments,
      key: TABLE_PARK_ORDER_INDEX.comments,
      width: 180,
      render: (render) => {
        const { ordid, status } = render;

        const handleComment = () => {
          setIsOpenComment(true);
          setData({ ordid, status: ORDER_STATUS.PARKED });
        };

        const commentsLength = comments[ordid]?.length || 0

        return (
          <button style={{ display: "flex", alignItems: "center", backgroundColor: "transparent", gap: "5px"}} onClick={handleComment}>
            <>{commentsLength}</>
            <img src={icon_comment_parcel} alt="" />
          </button>
        )
      },
    },
  ];
};
