import styles from "../../../../common.module.css";

const ProfileAction = (props) => {
  const { isEdited, setIsEdited, icon_edit, handleSubmit } = props;

  return (
    <div className={styles["form-action"]}>
      <h1 style={{ fontSize: "22px" }}>Basic Information</h1>

      <div className={styles.right}>
        {isEdited ? (
          <>
            <button
              type="submit"
              onClick={()=> handleSubmit()}
              // onClick={() => setIsEdited(false)}
              className={styles["reset-button"]}
              style={{ color: "#FF5F1F" }}
            >
              Save
            </button>
            <button
              className={styles["reset-button"]}
              onClick={() => setIsEdited(false)}
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className={styles["reset-button"]}
              onClick={() => setIsEdited(true)}
            >
              <img src={icon_edit} alt="" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileAction;
