// Third-party Library
import { Upload, message } from "antd";

// Handler
import { uploadHDLR } from "../../../../../handlers";

// Styles
import "../index.css";

const { 
    getBase64, 
    put2AssetS3OPCommentAttachmentRequest 
} = uploadHDLR;

const StatusUpdateAttachment = ({ 
    val,
    setVal, 
    disabled
}) => {

    const validateFileType = (file) => {
        const fileTypes = [
            "image/jpeg",
            "image/jpg",
            "image/png",
            "application/pdf",
        ];
        
        return fileTypes.includes(file.type);
    };

    const handleBeforeUpload = (file) => {
        if (!validateFileType(file)) {
            message.error(`File type ${file.type} is not supported.`);
            return false;
        } else {
            return true;
        }
    };

    const handleChange = (info) => {
        const { status, originFileObj, response = {} } = info.file;

        if (status === "done") {
            getBase64(originFileObj, () => {
                const { Location: url } = response;

                setVal((val) => ({
                    ...val,
                    attachment: [...val.attachment, url]
                }));
            });
        }
    };

    return (
        <Upload
            beforeUpload={handleBeforeUpload}
            onChange={handleChange}
            customRequest={put2AssetS3OPCommentAttachmentRequest}
            listType="picture-card"
            className="attachment"
            disabled={disabled}
        >
            {val.attachment?.length >= 8 ? null : "+ upload"}
        </Upload>
    );
};

export default StatusUpdateAttachment;
