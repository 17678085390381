import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { message } from "antd";

import { TIME } from "../../util"

import {
    setLoadingPending,
    unsetLoadingPending,
    setLoadingCountHub,
    unsetLoadingCountHub,
} from "./style";

const startTime = TIME.lastNDay(90)
const endTime = TIME.currentTimeMilliSecond()

export const get_count_hubs_timeframe_request = (items) => (dispatch) => {
    
    dispatch(setLoadingCountHub());

    API.countTimeframeOrderState({
        ...items,
        startTime,
        endTime
    })
    .then((info) => {
        const { status, ordersCount, msg } = info;

        if (status === 200) {
            dispatch(get_count_hubs_timeframe_success(ordersCount));
        } else {
            message.error(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingCountHub());
    });

};

const get_count_hubs_timeframe_success = (hubs) => {
    return {
        type: ActionTypes.COUNT_HUBS,
        hubs,
    };
};

export const get_active_hubs_request = () => (dispatch) => {

    API.getActiveHubs()
    .then((info) => {
        const { status, hubs, msg } = info;

        if (status === 200) {
            dispatch(get_active_hubs_success(hubs));
        } else {
            message.error(msg);
        }
    });
};

const get_active_hubs_success = (hubs) => {
    return {
        type: ActionTypes.GET_ACTIVE_HUBS,
        hubs,
    };
};

export const get_all_hubs_request = () => (dispatch) => {
    dispatch(setLoadingPending());

    API.getAllHubs()
    .then((info) => {
        const { status, hubs, msg } = info;

        if (status === 200) {
            dispatch(get_all_hubs_success(hubs));
        } else {
            message.error(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingPending());
    });
};

const get_all_hubs_success = (hubs) => {
    return {
        type: ActionTypes.GET_ALL_ORDER_HUBS,
        hubs,
    };
};

// export const get_hub_thru_hubid = (hubid) => (dispatch) => {
    
//     API.getHubByHubid(hubid)
//     .then((info) => {
//         const { status, hub, msg } = info;

//         if (status === 200) {
//             dispatch(get_hub_thru_hubid_success(hub));
//         } else {
//             message.error(msg);
//         }
//     });
// };

// const get_hub_thru_hubid_success = (hub) => {
//     return {
//         type: ActionTypes.GET_HUB_BY_HUBID,
//         hub,
//     };
// };
