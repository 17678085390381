// React Thirty Party Library
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Layout as LayoutAntd } from "antd";
import { useNavigate } from "react-router";

// Constants Library
import * as CONFIG from "../../../config";
import { ROUTES, FEATURE, COMMON_FEATURES } from "../../../constants";

// Components Library
import SidebarMenu from "./SidebarMenu";
import Logo from "./Logo";
import ConfirmLogout from "../../../components/ButtonModal/modules/ConfirmLogout";

// Handler Library

// Services Library

// Redux Actions
import { v1_user } from "../../../services/redux/actions";

// Styles Library
import "./index.css";

const { Sider } = LayoutAntd;

const {
  FEATURE_MENU_ITEMS_OBJ,
  FEATURE_MENU_ITEMS_FIRST_LAYER_GROUP,
  FEATURE_MENU_ITEMS_SECOND_LAYER_GROUP,
} = FEATURE;

const { ROUTE_URL } = ROUTES;

const { FEATURE_TYPE } = COMMON_FEATURES;

const { user_sign_out_request } = v1_user;

const LayoutSidebar = (props) => {
  const { collapsed } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = (st) => {
    setIsModalOpen(st);
  };

  const onClickConfirmLogout = () => dispatch(user_sign_out_request());

  const SIClickHander = (sidebarItem) => {
    const { key } = sidebarItem;

    const { routeType } = FEATURE_MENU_ITEMS_OBJ[key];

    switch (routeType) {
      case FEATURE_TYPE.ROUTE: {
        navigate(ROUTE_URL[key]);
        break;
      }
      case FEATURE_TYPE.LOGOUT: {
        handleModal(true);
        break;
      }
      default: {
        break;
      }
    }

    return 1;
  };

  const sidebarItems = [
    ...FEATURE_MENU_ITEMS_FIRST_LAYER_GROUP,
    {
      key: "dvider",
      label: "dvider",
      icon: <></>,
      type: "divider",
      routeType: "divider",
    },
    ...FEATURE_MENU_ITEMS_SECOND_LAYER_GROUP,
  ];

  return (
    <>
      <Sider
        style={{
          height: "100vh",
          backgroundColor: "#FAFAFA",
          overflowY: "hidden",
          position: "fixed",
          zIndex: "1"
        }}
        width={"28vh"}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <Logo collapsed={collapsed} />
        <SidebarMenu
          sidebarItems={sidebarItems}
          onClick={(item) => SIClickHander(item)}
        />
        {/* <div className="version">V {CONFIG.APP_INFO.APP_VERSION}</div> */}
      </Sider>
      <ConfirmLogout
        isModalOpen={isModalOpen}
        setIsModalOpen={() => setIsModalOpen(false)}
        onClick={() => onClickConfirmLogout()}
      />
    </>
  );
};

export default LayoutSidebar;
