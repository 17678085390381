export const PENDING_TABLE_LIST_INDEX = {
  add_on: "add_on",
  createdAt: "createdAt",
  currency: "currency",
  Delivery_Instruction: "deliveryType",
  customer_instruction: "customerInstruction",
  plate: "plate",
  deliveryType: "deliveryType",
  tripManifest: "tripManifest",
  dstid: "dstid",
  dstpid: "dstpid",
  eta: "eta",
  etd: "etd",
  memid: "memid",
  ordid: "ordid",
  origid: "origid",
  origpid: "origid",
  parcel: "parcel",
  volume: "volume",
  parcelType: "parcelType",
  price: "price",
  priceInfo: "priceInfo",
  status: "status",
  sender: "sender",
  receiver: "receiver",
  sr: 'sr',
  remark: "remark",
  updatedAt: "updatedAt",
  updatedBy: "updatedBy",
  SOPrinted: "SOPrinted",
  actions: "actions",
};

export const PENDING_TABLE_LIST_TITLE = {
  add_on: "Delivery Instruction",
  createdAt: "Created At",
  currency: "currency",
  Delivery_Instruction: "Delivery Instruction",
  customer_instruction: "Customer Instruction",
  plate: "Vehicle / Bus Operator",
  deliveryType: "Delivery Type",
  tripManifest: "tripManifest",
  dstpid: "Destination Point",
  dstid: "Destination Hub",
  eta: "eta",
  etd: "etd",
  memid: "Customer ID/ Name/ Contact",
  ordid: "Order ID",
  origid: "Origin Hub",
  origpid: "Origin Point",
  parcel: "Quantity/ Volume (m³)/ Weight (kg)",
  volume: "Volume (m3)",
  parcelType: "Parcel Type",
  price: "Price (RM)",
  priceInfo: "priceInfo",
  status: "Order Status",
  sender: "Sender Name/ Contact/ Remarks",
  receiver: "Receiver Name/ Contact/ Remarks",
  sr: 'Sender / Receiver',
  remark: "Parcel Remarks",
  updatedAt: "Last Updated At",
  updatedBy: "updatedBy",
  SOPrinted: "SO Printed At",
  actions: "Actions",
};

export const COMPLETED_TABLE_LIST_INDEX = {
  ordid: "ordid",
  status: "status",
  hub: "hub",
  point: 'point',
  init_time: "initiatedAt",
  complete_time: "completedAt",
  duration: "duration",
  updatedAt: "updatedAt",
  createdAt: "createdAt",
  createdBy: "createdBy",
  revision: "revision",
  remark: "remark",
  attachment: "attachment",
};

export const COMPLETED_TABLE_LIST_TITLE = {
  ordid: "Order ID",
  status: "Order Status",
  hub: "Hub",
  point: 'Point',
  init_time: "Initiation Time",
  complete_time: "Completion Time",
  duration: "Duration",
  createdAt: "Created At",
  updatedAt: "Updated At",
  createdBy: "Created By",
  revision: "Revision",
  remark: "Status Remark",
  attachment: "Attachment",
};

export const COMPLETED_STATUS = {
  PENDING_SELF_LODGE: "O0201",
  PENDING_SELF_LODGE_POINT: "O0201",
  PENDING_PICK_UP_FROM_CUSTOMER: "O0202",
  ARRIVED_AT_ORIGIN_HUB: "O0300",
  DEPARTED_FROM_ORIGIN_HUB: "O0400",
  PENDING_SELF_COLLECTION: "O0501",
  PENDING_DOORSTEP_DELIVERY: "O0502",
  FULFILLED: "O0600",
};

export const OPTIONS_COMPLETED_STATUS = {
  [COMPLETED_STATUS.PENDING_SELF_LODGE]: {
    name: "Completed Awaiting Self Lodge",
    colorTag: "blue",
    status: "O0201",
  },
  [COMPLETED_STATUS.PENDING_PICK_UP_FROM_CUSTOMER]: {
    name: "Completed Check in",
    colorTag: "blue",
    status: "O0202",
  },
  [COMPLETED_STATUS.ARRIVED_AT_ORIGIN_HUB]: {
    name: "Completed Check in",
    colorTag: "blue",
    status: "O0300",
  },
  [COMPLETED_STATUS.DEPARTED_FROM_ORIGIN_HUB]: {
    name: "Completed Check In",
    colorTag: "blue",
    status: "O0400",
  },
  [COMPLETED_STATUS.PENDING_SELF_COLLECTION]: {
    name: "Completed Check Out",
    colorTag: "blue",
    status: "O0501",
  },
  [COMPLETED_STATUS.PENDING_DOORSTEP_DELIVERY]: {
    name: "Completed Check Out",
    colorTag: "blue",
    status: "O0502",
  },
};

export const OPTION_DAYS = {
  LAST_30_DAYS: "Last 30 Days",
  LAST_60_DAYS: "Last 60 Days",
  LAST_90_DAYS: "Last 90 Days",
};

export const OPTION_DAYS_VALUE = {
  LAST_30_DAYS: 30,
  LAST_60_DAYS: 60,
  LAST_90_DAYS: 90,
};

export const OPTION_STATUS = {
  Submitted: "Submitted Succesfully",
  Pending: "Pending Self Lodge",
  Received: "Received At Origin Hub",
  Departed: "Departed From Origin Hub",
  Arrived: "Arrived & Ready For Self Collection",
  Collected: "Receiver Collected",
};

export const OPTION_DELIVERY = {
  HubToHub: "Hub to Hub",
  HubtoDelivery: "Hub to Delivery",
};

export const STATUS_DELIVERY = {
  Submitted: "Submitted Succesfully",
  Pending: "Pending Self Lodge",
  Received: "Received At Origin Hub",
  Departed: "Departed From Origin Hub",
  Arrived: "Arrived & Ready For Self Collection",
  Collected: "Receiver Collected",
};

export const DATA_FIELD_VALUES = {
  [PENDING_TABLE_LIST_INDEX.deliveryType]: {
    h2h: "Hub - Hub",
    h2d: "Hub - Doorstep",
    p2p: "Point - Point",
},
  [PENDING_TABLE_LIST_INDEX.add_on]: {
    "svc#dpu": "Doorstep Pick Up",
  },
  [PENDING_TABLE_LIST_INDEX.parcelType]: {
    "001": { name: "Box" },
    "002": { name: "Document" },
  },
};

export const DATA_FIELD_TITLE = {
  [PENDING_TABLE_LIST_INDEX.deliveryType]: {
    h2h: "Sender self drop-off at hub and receiver self collect from hub.",
    h2d: "Sender self-drop off at hub and parcel delivery right to your receiver’s doorstep.",
  },
};

export const OPTION_STATUS_ALL = "ALL";
export const OPTION_DELIVERY_ALL = "ALL";

export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
  label: OPTION_DAYS[option],
  value: OPTION_DAYS_VALUE[option],
}));
