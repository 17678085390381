import { message } from "antd";
import * as API from "../../api";
import * as ActionTypes from "../action-types";

// Redux Action
// Neccessary Actions

import { TIME } from "../../util";

import {
    setLoadingPending,
    unsetLoadingPending,
} from "./style";

const { lastNDay, currentTimeMilliSecond } = TIME;

const startTime = lastNDay(90)
const endTime = currentTimeMilliSecond()


export const get_order_pending_thru_hubid_status_request = (items) => (dispatch) => {

    dispatch(setLoadingPending());

    API.getHubPendingThruHubid({
        ...items,
        startTime,
        endTime
    })
    .then((info) => {
        const { status, orders, msg } = info;

        if (status === 200) {
            dispatch(get_order_pending_thru_hubid_status_success(orders));
            message.success(msg);
        } else {
            message.error(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingPending());
    });
};

const get_order_pending_thru_hubid_status_success = (orders) => {
    return {
        type: ActionTypes.GET_ORDER_THRU_HUBID_STATUS,
        orders,
    };
};


// export const update_order_request = ({ order = {}, statusChange = 0, orderState = {}, update, customer = {}, attachment = []}, statusOd, cb = () => {}) => (dispatch) => {
export const update_order_request = (items) => (dispatch) => {

    dispatch(setLoadingPending());

    API.updateOrder(
        items
    )
    .then((info) => {
        const { status, msg, orderState } = info;

        const { ordid } = orderState

        if (status === 200) {
            dispatch(update_order_success(ordid));
            message.success("Order Updated Successfully");
        } else {
            message.warning(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingPending());
    });
};

const update_order_success = (ordid) => {
    return {
        type: ActionTypes.UPDATE_ORDER_STATUS,
        ordid
    };
};


export const get_shipment_order_thru_ordid_request = (ordid, cb) => dispatch => {

    API.getShipmentOrderByORDID({
        ordid
    })
    .then(info => {
        const { status, shipmentOrder, msg } = info;

        if (status === 200) {
            const { url } = shipmentOrder;

            window.open(url, '_blank', 'noreferrer');
            dispatch(get_shipment_order_thru_ordid_success(shipmentOrder))
        } else {
            message.warning(msg)
        }
    })
    .finally(() => {
        cb()
    })
}

const get_shipment_order_thru_ordid_success = (shipmentOrder) => {
    return {
        type: ActionTypes.GET_SHIPMENT_ORDER_THRU_ORDID,
        shipmentOrder
    };
}

export const get_shipment_order_thru_soid_request = (soid, cb = () => {}) => dispatch => {
    
    API.getShipmentOrderBySOID({
        soid
    })
    .then((info) => {
        const { status, shipmentOrder, msg } = info;
        
        if (status === 200) {
            const { url } = shipmentOrder;

            window.open(url, '_blank', 'noreferrer');
        } else {
            message.warning(msg)
        }
    })
    .finally(() => {
        cb()
    })
}

export const get_order_id_by_hubid_request = (hubid) => dispatch => {

    API.getAllOrderIDByHUBID({
        startTime,
        endTime,
        hubid
    })
    .then(info => {
        const { msg, status, orderids } = info

        if(status === 200) {
            dispatch(get_order_id_by_hubid_success(orderids))
        } else {
            message.warning(msg)
        }
    })
} 

const get_order_id_by_hubid_success = (orderids) => {
    return {
        type: ActionTypes.GET_ORDID_THRU_HUBID,
        orderids
    }
}