// React Thirty Party Library
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library
import { TableList } from "../../..";
import ModalImage from "../ModalImage";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../services/util";
import { useNavigate } from "react-router";

// Styles Library

const { SELECT_OPTION_DAYS } = TEMPLATE.TABLELIST;
const { COMPLETED_TABLE_LIST_INDEX } = TEMPLATE.TABLELIST;
const { COMPLETED_COLUMN } = TEMPLATE.REPORT.COMPLETED;
const { ROUTE_URL } = ROUTES;

const { reduxStoreWrapper } = REDUX_UTIL;

const TableCompleted = ({
    filteredData,
    setFilteredData,
    dataSource,
}) => {
    const navigate = useNavigate()

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);

    const handleModal = (record) => {
        setSelectedRecord(record);
        setIsModalOpen(true);
    };

    const user = useSelector(reduxStoreWrapper("user"));
    const admin = useSelector(reduxStoreWrapper("users_admin.byID"));
    const loading = useSelector(reduxStoreWrapper("style.loading.completed"));
    const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"))

    const admins = dataSource ? admin : null;
    const operator = dataSource ? user : null;
    
    const NavigateOrderSummary = (ordid) => {
        navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY], { state: { ordid } });
    };
    

    const columns = COMPLETED_COLUMN({
        redux: {
            uroleid: user.uroleid,
            admins,
            operator,
            orderStatuses
        },
        func: {
            handleModal,
            NavigateOrderSummary
        },
    });

    const sortedData = filteredData.sort((a, b) => b.updatedAt - a.updatedAt);

    return (
        <>
            <TableList.DataList
                dataSource={dataSource}
                filteredData={sortedData}
                setFilteredData={setFilteredData}
                optionDay={SELECT_OPTION_DAYS}
                dataDate={COMPLETED_TABLE_LIST_INDEX.createdAt}
                defaultValue="Created At"
                columnData={columns.map((c) => ({
                    ...c,
                    textWrap: "word-break",
                    ellipsis: true,
                }))}
                loading={loading}
                x={2100}
                y={1000}
            />
            <ModalImage
                isModalOpen={isModalOpen && selectedRecord}
                setIsModalOpen={() => setIsModalOpen(false)}
                dataOrder={selectedRecord}
            />
        </>
    );
};

export default TableCompleted;
