import * as ActionTypes from '../action-types'

const vehicle_defaultState = {
    byID: {},
    byPlate: {},
    allIDs: [],
}

export const vehicle = (state = vehicle_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch (action.type) {
        case ActionTypes.VEHICLE_GET_ALL: {

            const vehicles = action.vehicle

            vehicles
            &&
            vehicles.length
            &&
            vehicles.map(vehicle => {

                const { vid, plate } = vehicle

                newState.byID[vid] = vehicle
                newState.byPlate[plate] = vehicle

                if(!newState.allIDs.includes(vid)) newState.allIDs.push(vid)

                return null
            })

            return newState;
        }
        default: {
            return state;
        }
    }
}
