import React, { useEffect } from "react";
import { batch, useDispatch } from "react-redux";

// Services
import { FIREBASE } from "../../cloud";
import * as CONFIG from "../../../config";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  getAuth,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  updatePassword,
  updateProfile,
  reauthenticateWithCredential,
} from "firebase/auth";

// Redux Action
import {
  set_auth_user_request,
  unset_auth_user_request,
  set_auth_loading_request,
} from "../../redux/actions";
import { main_user } from "../../../containers/main/services/redux/actions";
import { v1_user } from "../../../containers/v1/services/redux/actions";

const { sso_user_sign_in_request, sso_user_sign_out_request } = main_user;
const { user_sign_out_request } = v1_user;

export const sign_in = async (email, password) =>
  signInWithEmailAndPassword(FIREBASE.auth, email, password)
    .then((result) => {
      return {
        status: 200,
        data: {
          emailVerified: result.user.emailVerified,
          currentUser: result.user.uid,
          authProvider: CONFIG.APP_CONFIG.AUTHENTICATION_PROVIDER,
        },
      };
    })
    .catch((err) => {
      return {
        status: 500,
        msg: err.message,
      };
    });

export const sign_out = async () => signOut(FIREBASE.auth);

export const sign_up_user_with_email_password = (email, password) =>
  createUserWithEmailAndPassword(getAuth(), email, password)
    .then((result) => {
      return {
        status: 200,
        data: {
          emailVerified: result.user.emailVerified,
          currentUser: result.user.uid,
          authProvider: CONFIG.APP_CONFIG.AUTHENTICATION_PROVIDER,
        },
      };
    })
    .catch((err) => {
      return {
        status: 500,
        code: err.code,
        msg: err.message,
      };
    });

export const AuthBackgroundTask = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(set_auth_loading_request());

    const listener = onAuthStateChanged(FIREBASE.auth, (user) => {
      const cuser = user || getAuth().currentUser || {};

      const { emailVerified } = cuser;

      // Only email verified authorised user can pass
      // Prevent client self sign up directly pass without email verified
      if (cuser && emailVerified) {
        // User is signed in.

        batch(() => {
          dispatch(set_auth_user_request({ currentUser: cuser.uid }));
          dispatch(sso_user_sign_in_request(cuser.uid, "firebase"));
        });
      } else {
        dispatch(sso_user_sign_out_request());
        dispatch(user_sign_out_request());
        dispatch(unset_auth_user_request());
      }
    });

    return listener;
  });

  return <></>;
};

export const change_password = (email, oldPassword, newPassword) =>
  new Promise((res) => {
    const auth = getAuth();
    const user = auth.currentUser;

    const cred = EmailAuthProvider.credential(email, oldPassword);

    reauthenticateWithCredential(user, cred)
      .then(() => {
        updatePassword(user, newPassword)
          .then(() => {
            res({
              status: 200,
              data: {
                authProvider: CONFIG.APP_CONFIG.AUTHENTICATION_PROVIDER,
              },
            });
          })
          .catch((err) => {
            res({
              status: 500,
              code: err.code,
              msg: err.message,
            });
          });
      })
      .catch((err) => {
        res({
          status: 404,
          code: err.code,
          msg: err.message,
        });
      });
  });
