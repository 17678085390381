// React Thirty Party Library
import { Layout as LayoutAntd } from "antd";

// Constants Library

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import "../../index.css";

import "./index.css";

const { Content } = LayoutAntd;

const LayoutPages = (props) => {
  return (
    <>
      <Content
        className="app-content"
        style={{
          width: props.collapsed ? "calc(100% - 80px)" : "calc(100% - 28vh)",
          transition: "width 0.2s",
        }}
      >
        {props.children}
      </Content>
    </>
  );
};

export default LayoutPages;
