import { FEATURE_NAME } from "../../common/features";

export const DATA_FIELD = {
  memid: "memid",
  deliveryType: "deliveryType",
  add_on: "add_on",
  parcelType: "parcelType",
  parcel_weight: "parcel_weight",
  parcel_length: "parcel_length",
  parcel_width: "parcel_width",
  parcel_height: "parcel_height",
  parcel_desc: "parcel_desc",
  parcel_remark: "parcel_remark",
  origid: "origid",
  etd: "etd",
  dstid: "dstid",
  dst_detail: "dst_detail",
  orig_detail: "orig_detail",
  eta: "eta",
  price: "price",
  state: "state",
};

export const DATA_FIELD_VALUES = {
  [DATA_FIELD.deliveryType]: {
    h2h: "Hub to Hub",
    h2d: "Hub to Doorstep",
    p2p: "Point to Point",
  },
  [DATA_FIELD.add_on]: {
    "svc#dpu": "Doorstep Pick Up",
  },
  [DATA_FIELD.parcelType]: {
    "001": { name: "Box" },
    "002": { name: "Document" },
  },
};

export const ORDER_DATA_TITLE = {
  [DATA_FIELD.memid]: "Customer Membership ID",
  [DATA_FIELD.deliveryType]: "Delivery Instruction",
  [DATA_FIELD.origid]: "Origin",
  [DATA_FIELD.etd]: "ETD",
  [DATA_FIELD.dstid]: "Destination",
  [DATA_FIELD.eta]: "ETA",
  [DATA_FIELD.add_on]: "Additional Delivery Service",
  [DATA_FIELD.parcelType]: "Parcel Type",
  [DATA_FIELD.parcel_weight]: "Parcel Weight (kg)",
  [DATA_FIELD.parcel_length]: "Parcel Length (cm)",
  [DATA_FIELD.parcel_width]: "Parcel Width (cm)",
  [DATA_FIELD.parcel_height]: "Parcel Height (cm)",
  [DATA_FIELD.price]: "Total Price (RM)",
};

export const STATE = {
  JOHOR: {
    name: "Johor",
    code: "JHR",
  },
  KEDAH: {
    name: "Kedah",
    code: "KDH",
  },
  KELANTAN: {
    name: "Kelantan",
    code: "KTN",
  },
  KUALA_LUMPUR: {
    name: "WP Kuala Lumpur",
    code: "KUL",
  },
  SELANGOR: {
    name: "Selangor",
    code: "SGR",
  },
  LABUAN: {
    name: "WP Labuan",
    code: "LBN",
  },
  MELAKA: {
    name: "Melaka",
    code: "MLK",
  },
  NEGERI_SEMBILAN: {
    name: "Negeri Sembilan",
    code: "NSN",
  },
  PAHANG: {
    name: "Pahang",
    code: "PHG",
  },
  PENANG: {
    name: "Penang",
    code: "PNG",
  },
  PERAK: {
    name: "Perak",
    code: "PRK",
  },
  PERLIS: {
    name: "Perlis",
    code: "PLS",
  },
  PURTAJAYA: {
    name: "WP Putrajaya",
    code: "PJY",
  },
  SABAH: {
    name: "Sabah",
    code: "SBH",
  },
  SARAWAK: {
    name: "Sarawak",
    code: "SWK",
  },
  TERENGGANU: {
    name: "Terengganu",
    code: "TRG",
  },
};

export const STATE_BYID = Object.fromEntries(
  Object.values(STATE).map((state) => [
    state.code,
    { name: state.name, code: state.code },
  ])
);

export const STATE_EX = {
  OTHERS: {
    name: "Others",
    code: "OTH",
  },
};

export const STATE_WITH_EX = {
  ...STATE,
  ...STATE_EX,
};

export const STATE_NAME = Object.fromEntries(
  Object.keys(STATE_WITH_EX).map((k) => [k, k])
);

export const HUBS = {
  OTHERS: {
    hubid: "hub#others",
    name: "Others",
    code: "OTH",
    biz_hrs: {
      day: "*",
      time: "*",
    },
  },
};

// export const SpecialFieldHandler = ["parcel", "sender", "receiver"];

export const PRICING_LIST = {
  columns: [
    {
      title: "L + W + H",
      key: "lwh",
      dataIndex: "lwh",
      align: "center",
    },
    {
      title: "Price (RM)",
      key: "price",
      dataIndex: "price",
      align: "center",
    },
  ],
  data: [
    {
      key: 1,
      lwh: "0 - 120",
      price: "25",
    },
    {
      key: 2,
      lwh: "121 -130",
      price: "35",
    },
    {
      key: 3,
      lwh: "131 - 140",
      price: "55",
    },
    {
      key: 4,
      lwh: "141 - 150",
      price: "75",
    },
    {
      key: 5,
      lwh: "151 - 160",
      price: "95",
    },
  ],
};

export const ORDER_STATUS = {
  SUBMITTED: "O0100",
  PENDING_SELF_LODGE: "O0201",
  PENDING_SELF_LODGE_POINT: "O1200",
  PENDING_PICK_UP_FROM_CUSTOMER: "O0202",
  CHECK_OUT_FROM_ORIGIN_POINT: "O0203",
  ARRIVED_AT_ORIGIN_HUB: "O0300",
  DEPARTED_FROM_ORIGIN_HUB: "O0400",
  PENDING_SELF_COLLECTION: "O0501",
  PENDING_DOORSTEP_DELIVERY: "O0502",
  ARRIVED_AT_DESTINATION_HUB: "O0503",
  PENDING_SELF_COLLECTION_POINT: "O1500",
  FULFILLED: "O0600",
  ARRIVED_AT_ORIGIN_POINT: "O1300",
  DEPARTED_FROM_ORIGIN_POINT: "O1400",
  CLOSED: "O4100",
  PARKED: "O4200",
  CANCELLED: "O4300",
  COMPLETED: "O2000",
};

export const ALL_STATUSES_DASHBOARD = [
  ORDER_STATUS.PENDING_SELF_LODGE,
  ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER,
  ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB,
  ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB,
  ORDER_STATUS.PENDING_SELF_COLLECTION,
  ORDER_STATUS.PENDING_DOORSTEP_DELIVERY,
  ORDER_STATUS.PARKED,
];

export const HUBS_HUBID = (hubs) =>
  Object.fromEntries(Object.values(hubs).map((v) => [v.hubid, v]));

export const PARCELS_TOTAL_CALCULATION = (parcels) => {
  const total_volumes =
    parcels.reduce((total, parcel) => {
      const parcelVolume = parcel.width * parcel.height * parcel.length;
      const parcelTotal = parcelVolume * parcel.qty;

      return total + parcelTotal;
    }, 0) / 1000000 || 0;

  const total_pieces =
    parcels.reduce((total, parcel) => {
      return total + parseInt(parcel.qty);
    }, 0) || 0;

  const total_weights =
    parcels.reduce((total, parcel) => {
      const parcelWeight = parcel.weight * parcel.qty;
      return total + parcelWeight;
    }, 0) || 0;

  return { total_volumes, total_pieces, total_weights };
};
