import * as API_CONSTANT from "../constants";

export const getHubThruOperatorUID = (uid) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/hub/get/thru/op/uid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
    }),
  }).then((res) => res.json());

export const countTimeframeOrderState = (items) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/order/state/count/get/hubid/status`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items),
  }).then((res) => res.json());

export const getActiveHubs = () =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/hub/get/active`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({}),
  }).then((res) => res.json());

export const getHubByHubid = (hubid) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/hub/get/hubid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({ hubid }),
  }).then((res) => res.json());
