// React Thirty Party Library
import React, { useState } from "react";

// Constants Library
import { TEMPLATE } from "../../constants";

// Components Library
import Sidebar from "./components/Sidebar";
import Profiles from "./components/Profiles";
import ChangePassword from "./components/ChangePassword";

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import styles from "./common.module.css";

const { SIDEBAR_FEATURE } = TEMPLATE.ACCOUNT;

const MyAccount = () => {
  const [currentPage, setCurrentPage] = useState(<Profiles />);

  const onChangePage = (p) => {
    let componentChanged;

    switch (p) {
      case SIDEBAR_FEATURE.PROFILE: {
        componentChanged = <Profiles />;
        break;
      }
      case SIDEBAR_FEATURE.CHANGE_PASSWORD: {
        componentChanged = <ChangePassword onChangePage={onChangePage} />;
        break;
      }
      default:
        break;
    }

    setCurrentPage(componentChanged);
  };

  return (
    <div className={styles.container}>
      <Sidebar onChangePage={onChangePage} />
      <div className={styles["container-routes-content"]}>{currentPage}</div>
    </div>
  );
};

export default MyAccount;
