import { message } from "antd";

import * as API from "../../api";
import * as ActionTypes from "../action-types";

// Neccessary Actions
import { TIME } from "../../util";

import {
    setLoadingReport,
    unsetLoadingReport
} from "./style"

export const get_report_list_request = (items, type) => dispatch => {

    dispatch(setLoadingReport())

    API
    .getReportList({
        ...items,
        type
    })
    .then(info => {
        const { status, msg, reports } = info

        if(status === 200) {
            message.success(msg)
            dispatch(get_report_list_success(reports, type))
        } else {
            message.error(msg)
        }
    })
    .finally(() => {
        dispatch(unsetLoadingReport())
    })

}

export const get_scan_log_report_list_request = (items, type) => dispatch => {

    console.log(items)

    // API.getReportScanLog(
    //     items
    // )
    // .then(info => {
    //     const { status, msg, report } = info

    //     console.log(info)

    //     if(status === 200) {
    //         // dispatch(get_report_list_success(report.scanLogs))
    //     }
    // })

}

export const get_status_log_report_list_request = (items, type) => dispatch => {

    // console.log(items)

    API.getReportStatusLog(
        items
    )
    .then(info => {
        const { status, msg, report } = info

        console.log(info)

        if(status === 200) {
            // dispatch(get_report_list_success(report.statusLogs))
        }
    })

}

const get_report_list_success = (reports, rtype) => {
    return {
        type: ActionTypes.GET_REPORT_LIST,
        reports, rtype
    }
}