// Third-party Library 
import { Tag } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons';

// Constants 
import { TEMPLATE } from "../..";

// Components
import { ButtonLink } from "../../../components";

// Handlers
import { filter } from "../../../handlers"

// Service
import { TIME } from "../../../services/util";

const { filterHDLR, searchHDLR } = filter

const { 
    parseTimeMinDayFirst 
} = TIME;

const {
    SCAN_TABLE_LIST_INDEX,
    SCAN_TABLE_LIST_TITLE,
    SCAN_STATUS,
} = TEMPLATE.SCAN

export const ORDER_SCAN_COLUMN = ({
    redux = {},
    func = {}
}) => {

    const {
        customer = {},
        hub = {},
        point = {},
        orderStatuses = {}
    } = redux

    const {
        NavigateOrderSummary,
        handleSearch
    } = func

    return [
        {
            title: SCAN_TABLE_LIST_TITLE.ordid,
            key: SCAN_TABLE_LIST_TITLE.ordid,
            width: 170,
            fixed: "left",
            render: (record) => {

                const { ordid, createdAt } = record

                return (
                    <div style={{display: 'flex', gap: '9px'}}>
                        <ButtonLink onClick={() => NavigateOrderSummary(ordid, createdAt)}>{ordid}</ButtonLink>
                        <CheckCircleFilled style={{color: record.order.sorted ? '#198754' : '#adb5bd'}}/>
                    </div>
                )
            },
            sorter: (a, b) => {
                a = parseInt(a.ordid.replace('P', ''));
                b = parseInt(b.ordid.replace('P', ''));
              
                return a - b;
            },
        },
        {
            title: SCAN_TABLE_LIST_TITLE.orderStatus,
            key: SCAN_TABLE_LIST_TITLE.orderStatus,
            width: 450,
            render: (record) => {

                const { orderStatus } = record
                
                const statusDisplay = orderStatuses[orderStatus]?.display.includes('Pending') ? orderStatuses[orderStatus].display.replace('Pending', 'Completed') : `Completed ${orderStatuses[orderStatus]?.display || '-'}`
                
                return (
                    <Tag color={orderStatuses[orderStatus]?.colorTag}>
                        {statusDisplay?.toUpperCase() || '-'}
                    </Tag>
                )
            },
            filters: Object.values(orderStatuses)?.map(status => ({ 
                text: 
                    <Tag color={status.colorTag}>
                        {   
                            status.display.includes('Pending') ? status.display.replace('Pending', 'Completed') : `Completed ${status.display}`
                        }
                    </Tag>, 
                value: status.status 
            })) || [],
            onFilter: (status, record) => record.orderStatus === status,
        },
        {
            title: SCAN_TABLE_LIST_TITLE.scanStatus,
            key: SCAN_TABLE_LIST_TITLE.scanStatus,
            width: 110,
            render: (record) => {

                const { scanStatus } = record

                return (
                    <Tag color={SCAN_STATUS[scanStatus]?.color}>
                        {SCAN_STATUS[scanStatus]?.text}
                    </Tag>
                )
            }
        },
        {
            title: SCAN_TABLE_LIST_TITLE.origpid,
            key: SCAN_TABLE_LIST_TITLE.origpid,
            width: 180,
            render: (record) => {
                
                const { origpid, etd } = record.order

                return origpid && (
                    <>
                        <p>{point[origpid]?.code || '-'}</p>
                        <p>ETD: {parseTimeMinDayFirst(etd)}</p>
                    </>
                )
            },
            filters: filterHDLR.getColumnsFilterHub(point),
            onFilter: (value, record) => record.origpid === value,
        },
        {
            title: SCAN_TABLE_LIST_TITLE.origid,
            key: SCAN_TABLE_LIST_TITLE.origid,
            width: 180,
            render: (record) => {
                
                const { origid, etd, origpid } = record.order

                return (
                    <>
                        <p>{hub[origid]?.code}</p>
                        <p>ETD: {!origpid && parseTimeMinDayFirst(etd) || '-'}</p>
                    </>
                )
            },
            filters: filterHDLR.getColumnsFilterHub(hub),
            onFilter: (value, record) => record.origid === value,
        },
        {
            title: SCAN_TABLE_LIST_TITLE.dstid,
            key: SCAN_TABLE_LIST_TITLE.dstid,
            width: 180,
            render: (record) => {
                
                const { dstid, eta, dstpid } = record.order

                return (
                    <>
                        <p>{hub[dstid]?.code}</p>
                        <p>ETA: {!dstpid && parseTimeMinDayFirst(eta) || '-'}</p>
                    </>
                )
            },
            filters: filterHDLR.getColumnsFilterHub(hub),
            onFilter: (value, record) => record.dstid === value,
        },
        {
            title: SCAN_TABLE_LIST_TITLE.dstpid,
            key: SCAN_TABLE_LIST_TITLE.dstpid,
            width: 180,
            render: (record) => {
                
                const { dstpid, eta } = record.order

                return dstpid && (
                    <>
                        <p>{point[dstpid]?.code || '-'}</p>
                        <p>ETA: {parseTimeMinDayFirst(eta)}</p>
                    </>
                )
            },
            filters: filterHDLR.getColumnsFilterHub(point),
            onFilter: (value, record) => record.dstpid === value,
        },
        {
            title: SCAN_TABLE_LIST_TITLE.customer,
            key: SCAN_TABLE_LIST_TITLE.customer,
            width: 250,
            render: (record) => {

                const { memid } = record.order

                return (
                    <p>{memid}/ {customer[memid]?.name}/ {customer[memid]?.contact}</p>
                )
            },
            ...searchHDLR.getColumnSearchProps('memid', handleSearch) 
        },
        {
            title: SCAN_TABLE_LIST_TITLE.est_volume,
            key: SCAN_TABLE_LIST_TITLE.est_volume,
            width: 200,
            render: (record) => {
                
                const { parcel = {} } = record.order

                // Just for handle old-format data, soon will be deleted
                const TYPED_PARCEL_DIMENSION_CALCULATION = (parcel) => {
                    const { length, width, height } = parcel

                    return (parseFloat(length) * parseFloat(width) * parseFloat(height)) / 1000000
                }

                // Main function to calculate total dimension
                const DIMENSION_CALCULATION = (parcel) => {
                    
                    const total_dimension = parcel?.reduce((total, parcel) => {
                        const { length, width, height, qty } = parcel
                        
                        const dimension = parseFloat(length) * parseFloat(width) * parseFloat(height)
                        const totalDimension = dimension * parseFloat(qty)

                        return total + totalDimension
                    }, 0) 

                    return total_dimension / 1000000 // cm3 to m3
                }

                // console.log(parcel)

                // when TYPED_PARCEL_DIMENSION_CALCULATION deleted, simplify these variables
                const estVol = !parcel?.desc && DIMENSION_CALCULATION(parcel.unsort) || TYPED_PARCEL_DIMENSION_CALCULATION(parcel) || '-'
                const sortedVol = !parcel?.desc && DIMENSION_CALCULATION(parcel.sort)


                return (
                    <>
                        <p>Est: {estVol || '-'}m³</p>
                        <p>Sorted: {sortedVol || '-'}m³</p>
                    </>
                )
            }
        },
        {
            title: SCAN_TABLE_LIST_TITLE.est_dimension,
            key: SCAN_TABLE_LIST_TITLE.est_dimension,
            width: 240,
            render: (record) => {

                const { sorted, parcel = {}, sort = {} } = record.order

                const { length: est_length, width: est_width, height: est_height, weight: est_weight } = parcel
                // const { length: s_length, width: s_width, height: s_height, weight: s_weight } = sort 

                const LWH_HDLR = (parcel) => {

                    const nLength = parcel?.reduce((total, parcel) => { return total + (parcel.length * parcel.qty) }, 0 ) 
                    const nWidth = parcel?.reduce((total, parcel) => { return total + (parcel.width * parcel.qty) }, 0 ) 
                    const nHeight = parcel?.reduce((total, parcel) => { return total + (parcel.height * parcel.qty) }, 0 ) 
                    const nWeight = parcel?.reduce((total, parcel) => { return total + (parcel.weight * parcel.qty) }, 0 )

                    return { nLength, nWidth, nHeight, nWeight }
                }

                const LWH_sort = !parcel.desc && LWH_HDLR(parcel.sort)
                const LWH_unsort = !parcel.desc && LWH_HDLR(parcel.unsort)
 
                return (
                    <>
                        <p>Est: {LWH_unsort.nLength || est_length || '-'}cm/{LWH_unsort.nWidth || est_width || '-'}cm/{LWH_unsort.nHeight || est_height || '-'}cm x {LWH_unsort.nWeight || est_weight ||  '-'}kg</p>
                        <p>
                            Sorted: {
                                sorted
                                &&
                                <>{LWH_sort.nLength || '-'}cm/{LWH_sort.nWidth || '-'}cm/{LWH_sort.nHeight || '-'}cm x {LWH_sort.nWeight || '-'}kg</>
                                ||
                                '-'
                            }
                        </p>
                    </>
                )
            }
        },
        {
            title: SCAN_TABLE_LIST_TITLE.est_price,
            key: SCAN_TABLE_LIST_TITLE.est_price,
            width: 200,
            render: (record) => {

                const { price, priceSort } = record.order

                return (
                    <>
                        <p>Est: RM {price || '-'}</p>
                        <p>Sorted: RM {priceSort || '-'}</p>
                    </>
                )
            }
        },
        {
            title: SCAN_TABLE_LIST_TITLE.scannedBy,
            key: SCAN_TABLE_LIST_INDEX.scannedBy,
            width: 220,
            fixed: "right",
            render: (record) => {

                const { createdAt, operator } = record
                const { updatedBy } = record.order

                return (
                    <>
                        <p>{updatedBy}/ {operator}</p>
                        <p>{parseTimeMinDayFirst(createdAt)}</p>
                    </>
                )
            },
            ...searchHDLR.getColumnSearchProps('createdBy', handleSearch) 
        }
    ]
}