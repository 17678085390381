import { message } from "antd";
import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { TIME } from "../../util";

import {
    setLoadingCompleted,
    unsetLoadingCompleted,
} from "./style";

const { lastNDay, currentTimeMilliSecond } = TIME;

export const get_order_completed_thru_hubid_status_request = (items) => (dispatch) => {
    
    dispatch(setLoadingCompleted());

    const startTime = lastNDay(90);
    const endTime = currentTimeMilliSecond();

    API.getHubCompletedThruHubid({
        ...items,
        startTime,
        endTime,
    })
    .then((info) => {
        const { status, tasks, msg } = info;

        if (status === 200) {
            dispatch(get_order_completed_thru_hubid_status_success(tasks));
            message.success(msg);
        } else {
            message.error(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingCompleted());
    });
};

const get_order_completed_thru_hubid_status_success = (tasks) => {
    return {
        type: ActionTypes.GET_ORDER_COMPLETED_THRU_HUBID,
        tasks
    };
};
