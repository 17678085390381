import * as ActionTypes from "../action-types";

const customer_defaultState = {
    byID: {},
    allIDs: [],
};

export const users_customer = (state = customer_defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    if (!action) return newState;

    switch (action.type) {
        case ActionTypes.GET_ALL_CUSTOMERS: {
            const customers = action.customers;

            customers 
            &&
            customers.length 
            &&
            customers.map((customer) => {
                const { memid } = customer;

                newState.byID[memid] = customer;

                if(!newState.allIDs.includes(memid)) {
                    newState.allIDs.push(memid)
                }

                return null;
            });

            return newState;
        }
        default: {
            return state;
        }
    }
};
