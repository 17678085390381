import * as ActionTypes from "../action-types";

const hubs_defaultState = {
  byCount: {},
//   byStatus: {},
  byID: {},
//   byHUBID: {},
//   allHub: {},
  allIDs: [],
};

export const hubs = (state = hubs_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.COUNT_HUBS: {
      const hubs = action.hubs;

      hubs &&
        hubs.length &&
        hubs.map((hub) => {
          const { type, count } = hub;

          newState.byCount[type] = count;

          if (!newState.allIDs.includes(type)) newState.allIDs.push(type);

          return null;
        });

      return newState;
    }
    // case ActionTypes.GET_ACTIVE_HUBS: {
    //   const hubs = action.hubs;

    //   hubs &&
    //     hubs.length &&
    //     hubs.map((hub) => {
    //       const { hubid } = hub;

    //       newState.byStatus[hubid] = hub;

    //       return null;
    //     });
    //   return newState;
    // }
    case ActionTypes.GET_ALL_ORDER_HUBS: {
      const hubs = action.hubs;

      hubs &&
        hubs.length &&
        hubs.map((hub) => {
          const { hubid } = hub;

          newState.byID[hubid] = hub;

          return null;
        });

      return newState;
    }
    // case ActionTypes.GET_HUB_BY_HUBID: {
    //   const hub = action.hub;

    //   newState.byHUBID = hub;

    //   return newState;
    // }
    default: {
      return state;
    }
  }
};
