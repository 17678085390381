import { combineReducers } from "redux";
/**
 * Main reducers
 */
import {style} from './style'
import {user} from './user'

export const mainReducer = combineReducers({
    user,
    style
})

export const containerReducer = combineReducers({
    main: mainReducer
})