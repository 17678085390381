import * as ActionTypes from "../action-types";

const orderStatus_defaultState = {
  byID: {},
};

export const orderStatus = (state = orderStatus_defaultState, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.GET_ALL_STATUSES: {
      const orderStatuses = action.orderStatuses;

      orderStatuses &&
        orderStatuses.length &&
        orderStatuses.map((orderStasus) => {
          const { status } = orderStasus;

          newState.byID[status] = orderStasus;

          return null;
        });

      return newState;
    }
    default: {
      return state;
    }
  }
};
