// Third-party Library
import { useState } from "react";
import { useSelector } from "react-redux";
import { Tabs } from "antd";

// Constants
import { TEMPLATE } from "../../../../constants";

// Components
import { Completed, Pending } from "../../../../components";

// Services 
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Styles
import classes from "../../../../pages/index.module.css";

const { ORDER_STATUS } = TEMPLATE.ORDER;
const { reduxStoreWrapper } = REDUX_UTIL

const PendingOutbound = () => {

    const uroleid = useSelector(reduxStoreWrapper('user.uroleid'))

    const [ isCompletedOpened, setIsCompletedOpened ] = useState(false)

    const status = uroleid === 'POP' ? ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT : ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB

    const items = [
        {
            key: "1",
            label: <span>Pending</span>,
            children: (
                <Pending
                    status={status}
                    number={2}
                />
            ),
        },
        {
            key: "2",
            label: <span>Completed</span>,
            children: (
                <Completed
                    status={status}
                    number={2}
                    isCompletedOpened={isCompletedOpened} // to refresh when back-and-forth into Pending tab
                />
            ),
        },
    ];

    return (
        <div className={classes.container}>
            <Tabs defaultActiveKey="1" items={items} onChange={() => setIsCompletedOpened(!isCompletedOpened)}/>
        </div>
    );
};

export default PendingOutbound;
