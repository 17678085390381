import * as ActionTypes from "../action-types"

const scan_defaultState = {
    // byID: {},
    byCreatedAt: {},
    allIDs: []
}

export const order_scan = (state = scan_defaultState, action) => {

    let newState = JSON.parse(JSON.stringify(state));

    if(!action) return newState 

    switch(action.type) {
        case ActionTypes.GET_ORDER_THRU_SCAN: {
            const order = action.order

            // do not delete this first

            // newState.byID = order.map((o, i) => ({
            //     key: i,
            //     ...o
            // }))
            // .sort((a, b) => b.createdAt - a.createdAt)
           

            newState.byCreatedAt = {}

            /* 
                using createdAt because if using ordid, there are some similar ordid 
                in the list of this order_scan
            */

            order 
            &&
            order.length
            &&
            order
                .sort((a, b) => b.createdAt - a.createdAt)
                .map(oscan => {
                    const { createdAt } = oscan

                    newState.byCreatedAt[createdAt] = oscan

                    if(!newState.allIDs.includes(createdAt)) {
                        newState.allIDs.push(createdAt)
                    }
                })
        
            return newState
        }

        case ActionTypes.CLEAR_ORDER_THRU_SCAN: {
            
            newState.byCreatedAt = {}

            return newState
        }

        // case ActionTypes.UPDATE_ORDER_THRU_SCAN: {

        //     const order = action.order

        //     console.log(order)

        //     newState.byID.unshift(order)

        //     return newState
        // }

        default: {
            return state
        }
    }
}