import { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import {
  TableList,
  ButtonLink,
  DrawerComments,
  ProgressTable
} from "../../../../../../components";

import {
  TEMPLATE,
  COMMON_FEATURES, ROUTES
} from "../../../../../../constants";

import * as REDUX_ACTION from "../../../../../../services/redux/actions";

// Handler Library
import { parkOrderHDLR } from "../../../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL } from "../../../../../../services/util";

const { reduxStoreWrapper } = REDUX_UTIL;

const {
  TABLE_PARK_ORDER_INDEX,
  TABLE_PARK_ORDER_TITLE,
  SELECT_OPTION_DAYS,
  OPTION_PARK_ORDER_STATUS,
} = TEMPLATE.PARKORDER;
const { ROUTE_URL } = ROUTES;
const { FEATURE_NAME } = COMMON_FEATURES;
const { ORDER_STATUS } = TEMPLATE.ORDER;
const { PARKORDER_SUMMARY_COL } = TEMPLATE.REPORT.PARKORDER;

const TableParkedList = ({
  filteredData,
  setFilteredData,
  dataSource,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

    const userList = useSelector(reduxStoreWrapper("users_customer.byID"));
    const admin = useSelector(reduxStoreWrapper('users_admin.byID'))
    const hubs = useSelector(reduxStoreWrapper("hubs.byID"));
    const points = useSelector(reduxStoreWrapper("points.byID"))
    const loading = useSelector(reduxStoreWrapper("style.loading.parkOrder"));
    const comments = useSelector(reduxStoreWrapper("orderComment"))

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isOpenComment, setIsOpenComment] = useState(false);
  const [data, setData] = useState({});

  const NavigateOrderSummary = (ordid) => {
    navigate(ROUTE_URL[FEATURE_NAME.ORDER_SUMMARY], { state: { ordid } });
  };

      // Function to go to Timeline page
      const NavigateOrderTimeline = (ordid, status, record = {}) => {
        navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ORDER_TIMELINE], {
            state: { ordid, status, record },
        });
    };

  const columns = [
    ...PARKORDER_SUMMARY_COL({
      redux: {
        userList,
        admin,
        comments
      },
      func: {
        setData,
        setIsOpenComment,
        NavigateOrderSummary
      },
    }),
    {
      title: TABLE_PARK_ORDER_TITLE.actions,
      key: TABLE_PARK_ORDER_INDEX.actions,
      width: 180,
      fixed: "right",
      render: (record) => {
        const { ordid, status, oparkid } = record;

        return (
            <>
                  <ButtonLink
                    onClick={ () =>{
  
                      const poStatus = status === OPTION_PARK_ORDER_STATUS.TO_BE_RESOLVED ? ORDER_STATUS.PARKED : ORDER_STATUS.CLOSED
  
                      !comments[ordid] 
                      && 
                      dispatch(REDUX_ACTION.v1_orderComment.get_order_comment_request(ordid))
                      
                      setIsOpenComment(true)
                      setData({ordid, status: poStatus, label: 'parked', oparkid})
  
                    }}
                  >
                    View/ Add <br />
                    Comment
                  </ButtonLink>
            </>
          );
      },
    },
  ];

  const expandedRowRender = (record) => {
    const { ordid, status, uid } = record;

    const userDetails = Object.values(userList).find(user => user.uid === uid);

    let newRecord = {
      ...record,
      memid: userDetails?.memid,
      status: status === OPTION_PARK_ORDER_STATUS.TO_BE_RESOLVED ? ORDER_STATUS.PARKED : ORDER_STATUS.CLOSED
    }


    return (
      <ProgressTable record={newRecord} hubs={hubs} points={points} NavigateOrderTimeline={NavigateOrderTimeline}/>
    );
  };

  const onSelectRow = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectRow,
  };

  return (
    <div>
      <DrawerComments data={data} isOpen={isOpenComment} setIsOpen={setIsOpenComment}/>
      <TableList.DataList
        dataSource={dataSource}
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        optionDay={SELECT_OPTION_DAYS}
        optionFirst={parkOrderHDLR.parkOrderStatus()}
        dataDate={TABLE_PARK_ORDER_INDEX.createdAt}
        defaultValue="DATE"
        text1="PARK STATUS"
        loading={loading}
        showFirstFilter={true}
        showSecondFilter={false}
        showThirdFilter={false}
        columnData={columns.map((c) => ({
          ...c,
          textWrap: "word-break",
          ellipsis: true,
        }))}
        x={2000}
        expandedRowRender={expandedRowRender}
        rowSelection={rowSelection}
      />
    </div>
  );
};

export default TableParkedList;
