// Third-party Library 
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import useScanDetection from 'use-scan-detection';

// Constants
import { TEMPLATE, COMMON_FEATURES, ROUTES } from "../../constants";

// Components 
import { 
    // ScanPage,
    DrawerHistory,
    HeaderScan,
    TableScan
} from "./components";

// Services Library
import { TIME, REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../services/redux/actions";

// Styles Library
import classes from "../../pages/index.module.css";

const { 
    reduxStoreWrapper 
} = REDUX_UTIL

const { 
    ORDER_SCAN_COLUMN 
} = TEMPLATE.REPORT.SCAN

const { 
    MODALS_ORDER_UPDATE
} = TEMPLATE.MODAL

const { ROUTE_URL } = ROUTES

const Scan = ({ number }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    // States
    const [ isOpenHistory, setIsOpenHistory ] = useState(false)
    const [ ordid, setOrdid ] = useState('')

    const prevOrder = useSelector(reduxStoreWrapper(`order.byID.${ordid}`))

    const customer = useSelector(reduxStoreWrapper("users_customer.byID"))
    const user = useSelector(reduxStoreWrapper("user"))
    const hubid = useSelector(reduxStoreWrapper("hub.hubid"));
    const hub = useSelector(reduxStoreWrapper("hubs.byID"))
    const point = useSelector(reduxStoreWrapper("points.byID"))
    const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"))
    const order_scan = useSelector(reduxStoreWrapper("order_scan.byCreatedAt"))
    const loading = useSelector(reduxStoreWrapper("style.loading.orderThruScan")) 

    /**
     *
     *  Some data initialization
     * 
    **/

    // Some needed variables
    const [ filteredData, setFilteredData ] = useState([])
    const dataSource = Object.values(order_scan)

    useEffect(() => {
        setFilteredData(dataSource)
    }, [dataSource.length])

    // when hubid being reassigned, call get_order_thru_scan
    useEffect(() => {
        dispatch(REDUX_ACTION.v1_scan.get_order_thru_scan_timeframe_request(
            hubid, 
            number
        ))

    }, [hubid])

    /** 
    *
    *  Functions Related to Scan Order Table
    * 
    ***/
        
    // when click ordid, redirect to Order Summary 
    const NavigateOrderSummary = (ordid, createdAt) => {
        navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY], { state: { ordid, createdAt, mode: 'scan' } });
    };

    // handler for search, for filtering data shown in table
    const handleSearch = (name, keyword) => {
        const filteredKeyword = Object.values(order_scan)
            .filter(oscan => name.includes('.') ? oscan['order']['memid'] : oscan[name])
            ?.includes(keyword)

        setFilteredData(filteredKeyword)
    };
    
    const columns = ORDER_SCAN_COLUMN({
            redux: {
                customer,
                hub,
                point,
                orderStatuses
            },
            func: {
                NavigateOrderSummary,
                handleSearch
            }
    })

    /** 
    *
    *  Functions Related to Scan Order actions (CRUD)
    * 
    ***/

    // Some needed variables
    const action = number === 1 ? 'check in' : 'check out'
    const nextStatus = prevOrder && MODALS_ORDER_UPDATE(prevOrder.status, prevOrder.deliveryType, prevOrder.add_on)?.next_status || ''

    // Reusable function to directly call add_order_thru_scan API 
    const addValueHDLR = () => {
    
        const items = {
            ordid,
            hubid, 
            status: nextStatus,
            statusChange: true,
            update: {
                updatedAt: TIME.currentTimeMilliSecond(),
                updatedBy: user.memid
            }
        }
    
        if(nextStatus && ordid) {
            dispatch(REDUX_ACTION.v1_scan.add_order_thru_scan_request(
                items, 
                number,
                hubid
            ))
        } 
        if(!nextStatus){
            message.warning('Please try again, ordid is not detected')
        }
    }

    // Assign Order Scan manually thru input field
    const onFinish = () => {

        addValueHDLR() // Reusable func

    }

    // temporary handling for scan issue. since less efficient, soon will be replaced
    const [ isScan, setIsScan ] = useState(false)

    useEffect(() => {
        if(isScan && ordid) {
            addValueHDLR()
            setIsScan(false)
        }
    }, [isScan]) // end temporary handling

    // Assign order directly thru scanner
    useScanDetection({
        onComplete: (_ordid) => {
            const scordid = _ordid.toUpperCase() // scanned ordid

            /* 
                To handle unwanted keyword came from scanner.
                if no unwanted keyword, the ordid will be passed to 
                add_order_thru_scan_request() redux action
            */ 
            const unwantedKeyword = ["CONTROL", "ARROW", "SHIFT", "VOLUME"]
            const isIncludesUnwantedKeyword = unwantedKeyword.some(k => scordid?.includes(k));

            if(!isIncludesUnwantedKeyword) {
                setOrdid(scordid)
                setIsScan(true)
            }

        },
        minLength: 1 // CODE 128
    });
    
    // Function to call clear table 
    const clearTableHDLR = () => {
        message.loading('Clearing table in progress', 0)

        dispatch(REDUX_ACTION.v1_scan.clear_order_request_thru_scan_request(
            dataSource,
            () => message.destroy()
        ))
    }

    const activeFilteredDatasource = filteredData.filter(data => data.scanStatus === 1)

    return (
        <div className={classes.container}>
            <DrawerHistory 
                isOpen={isOpenHistory}
                setIsOpen={setIsOpenHistory}
                dataSource={dataSource}
            />
            <HeaderScan 
                setOrdid={setOrdid}
                onFinish={onFinish}
                action={action}
                clearTableHDLR={clearTableHDLR}
            />
            <TableScan 
                loading={loading}
                columns={columns}
                dataSource={activeFilteredDatasource}
                action={action}
                setIsOpenHistory={setIsOpenHistory}
            />
        </div>
    )
}

export default Scan