import { ORDER_STATUS } from "../order";

export const MODALS_ORDER_UPDATE = (status, deliveryType, add_on) => {

    let action = {}

    switch(status) {

        case ORDER_STATUS.PENDING_SELF_LODGE: {
                action.action_name = `pending self lodge/ awaiting sender self-lodge to origin hub`,
                action.next_status = ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB
                action.prev_status = ORDER_STATUS.SUBMITTED
            break;
        }

        case ORDER_STATUS.PENDING_SELF_LODGE_POINT: {
                action.action_name = `pending self lodge/ awaiting sender self-lodge to origin point`,
                action.next_status = ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT
                action.prev_status = ORDER_STATUS.SUBMITTED
            break;
        }

        case ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER: {
                action.action_name = `awaiting pick up/ pending pick-up to origin hub`,
                action.next_status = ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB
                action.prev_status = ORDER_STATUS.SUBMITTED
            break;
        }

        case ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB: {
                let tempStatus

                if(deliveryType === 'h2h') {
                    tempStatus = add_on?.includes('svc#dpu') ? ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER : ORDER_STATUS.PENDING_SELF_LODGE
                } else {
                    tempStatus = ORDER_STATUS.CHECK_OUT_FROM_ORIGIN_POINT
                }

                action.action_name = 'received at origin hub/ sort',
                action.next_status = ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB
                action.prev_status = tempStatus
            break;
        }
        
        case ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT: {
                action.action_name = 'received at origin point',
                action.next_status = ORDER_STATUS.CHECK_OUT_FROM_ORIGIN_POINT
                action.prev_status = ORDER_STATUS.PENDING_SELF_LODGE_POINT
            break;
        }

        case ORDER_STATUS.CHECK_OUT_FROM_ORIGIN_POINT: {
                action.action_name = 'departing from origin point',
                action.next_status = ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB
                action.prev_status = ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT
            break;
        }

        case ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB: {
                let tempStatus

                switch (deliveryType) {
                    case 'h2h': 
                        tempStatus = ORDER_STATUS.PENDING_SELF_COLLECTION
                        break;
                    case 'h2d': 
                        tempStatus = ORDER_STATUS.PENDING_DOORSTEP_DELIVERY
                        break;
                    case 'p2p': 
                        tempStatus = ORDER_STATUS.ARRIVED_AT_DESTINATION_HUB
                        break;
                }

                action.action_name = `departing from origin hub`,
                action.next_status = tempStatus
                action.prev_status = ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB

            break;
        }

        case ORDER_STATUS.ARRIVED_AT_DESTINATION_HUB: {
                action.action_name = 'received at destination hub',
                action.next_status = ORDER_STATUS.DEPARTED_FROM_ORIGIN_POINT
                action.prev_status = ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB
            break;
        }

        case ORDER_STATUS.DEPARTED_FROM_ORIGIN_POINT: {
                action.action_name = `departing from destination hub`,
                action.next_status = ORDER_STATUS.PENDING_SELF_COLLECTION_POINT
                action.prev_status = ORDER_STATUS.ARRIVED_AT_DESTINATION_HUB
            break;
        }

        case ORDER_STATUS.PENDING_SELF_COLLECTION: {
                action.action_name = `pending self collect/ arrived and ready for self-collection`,
                action.next_status = ORDER_STATUS.FULFILLED
                action.prev_status = ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB
            break;
        }

        case ORDER_STATUS.PENDING_DOORSTEP_DELIVERY: {
                action.action_name = `awaiting delivery to receiver/ pending delivery to receiver from destination hub`,
                action.next_status = ORDER_STATUS.FULFILLED
                action.prev_status = ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB
            break;
        }

        case ORDER_STATUS.PENDING_SELF_COLLECTION_POINT: {
                action.action_name = `pending self collect/ arrived and ready for self-collection`
                action.next_status = ORDER_STATUS.FULFILLED
                action.prev_status = ORDER_STATUS.DEPARTED_FROM_ORIGIN_POINT
            break;
        }

        case ORDER_STATUS.FULFILLED: {
                action.action_name = `receiver collected`
            break;
        }

        case ORDER_STATUS.PARKED: {
                action.action_name = `parking ticket and the park order closed`
            break;
        }

    }

    return action
};

const STATUSES_IN_ORIGID = [
    // ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB,
    
    // checked
    ORDER_STATUS.PENDING_SELF_LODGE,
    ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER,
    ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB,
    ORDER_STATUS.CHECK_OUT_FROM_ORIGIN_POINT,
]

const STATUSES_IN_ORIGPID = [
    ORDER_STATUS.PENDING_SELF_LODGE_POINT,
    ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT, 
    // ORDER_STATUS.CHECK_OUT_FROM_ORIGIN_POINT,
]

const STATUSES_IN_DSTID = [
    ORDER_STATUS.ARRIVED_AT_DESTINATION_HUB,
    // ORDER_STATUS.DEPARTED_FROM_ORIGIN_POINT,
    ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB,
    
    // checked
    ORDER_STATUS.PENDING_DOORSTEP_DELIVERY,
    ORDER_STATUS.PENDING_SELF_COLLECTION,
    ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB,
]

const STATUSES_IN_DSTPID = [
    ORDER_STATUS.DEPARTED_FROM_ORIGIN_POINT,
    ORDER_STATUS.PENDING_SELF_COLLECTION_POINT,
]

export const NEXT_HUBID = (os, prevOrder) => {

    let nextHubid

    const isHubidOrigid = STATUSES_IN_ORIGID.some(p => p === os)
    const isHubidDstid = STATUSES_IN_DSTID.some(p => p === os)
    const isHubidOrigpid = STATUSES_IN_ORIGPID.some(p => p === os)
    const isHubidDstpid = STATUSES_IN_DSTPID.some(p => p === os)

    // set (in-charge) hubid
    if(isHubidOrigpid) {
        nextHubid = prevOrder?.origpid
    } else if(isHubidOrigid) {
        nextHubid = prevOrder?.origid
    } else if(isHubidDstid) {
        nextHubid = prevOrder?.dstid
    } else if(isHubidDstpid) {
        nextHubid = prevOrder?.dstpid
    } else if(os === ORDER_STATUS.FULFILLED) {
        nextHubid = prevOrder?.status === ORDER_STATUS.PENDING_SELF_COLLECTION_POINT ? prevOrder?.dstpid : prevOrder?.dstid
    }



    return nextHubid
}
