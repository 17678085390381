// Third-party Library
import React, { useState } from "react";
import { Steps, Row, Col, Tag } from "antd";

// Constants
import { TEMPLATE } from "../../../../../../constants";

import { ButtonLink } from "../../../../../../components";
import ModalImage from "../ModalImage/";

// Services
import { TIME } from "../../../../../../services/util";

// Styles
import classes from "../../index.module.css";

const TimelineList = ({ orderLogs, orderStatuses, hubs, record, point }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  const handleModal = (record) => {
    setSelectedRecord(record);
    setIsModalOpen(true);
  };

  const Title = ({ status, updatedAt }) => {
    const statusNameTitle =
      orderStatuses[status] && orderStatuses[status].display;

      const statusNameHub = () => {
        if (status === "O0300" || status === "O0203") {
          return `${statusNameTitle} (${
            hubs[record.origid] && hubs[record.origid].code
          })`;
        } else if (status === "O0400" || status === "O0503") {
          return `${statusNameTitle} (${
            hubs[record.dstid] && hubs[record.dstid].code
          })`;
        } else if (status === "O1200" || status === "O1300") {
          return `${statusNameTitle} (${
            point[record.origpid] && point[record.origpid].code
          })`;
        } else if (status === "O1400" || status === "O1500") {
          return `${statusNameTitle} (${
            point[record.dstpid] && point[record.dstpid].code
          })`;
        }
      };

      const hubName = statusNameHub();
      const statusName =
        status === "O0300" || status === "O0400" || status === "O1200" || status === "O1400" || status === "O0203" || status === "O1300" || status === "O0503" || status === "O1500" ? hubName : statusNameTitle;

    return (
      <div className={classes["timeline-title"]}>
        <h1>{statusName}</h1>
        <p>{TIME.parseTimeMinDayFirst(updatedAt)}</p>
      </div>
    );
  };

  const Description = ({ remark, attachment }) => {
    return (
      <div className={classes["timeline-subtitle"]}>
        <p>Remarks: {remark}</p>
        <p>
          Attachment:
          {attachment?.length > 0 ? (
            <ButtonLink onClick={() => handleModal(attachment)}>
              View
            </ButtonLink>
          ) : (
            "-"
          )}
        </p>
      </div>
    );
  };

  const ologItems =
    orderLogs &&
    orderLogs
      .map((ologItem) => {
        const { status, updatedAt, remark, attachment } = ologItem;

        return {
          title: <Title status={status} updatedAt={updatedAt} />,
          description: <Description remark={remark} attachment={attachment} />,
        };
      })
      .reverse()
      .slice(1);

  return (
    <>
      <Steps
        direction="vertical"
        size="small"
        items={ologItems && ologItems}
        current={ologItems && ologItems.length}
      />
      <ModalImage
        isModalOpen={isModalOpen && selectedRecord}
        setIsModalOpen={() => setIsModalOpen(false)}
        dataOrder={selectedRecord}
      />
    </>
  );
};

export default TimelineList;
