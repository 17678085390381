import React from "react";
import { Routes, Route, useLocation } from "react-router";

// Constant
import { ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components
import { asyncComponent } from "../../../../components";
import { ROOT_PAGES } from "../../../../pages";

// Modules
import Dashboard from "../../../../pages/Dashboard";
import {
    OrderSummary,
    OrderTimeline,
    PendingInbound,
    PendingOutbound,
    ParkedOrder
} from "../../../../pages/Order"
import Scan from "../../../../pages/Scan"
import Report from "../../../../pages/Report"
import MyAccount  from "../../../../pages/MyAccount"


const AsyncNotFoundPage = asyncComponent(() => <ROOT_PAGES.NotFoundPage />);

const { ROUTE_URL } = ROUTES;

const MainRoutes = () => {
    const location = useLocation();

    const { state = {} } = location;
    
    const status = state && state.status;
    const number = state && state.number;
    const ordid = state && state.ordid
    const record = state && state.record;
    const mode = state && state.mode;
    const createdAt = state && state.createdAt

    return (
        <Routes>
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.DASHBOARD]}
                element={<Dashboard />}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ORDER_SUMMARY]}
                element={<OrderSummary ordid={ordid} mode={mode} createdAt={createdAt}/>}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.PENDING_OUTBOUND]}
                element={<PendingOutbound />}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.PENDING_INBOUND]}
                element={<PendingInbound />}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ORDER_TIMELINE]}
                element={<OrderTimeline ordid={ordid} status={status} record={record} />}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.SCAN_CHECK_IN]}
                element={<Scan status={status} number={number} />}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.SCAN_CHECK_OUT]}
                element={<Scan status={status} number={number} />}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.PARKED_ORDER]}
                element={<ParkedOrder />}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.REPORT]}
                element={<Report />}
            />
            <Route
                exact
                path={ROUTE_URL[COMMON_FEATURES.FEATURE_NAME.ACCOUNT_PROFILE]}
                element={<MyAccount />}
            />
            <Route element={<AsyncNotFoundPage />} />
        </Routes>
    );
};
export default MainRoutes;
