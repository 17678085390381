import * as ActionTypes from "../action-types";

const order_park_defaultState = {
	byID: {},
	allIDs: [],
};

export const order_park = (state = order_park_defaultState, action) => {
	let newState = JSON.parse(JSON.stringify(state));

	if (!action) return newState;

	switch (action.type) {
		case ActionTypes.GET_PARKED_ORDER: {
            const order_park = action.parkedOrder
            
            order_park 
            &&
            order_park.length 
            &&
            order_park.map((po) => {
                const order_oparkid = po.oparkid;

                newState.byID[order_oparkid] = po;

                if (!newState.allIDs.includes(order_oparkid))
                    newState.allIDs.push(order_oparkid);

                return null;
            });

            return newState
        }

		default: {
			return state;
		}
	}
};