// React Thirty Party Library
import React, { useState, useEffect } from "react";
import { Table, Pagination, Select } from "antd";

// Constants Library
import { ROOT_CONSTANT } from "../../../constants";

// Components Library
import FilterSelect from "../FilterSelect";
import FilterDays from "../FilterDays";

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import classes from "../index.module.css";

const { icon_filter } = ROOT_CONSTANT.IMG.ICON;

const DataList = ({
  dataSource,
  filteredData,
  setFilteredData,
  loading,
  optionDay,
  optionZero,
  optionFirst,
  optionSecond,
  optionThird,
  optionAll,
  dataDate,
  defaultValue,
  text0,
  text1,
  text2,
  text3,
  showZeroFilter,
  showFirstFilter,
  showSecondFilter,
  showThirdFilter,
  columnData,
  expandedRowRender,
  rowSelection,
  x = true,
  y = true,
  // x,
}) => {

  const [selectedDays, setSelectedDays] = useState("");
  const [selectedZero, setSelectedZero] = useState([]);
  const [selectedFirst, setSelectedFirst] = useState([]);
  const [selectedSecond, setSelectedSecond] = useState([]);
  const [selectedThird, setSelectedThird] = useState([]);

  const handleOptionDays = (value) => {
    setSelectedDays(value);
  };

  const handleOptionZero = (value) => {
    let selected;
    if (Array.isArray(value)) {
      selected = value.find((option) => option === optionZero);
    } else {
      selected = value;
    }
    setSelectedZero(selected);
  };

  const handleOptionFirst = (value) => {
    let selected = value.filter((option) => option !== optionAll);
    setSelectedFirst(selected);
  };

  const handleOptionSecond = (value) => {
    let selected = value.filter((option) => option !== optionAll);
    setSelectedSecond(selected);
  };

  const handleOptionThird = (value) => {
    let selected = value.filter((option) => option !== optionAll);
    setSelectedThird(selected);
  };

  const filterData = () => {
    const now = new Date();
    let filtered = dataSource.filter((item) => {
      const transactedAt = new Date(item[dataDate]);
      const diffTime = now - transactedAt;
      const diffDays = diffTime / (1000 * 60 * 60 * 24);

      let daysSelected = selectedDays.length === 0 || diffDays <= selectedDays;

      let firstSelected =
        selectedFirst.length === 0 ||
        selectedFirst.some((option) => {
          const [t, v] = option.split("#");
          return option === "ALL" || item[t] === v || item[t] === Number(v);
        });

      let secondSelected =
        selectedSecond.length === 0 ||
        selectedSecond.some((option) => {
          const [t, ...vArray] = option.split("#");
          const v = vArray.join("#");
          return option === "ALL" || item[t] === v;
        });

      let ThirdSelected =
        selectedThird.length === 0 ||
        selectedThird.some((option) => {
          const [t, ...vArray] = option.split("#");
          const v = vArray.join("#");
          return option === "ALL" || item[t] === v;
        });

      return daysSelected && firstSelected && secondSelected && ThirdSelected;
    });

    if (selectedZero === "Ascending") {
      filtered.sort((a, b) => a.balance - b.balance);
    } else if (selectedZero === "Descending") {
      filtered.sort((a, b) => b.balance - a.balance);
    }
    setFilteredData(filtered);
  };

  useEffect(() => {
    filterData();
  }, [
    selectedDays,
    selectedZero,
    selectedFirst,
    selectedSecond,
    selectedThird,
  ]);

  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const paginatedData = filteredData.slice(
    (current - 1) * pageSize,
    current * pageSize
  );

  const handlePaginationChange = (page, pageSize) => {
    setCurrent(page);
    setPageSize(pageSize);
  };

  

  // const totalPages = Math.ceil(filteredData.length / pageSize);
  // const pageOptions = [];
  // for (let i = 1; i <= totalPages; i++) {
  //   pageOptions.push(
  //     <Select.Option key={i} value={i}>
  //       {i}
  //     </Select.Option>
  //   );
  // }
  // const handleSelectPage = (page) => {
  //   setCurrent(page);
  // };

  // console.log(selectedFirst)

  return (
    <>
      <div className={classes.selectFilter}>
        <div>
          <img src={icon_filter} className={classes.filterIcon} alt="" />

          <FilterDays
            defaultValue={defaultValue}
            handleOptionDays={handleOptionDays}
            optionDay={optionDay}
          />

          {showZeroFilter && (
            <Select
              placeholder={text0}
              style={{
                width: 150,
              }}
              onChange={handleOptionZero}
              value={selectedZero}
            >
            {optionZero.map((option) => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
            </Select>
          )}

          {showFirstFilter && (
            <FilterSelect
              selection={optionFirst}
              selectedData={selectedFirst}
              onChangeSelect={handleOptionFirst}
              text={text1}
              style={{ width: 180 }}
            />
          )}

          {showSecondFilter && (
            <FilterSelect
              selection={optionSecond}
              selectedData={selectedSecond}
              onChangeSelect={handleOptionSecond}
              text={text2}
              style={{ width: 150 }}
            />
          )}

          {showThirdFilter && (
            <FilterSelect
              selection={optionThird}
              selectedData={selectedThird}
              onChangeSelect={handleOptionThird}
              text={text3}
              style={{ width: 150 }}
            />
          )}
        </div>

        <div className={classes.pagination}>
          <Pagination
            current={current}
            pageSize={pageSize}
            total={filteredData.length}
            onChange={handlePaginationChange}
            showTotal={(total) => (
              <div style={{ fontSize: 13 }}>{total} results </div>
            )}
            showSizeChanger
          />
        </div>
      </div>

      <Table
        className={classes.table}
        rowSelection={rowSelection}
        dataSource={paginatedData}
        columns={columnData}
        pagination={false}
        loading={loading}
        expandable={{
          expandedRowRender,
        }}
        scroll={{
          x: x,
          y:500
          // y: y,
        }}
      />
    </>
  );
};

export default DataList;
