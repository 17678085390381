// React Thirty Party Library

// Constants Library
import { ROOT_CONSTANT } from "../../../../constants";

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import "../../../index.css";

const { title_white, ipick } = ROOT_CONSTANT.IMG.ICON.LOGO;

const Logo = (props) => {
  const { collapsed } = props;
  return (
    <>
      <div className="sidebar-logo-container">
        <img
          src={collapsed ? ipick : title_white}
          alt="IPick Logo"
          className="sidebar-logo"
        />
      </div>
    </>
  );
};
export default Logo;
