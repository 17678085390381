// Third-party Library 
import { Table, Row, Col } from "antd"
import { CheckCircleTwoTone } from "@ant-design/icons";

// Services Library
import { TIME } from "../../../../services/util";

// Styles Library
import style from '../index.module.css'

const TableScan = ({
    columns,
    dataSource,
    action,
    setIsOpenHistory,
    loading
}) => {

    return (
        <div>
            <Row justify={'space-between'}>
                <Col span={8} className={style["tagline"]}>
                    <h1 className={style["tagline-title"]}>Success {action} ({dataSource?.length || 0})</h1>
                    <p>Table will be empty once you click 'clear'</p>
                </Col>
                <Col span={10} onClick={() => setIsOpenHistory(true)} className={style["tagline"]}>
                    {   
                        dataSource?.length > 0
                        &&
                        <div className={style["success-notif"]}>
                            <CheckCircleTwoTone twoToneColor="#52c41a" />
                            <p>{dataSource[0].ordid} was scanned successfully at {TIME.parseTimeMinDayFirst(dataSource[0].createdAt)}</p>
                        </div>
                    }
                </Col>
            </Row>
            
            <Table 
                columns={columns.map((c) => ({
                    ...c,
                    textWrap: "word-break",
                    ellipsis: true,
                }))}
                dataSource={dataSource.map(data => ({ key: data.createdAt, ...data }))}
                pagination={{
                    position: ['bottomRight']
                }}
                scroll={{
                    x: 2300
                }}
                loading={loading}
            />
        </div>
    )
}

export default TableScan