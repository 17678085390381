// React Thirty Party Library
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components Library
import { SearchPending, TablePending } from "./components";

// Services Library
import { REDUX, REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../services/redux/actions";

const { reduxStoreWrapper } = REDUX_UTIL;

const Pending = ({
    status = '',
    // next_status,
    number
}) => {
    const dispatch = useDispatch();

    const pending = useSelector(reduxStoreWrapper(`order.byID`));
    const hubid = useSelector(reduxStoreWrapper('hub.hubid'));
    const vehicle = useSelector(reduxStoreWrapper('vehicle.byPlate'));

    const dataSource = Object.values(pending).map((o) => {
        
        const vehicleData = vehicle?.[o.v_plate] || {};

        return {
        key: o.ordid,
        ...o,
        driver: vehicleData?.driver || "-",
        };
    });

    const [ filteredData, setFilteredData ] = useState(dataSource);
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([])

    // Call API to get needed data by table
    useEffect(() => {
        dispatch(
            REDUX_ACTION.v1_order.get_order_pending_thru_hubid_status_request({
                hubid,
                status: number,
            })
        );
    }, [dispatch, hubid]);

    useEffect(() => {
        dispatch(REDUX_ACTION.v1_vehicle.get_all_vehicle_request());
        return () => {}
    }, [dispatch])

    useEffect(() => {
        if (filteredData !== dataSource) {
            setFilteredData(dataSource);
        }
    }, [dataSource]);

    useEffect(() => {
        setFilteredData(filteredData);
    }, [filteredData]);

    return (
        <>
            <SearchPending
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                dataSource={dataSource}
                status={status}
                number={number}
                selectedRowKeys={selectedRowKeys}
            />
            <TablePending
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                dataSource={dataSource}
                number={number}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                // status={status}
                // next_status={next_status}
            />
        </>
    );
};

export default Pending;
