// React Thirty Party Library
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Result } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

// Constants 
import { TEMPLATE } from "../../constants";

// Components Library
import {
    HubInformation,
    PricingList,
    TopUpInformation,
    DashboardIcon,
    ProgressSummary,
} from "./components";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../services/redux/actions";

// Styles Library
import styles from "../index.module.css";


const { reduxStoreWrapper } = REDUX_UTIL;

// const { ALL_STATUSES_DASHBOARD } = TEMPLATE.ORDER

const Dashboard = () => {
    const dispatch = useDispatch();

    const hubid = useSelector(reduxStoreWrapper("hub.hubid"));
    const loading = useSelector(reduxStoreWrapper("style.loading.hub_count"));
    const uroleid = useSelector(reduxStoreWrapper("user.uroleid"))

    useEffect(() => {

        dispatch(
            REDUX_ACTION.v1_hubs.get_count_hubs_timeframe_request({
                hubid,
                // statuses: ALL_STATUSES_DASHBOARD
            })
        );

        dispatch(REDUX_ACTION.v1_hub.get_hub_thru_hubid(hubid));

        return () => {};

    }, [dispatch, hubid]);

    return (
        <div className={styles.container}>
            <div className={styles["container-head"]}>
                <DashboardIcon hubid={hubid} />
                {!loading && <ProgressSummary />}
            </div>

            {
                !loading 
                &&
                <div className={styles["container-main"]}>
                    <>
                        <div>
                            <TopUpInformation />
                            <HubInformation />
                        </div>
                        <div className={styles["container-submit"]}>
                            <PricingList uroleid={uroleid}/>
                        </div>
                    </>
                </div>
                ||
                <Result
                    icon={<LoadingOutlined style={{ fontSize: 96, color: '#F08800', marginTop: '25vh' }} spin/>}
                    title="Loading..."
                />
            }
        </div>
    );
};

export default Dashboard;
