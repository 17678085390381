import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { SearchParkedList, TableParkedList } from "./components";

import { REDUX as REDUX_UTIL } from "../../../../services/util";

import * as REDUX_ACTION from "../../../../services/redux/actions";

import styles from "../../../../components/TableList/index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const OrderParkedList = () => {
    const dispatch = useDispatch();

    const parkedOrder = useSelector(reduxStoreWrapper("order_park.byID"));

    const dataPO = Object.values(parkedOrder).map((po) => ({
        key: po.oparkid,
        ...po,
    }));
    const dataSource = dataPO.sort((a, b) => b.createdAt - a.createdAt);

    const [filteredData, setFilteredData] = useState(dataSource);

    const hubid = useSelector(reduxStoreWrapper("hub.hubid"));

    useEffect(() => {
        // dispatch(REDUX_ACTION.v1_orderStatus.get_all_statuses_request());
        dispatch(REDUX_ACTION.v1_admin.get_all_admins_request());
        // dispatch(REDUX_ACTION.v1_customer.get_all_customers());
        // dispatch(REDUX_ACTION.v1_hubs.get_all_hubs_request());

        return () => {};
    }, [dispatch]);

    // Call API to get needed data by table
    useEffect(() => {
        dispatch(
            REDUX_ACTION.v1_order.get_order_pending_thru_hubid_status_request({
                hubid,
                status: 3,
            })
        );

    }, [dispatch, hubid]);

    useEffect(() => {
        if (filteredData !== dataSource) {
            setFilteredData(dataSource);
        }
    }, [dataSource]);

    useEffect(() => {
        setFilteredData(filteredData);
    }, [filteredData]);

    return (
        <div className={styles.container}>
            <SearchParkedList
                dataSource={dataSource}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
            />
            <TableParkedList
                dataSource={dataSource}
                filteredData={filteredData}
                setFilteredData={setFilteredData}
            />
        </div>
    );
};

export default OrderParkedList;
