export const TABLE_PARK_ORDER_INDEX = {
    oparkid: "oparkid",
    ordid: "ordid",
    status: "status",
    uid: "uid",
    name: "name",
    custid: "custid",
    contact: "contact",
    remark: "remark",
    createdAt: "createdAt",
    createdBy: "createdBy",
    updatedAt: "updatedAt",
    updatedBy: "updatedBy",
    updatedByName: "updatedByName",
    resolved_at: "resolved_at",
    resolved_remarks: "resolved_remarks",
    resolved_by_id: "IPHQ0001",
    resolved_by_name: "Admin A",
    actions: "actions",
    comments: "comments"
  };
  
  export const TABLE_PARK_ORDER_TITLE = {
    oparkid: "Parking Ticket",
    ordid: "Order ID",
    status: "Park Status",
    name: "Name",
    custid: "Customer ID",
    contact: "Contact",
    remark: "Park Remarks",
    createdAt: "Parked at",
    // updatedAt: "Parked by",
    createdBy: "Parked by",
    updatedAt: "Resolved at",
    updatedBy: "Resolved by",
    resolved_remarks: "Resolve Remarks",
    actions: "Actions",
    comments: "Comments"
  };
  
  export const OPTION_DAYS = {
    LAST_30_DAYS: "Last 30 Days",
    LAST_60_DAYS: "Last 60 Days",
    LAST_90_DAYS: "Last 90 Days",
  };
  
  export const OPTION_DAYS_VALUE = {
    LAST_30_DAYS: 30,
    LAST_60_DAYS: 60,
    LAST_90_DAYS: 90,
  };
  
  export const OPTION_PARK_ORDER_STATUS = {
    CLOSED: "PRK002",
    TO_BE_RESOLVED: "PRK001",
  };
  
  export const OPTION_PARK_ORDER_STATUS_LABEL = {
    [OPTION_PARK_ORDER_STATUS.CLOSED]: { tag: "CLOSE", color: "green" },
    [OPTION_PARK_ORDER_STATUS.TO_BE_RESOLVED]: {
      tag: "TO BE RESOLVED",
      color: "orange",
    },
  };
  
  export const OPTION_PARK_ORDER_STATUS_ALL = "ALL";
  
  export const SELECT_OPTION_DAYS = Object.keys(OPTION_DAYS).map((option) => ({
    label: OPTION_DAYS[option],
    value: OPTION_DAYS_VALUE[option],
  }));
  