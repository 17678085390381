import { message } from "antd";
import * as API from "../../api";
import * as ActionTypes from "../action-types";

export const get_all_admins_request = () => (dispatch) => {
  API.getAllAdmin().then((info) => {
    const { status, users, msg } = info;

    if (status === 200) {
      dispatch(get_all_admins_success(users));
      message.success(msg);
    } else {
      message.error(msg);
    }
  });
};

const get_all_admins_success = (admins) => {
  return {
    type: ActionTypes.GET_ALL_ADMINS,
    admins,
  };
};
