import * as API_CONSTANT from "../constants";

export const getInfoByUser = (uid, DLT = 0) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/user/getInfo`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
      DLT,
    }),
  }).then((res) => res.json());

  export const updateUserInfo = (uid, items) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/operator/update`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      uid,
      items,
    }),
  }).then((res) => res.json());
