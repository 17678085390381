// // React Thirty Party Library
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Steps, Skeleton } from "antd";

// // Constants Library
import { TEMPLATE, ROOT_CONSTANT } from "../../constants";

// // Components Library
import { getTitleCancelPark, getTitleCompleteClose, getTitleDoorstep, getDescDoorstep, getTitleArrive, getDescArrive } from "./title"
import { ButtonLink } from "../../components"

// // Handler Library

// // Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../services/util";

// // Redux Actions
import { v1_orderLog } from "../../services/redux/actions";

// // Styles Library
import classes from "../../pages/index.module.css";
import "./index.css";

const { ORDER_STATUS } = TEMPLATE.ORDER;
// const { MODAL_ACTION } =  TEMPLATE.MODAL
const { reduxStoreWrapper } = REDUX_UTIL;
const { parseTimeMinDayFirst } = TIME;
const { icon_remarks} = ROOT_CONSTANT.IMG.ICON

const ProgressTable = (props) => {
    const {record, hubs, points, NavigateOrderTimeline} = props;
    const {ordid, memid, status: os, dstid, origid, origpid, dstpid} = record
  
  
    const dispatch = useDispatch();
    const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
    const orderLogs = useSelector(reduxStoreWrapper(`orderLog.byID.${ordid}`));
    const loading = useSelector(reduxStoreWrapper("style.loading.orderLog"));
    const user = useSelector(reduxStoreWrapper("user"));
    
    
    const OrderStatusDisplay = (status) =>
    (orderStatuses[status] && orderStatuses[status].display) || status;
  
    const OrderStatusDesc = (status) => {
      const olog =
        status && orderLogs && orderLogs.find((o) => o.status === status);
  
      return (
        <>
          {(olog && (
            <div className="progressContent">
              <div className="progressDesc">
                <p>{olog.updatedBy}</p>
                <p>{parseTimeMinDayFirst(olog.updatedAt)}</p><br/>
              </div>
              {/*{ORDER_STATUS.PARKED && 
              <div className="icons">
                {statusModalAction(status) && (
                  <ButtonModal
                    model={"link"}
                    setmodal={statusModalAction(status)}
                    type="primary"
                    style={{ width: "100%", height: "100%" }}
                    onClick={(value) =>
                      dispatch(REDUX_ACTION.v1_orderLog.update_order_logs_thru_ordid_request({
                      ...olog, 
                      initiatedAt: value && value.initiatedAt,
                      // updatedAt: value && value.updatedAt,
                      updatedBy: value && user.memid,
                      remark: value && value.remark, 
                      // attachment: Array.isArray(value && value.url) && value.url.map(url => ({ url }))}))
                      attachment: value && value.attachment}))
                    }
                    extra={{ ordid }}
                    data={olog}
                  >
                    <img className="remark-icon" src={icon_remarks} alt="remark icon" />
                  </ButtonModal>
                  )}
              </div>
              }*/}
            </div>
          )) || <></>}
        </>
      );
    };
  
    const progresses = [
      {
        title: 
        <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
          {OrderStatusDisplay(ORDER_STATUS.SUBMITTED)}
        </ButtonLink>,
        description: OrderStatusDesc(ORDER_STATUS.SUBMITTED),
      },
      {
        title: 
        <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
          {getTitleDoorstep(os, OrderStatusDisplay, record)}
          {/* {`${getTitleDoorstep(os, OrderStatusDisplay, record)} to ${record.deliveryType === 'p2p' ? (points[origpid] && points[origpid].code + ' point') : (hubs[origid] && hubs[origid].code + ' hub')}`} */}
        </ButtonLink>,
        description: getDescDoorstep(os, OrderStatusDesc, record),
      },
      {
        title: 
        <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
        In Transit
        </ButtonLink>,
        description: OrderStatusDesc(record.deliveryType === "p2p" ? ORDER_STATUS.PENDING_OUTBOUND_TO_POINT : ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB ),
        // description: OrderStatusDesc(orderLogs && orderLogs[3]?.status ? ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB : ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB),
      },
      {
        title: 
        <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
          {getTitleArrive(os, OrderStatusDisplay, record)} 
        {/* {`${getTitleArrive(os, OrderStatusDisplay, record)} at ${record.deliveryType === 'p2p' ? (points[dstpid] && points[dstpid].code + ' point') : (hubs[dstid] && hubs[dstid].code + ' hub')}`} */}
        </ButtonLink>,
        description: getDescArrive(os, OrderStatusDesc, record),
      },
      {
        title: 
        <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
          {OrderStatusDisplay(ORDER_STATUS.FULFILLED)}
        </ButtonLink>,
        description: OrderStatusDesc(ORDER_STATUS.FULFILLED),
      },
      {
        title: 
        <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
          {getTitleCancelPark(os, OrderStatusDisplay)}
        </ButtonLink>,
        description:  os === ORDER_STATUS.CANCELLED ? 
                        OrderStatusDesc(ORDER_STATUS.CANCELLED) 
                        :
                        os === ORDER_STATUS.PARKED ? 
                          OrderStatusDesc(ORDER_STATUS.PARKED) 
                          :
                          OrderStatusDesc(ORDER_STATUS.CLOSED),
      },
      {
        title: 
        <ButtonLink onClick={() => NavigateOrderTimeline(ordid, os, record)}>
          {getTitleCompleteClose(os, OrderStatusDisplay)}
        </ButtonLink>,
        description: os === ORDER_STATUS.COMPLETED && OrderStatusDesc(ORDER_STATUS.COMPLETED) || os === ORDER_STATUS.CLOSED &&OrderStatusDesc(ORDER_STATUS.CLOSED),
      },
    ];
    
    const getProgress = (os) => {
      let step;
  
      switch (os) {
        case ORDER_STATUS.SUBMITTED: {
          step = 0;
          break;
        }
        case ORDER_STATUS.PENDING_SELF_LODGE:
        case ORDER_STATUS.PENDING_PICK_UP_FROM_CUSTOMER:
        case ORDER_STATUS.PENDING_SELF_LODGE_POINT: {
          step = 1;
          break;
        }
        case ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT:
        case ORDER_STATUS.CHECK_OUT_FROM_ORIGIN_POINT:
        case ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB:
        case ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB:
        case ORDER_STATUS.ARRIVED_AT_DESTINATION_HUB: 
        case ORDER_STATUS.DEPARTED_FROM_ORIGIN_POINT:{
          step = 2;
          break;
        } 
        case ORDER_STATUS.PENDING_SELF_COLLECTION:
        case ORDER_STATUS.PENDING_SELF_COLLECTION_POINT: 
        case ORDER_STATUS.PENDING_DOORSTEP_DELIVERY: {
          step = 3;
          break;
        }
        case ORDER_STATUS.FULFILLED: {
          step = 4;
          break;
        }
        case ORDER_STATUS.CANCELLED:
        case ORDER_STATUS.PARKED:  {
          step = 5;
          break;
        }
        case ORDER_STATUS.CLOSED: 
        case ORDER_STATUS.COMPLETED:{
          step = 6;
          break;
        }
        default: {
          step = 0;
          break;
        }
      }
  
      return step;
    };
  
    const customDot = (dot) => (
      <>
        <>{dot}</>
      </>
    );
  
    useEffect(() => {
      dispatch(v1_orderLog.get_order_logs_thru_ordid_request(ordid));
  
    }, [dispatch, os]);
  
    return (
      <div className={classes.StepProgression}>
        <Skeleton loading={loading} active>
          <Steps
            items={progresses}
            size="small"
            className={getProgress(os) === 6 && "custome-step"}
            current={getProgress(os)}
            progressDot={customDot}
          />
        </Skeleton>
      </div>
    );
  };

export default ProgressTable;
