import * as ActionTypes from "../action-types";

const order_defaultState = {
    byID: {},
    allIDs: [],
    allOrderIDs: []
};

export const order = (state = order_defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    if (!action) return newState;

    switch (action.type) {
        case ActionTypes.GET_ORDER_THRU_HUBID_STATUS: {
            /* 
                Initialize the reducer into empty object, 
                since reducer often filled with previous order (order from different status)
            */
            newState.byID = {}

            const orders = action.orders

            orders
            &&
            orders.length
            &&
            orders.map(order => {
                const { ordid } = order

                newState.byID[ordid] = order

                if(!newState.allIDs.includes(ordid)) {
                    newState.allIDs.push(ordid)
                }

                return null
            })

            return newState
        }
        
        case ActionTypes.UPDATE_ORDER_STATUS: {
            const ordid = action.ordid

            delete newState.byID[ordid]

            if(newState.allIDs.includes(ordid)) {
                const newAllIDs = newState.allIDs.filter(allIDs => allIDs !== ordid)
                
                newState.allIDs = newAllIDs
            }

            return newState;
        }

        case ActionTypes.GET_SHIPMENT_ORDER_THRU_ORDID: {
            const shipmentOrder = action.shipmentOrder 

            const { ordid, updatedAt, soid } = shipmentOrder

            const newStateWithSO = {
                ...newState.byID[ordid],
                printedAt: updatedAt, 
                soid
            }
    
            newState.byID[ordid] = newStateWithSO

            return newState
        }

        case ActionTypes.GET_ORDID_THRU_HUBID: {
            const orderids = action.orderids

            orderids
            &&
            orderids.length
            &&
            orderids.map(orderids => {
                const { ordid } = orderids

                if(!newState.allOrderIDs.includes(ordid)) {
                    newState.allOrderIDs.push(ordid)
                }
            })

            return newState
        }

        default: {
            return state;
        }
    }
};