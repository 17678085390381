import * as API from "../../api"
import * as ActionTypes from "../action-types";

import { message } from 'antd'
import { batch } from "react-redux";

// Constants and Config

// Services

// Redux Action

// Neccessary Actions
import {
    setLoadingvehicle,
    unsetLoadingvehicle,
} from './style'

export const get_all_vehicle_request = () => dispatch => {

    dispatch(setLoadingvehicle())

      return new Promise((resolve, reject) => {
        API.getAllVehicle().then(info => {
          const { status, msg, vehicles } = info;
    
          if (status === 200) {
            dispatch(get_all_vehicle_success(vehicles))
            resolve(info); 
          } else {
            message.warning(msg);
            reject(new Error(msg)); 
          }
        }).catch(error => {
          reject(error); 
        }).finally(() => {
          dispatch(unsetLoadingvehicle())
        })
      });
}

const get_all_vehicle_success = (vehicle) => {
    return {
        type: ActionTypes.VEHICLE_GET_ALL,
        vehicle
    };
}
