// Third Party Library
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer } from "antd";

// Components
import { ButtonLink } from "../../../../components";
import TextArea from "./TextArea";
import CommentLists from "./CommentLists";

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

import * as REDUX_ACTION from "../../services/redux/actions";

// Styles
import classStyles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const DrawerComments = ({ 
    isOpen, 
    setIsOpen, 
    data
}) => {
    const dispatch = useDispatch();

    const user = useSelector(reduxStoreWrapper("user"));

    const { ordid, status } = data;

    // initializations
    const [value, setValue] = useState({});
    const [showTextArea, setShowTextArea] = useState(false);
    const [showEditTextArea, setShowEditTextArea] = useState(false);

    // useState for edit status. usable for determining the action in handleOkButton (whether Submit or Edit)
    const [edit, setEdit] = useState(false);

    // using useEffect to make sure the user data is available
    useEffect(() => {

        /* 
            if DrawerComments is opened and no value.commentedBy value
            set the initial value 
        */
        (isOpen && !value.commentedBy) 
        &&
        setValue({
            ...value,
            // ordid: ordid,
            commentedBy: user.memid,
            // orderStatus: status,
            active: 1,
        });

    }, [isOpen, !value.commentedBy, user]);

    // Comment drawer header
    const CommentHeader = () => {

        const showTextAreaHDLR = () => {
            // if Add Comment text area opened, the TextArea in edit field will be closed 
            setShowTextArea(true)
            &&
            setShowEditTextArea(false)
        }

        return (
            <div className={classStyles["header"]}>
                <h1 className={classStyles["title"]}>Comments ({data.ordid})</h1>
                <ButtonLink
                    onClick={showTextAreaHDLR}
                >
                    + Add Comment
                </ButtonLink>
            </div>
        );
    };

    const handleOkButton = () => {
        try {
            // if ok performed (Save button), one of these actions will be triggered
            switch (edit) {
                 // Edit
                 case true: {
                    dispatch(
                        REDUX_ACTION.v1_orderComment.edit_order_comment_data({...value, ordid, orderStatus: status})
                    );
                    setShowEditTextArea(false);
                    break;
                }
                // Submit
                case false: {
                    dispatch(
                        REDUX_ACTION.v1_orderComment.create_order_comment_data({...value, ordid, orderStatus: status})
                    );
                    setShowTextArea(false);
                    break;
                }
            }
        } 
        finally {
            // in general, as long as the try performed, these action will run
            setEdit(false);
            setValue({ ...value, comment: "", url: "" });
            setShowTextArea(false);
        }
    };

  // attributes used for CommentLists
    const commentListsAttr = {
        // ordid: label ? oparkid : ordid,
        // ordid,
        setEdit,
        setShowEditTextArea,
        showTextArea,
        setShowTextArea,
        edit,
        showEditTextArea,
        handleOkButton,
        value,
        // label,
        setValue,
    };

    return (
        <Drawer
            title={<CommentHeader />}
            placement="right"
            closable={false}
            width={500}
            onClose={() => setIsOpen(false)}
            open={isOpen}
        >
            {/* 
                if user clicks the 'Add Comment' link (available on the top-right of drawer), 
                the TextArea will be shown  
            */}
            {showTextArea && (
                <TextArea
                    value={value}
                    setValue={setValue}
                    handleOkButton={handleOkButton}
                    setShowTextArea={setShowTextArea}
                />
            )}

            {/* List of Comments  */}
            <CommentLists {...commentListsAttr} />
        </Drawer>
    );
};

export default DrawerComments;