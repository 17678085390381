import React from "react";

/**
 * Components
 */

/**
 * Services
 */
import SSORoutes from "./services/navigation/route";

// Styling
import "./index.css";

const App = () => {
	return (
		<>
			<SSORoutes />
		</>
	);
};

export default React.memo(App);
