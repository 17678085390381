import React from "react";
import { useSelector } from "react-redux";

// Constants & Config

// Components
import Layout from "../v1/layout";
import MainRoutes from "../v1/services/navigation/route/main";
import SelectHub from "./pages/SelectHub"

// Route

// Service
import { REDUX as REDUX_UTIL } from "../v1/services/util"

// styles
import "./index.css";

const {
    reduxStoreWrapper
} = REDUX_UTIL

const App = (props) => {
    
    const isHubSelected = useSelector(reduxStoreWrapper('hub.isSelected'))

    return (
        <>
            {
                isHubSelected 
                ?
                    <Layout>
                        <MainRoutes {...props} />
                    </Layout>
                :
                    <SelectHub />
            }
        </>
    );
};

export default React.memo(App);
