import { UserOutlined, KeyOutlined } from '@ant-design/icons';

export const SIDEBAR_FEATURE = {
  PROFILE: "PROFILE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  ADDRESS_BOOK: "ADDRESS_BOOK",
};

export const SIDEBAR_MENU = [
  {
    icon: <UserOutlined />,
    onChangeParam: SIDEBAR_FEATURE.PROFILE,
    label: "Profile",
  },
  {
    icon: <KeyOutlined />,
    onChangeParam: SIDEBAR_FEATURE.CHANGE_PASSWORD,
    label: "Change Password",
  },
];
