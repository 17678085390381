import { ROOT_CONSTANT } from "../..";
import { APP_INFO } from "../../../config";

import { FEATURE_NAME } from "../../common/features";
import { ROUTE_URL } from "../../routes"

import { TIME } from "../../../services/util";

const {
  icon_checkin,
  icon_checkout,
  icon_orderTrack,
  icon_loadBus,
  icon_unloadBus,
  icon_sortOrder,
  icon_calculator,
} = ROOT_CONSTANT.IMG.ICON.DASHBOARD;

export const DASHBOARD_ICON_FEATURE = {
  check_in: "check_in",
  check_out: "check_out",
  track_order: "track_order",
};

export const INCOMING_INDEX = {
    self_lodge: "O0201",
    pending_pickup: "O0202",
    pending_inbound: "O0400",
  };

  export const OUTCOMING_INDEX = {
    self_collection: "O0501",
    delivery: "O0502",
    pending_outbound: "O0300",
  };
  
  export const IMPORTANT_INDEX = {
    parked: "O4200",
    sorted: "O7000",
    upcoming: "O8000",
  };
  

export const DASHBOARD_ICON = {
  [DASHBOARD_ICON_FEATURE.check_in]: {
    icon: icon_checkin,
    title: "Scan to check-in",
    route: ROUTE_URL[FEATURE_NAME[FEATURE_NAME.SCAN_CHECK_IN]], 
    status: INCOMING_INDEX.pending_inbound,
    number: 1,
    disable: true
  },
  [DASHBOARD_ICON_FEATURE.check_out]: {
    icon: icon_checkout,
    title: "Scan to check-out",
    route: ROUTE_URL[FEATURE_NAME[FEATURE_NAME.SCAN_CHECK_OUT]],
    status: OUTCOMING_INDEX.pending_outbound,
    number: 2,
    disable: true
  },
  [DASHBOARD_ICON_FEATURE.track_order]: {
    icon: icon_orderTrack,
    title: "Track order",
    route: APP_INFO.ASSET.PUBLIC_TRACKING_LINK,
    // disable: true
  },
};


export const INCOMING_FEATURE = {
  [INCOMING_INDEX.self_lodge]: {
    title: "Awaiting Customer Self Lodge",
    status: "O0201",
    route: "CUSTOMER_SELF_LODGE",
  },
  [INCOMING_INDEX.pending_pickup]: {
    title: "Pending Pickup From Customer",
    status: "O0202",
    route: "PENDING_PICKUP",
  },
  [INCOMING_INDEX.pending_inbound]: {
    title: "Pending Inbound",
    status: "O0400",
    route: "PENDING_INBOUND",
  },
};

export const OUTCOMING_FEATURE = {
  [OUTCOMING_INDEX.self_collection]: {
    title: "Arrive & Ready For Self Collection",
    status: "O0501",
    route: "READY_FOR_SELF_COLLECTION",
  },
  [OUTCOMING_INDEX.delivery]: {
    title: "Arrive & Ready for Delivery",
    status: "O0502",
    route: "PENDING_FOR_DELIVERY",
  },
  [OUTCOMING_INDEX.pending_outbound]: {
    title: "Pending Outbound",
    status: "O0300",
    route: "PENDING_OUTBOUND",
  },
};

export const IMPORTANT_FEATURE = {
  [IMPORTANT_INDEX.parked]: {
    title: "Parked Order",
    route: "PARKED_ORDER",
  },
  [IMPORTANT_INDEX.sorted]: {
    title: "To Be Sorted",
    route: "",
  },
  [IMPORTANT_INDEX.upcoming]: {
    title: "Upcoming Trip",
    route: "",
  },
};

export const GET_OPERATION_HOURS_ARR = (biz_hrs) => {
    const days = ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"]

    let opHrs = []

    biz_hrs 
    &&
    days.forEach(day => {

        const opHr = {
            day,
            hour: biz_hrs.find(bz => day === TIME.getDayNamefromEpoch(bz.day))?.hour
        }
    
        opHrs.push(opHr)
    })

    return opHrs
}