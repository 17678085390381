// Third-party Library
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

// Constants
import { ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components
// import { LoadingPage } from "../../../../components";
import { TimelineHeader, TimelineHightlight, TimelineList } from "./components";

// Service
import { REDUX as REDUX_UTIL } from "../../../../services/util";

// Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles
import classes from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

// const { MODULE_ROUTE } = ROUTES;

const Timeline = (props) => {
  const { ordid, status, record } = props;
  const dispatch = useDispatch();

  const loading = useSelector(reduxStoreWrapper("style.loading.order_log"));
  const orderLogs = useSelector(reduxStoreWrapper("orderLog.byID"));
  const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
  const hubs = useSelector(reduxStoreWrapper("hubs.byID"));
  const points = useSelector(reduxStoreWrapper("points.byID"));

  const { olog: orderLog } = orderLogs;

  useEffect(() => {
    if (!orderLogs[ordid])
      dispatch(
        REDUX_ACTION.v1_orderLog.get_order_logs_thru_ordid_request(ordid)
      );
  }, [dispatch]);

  const orderLogData = orderLogs[ordid]?.map((o, index) => {
    return {
      ...o,
      remark: orderLogs[ordid][index - 1]?.remark || "",
      attachment: orderLogs[ordid][index - 1]?.attachment || [],
    };
  });

  const TimelineMain = () => {
    return (
      <div className={classes["container-track"]}>
        <TimelineHeader ordid={ordid} />
        <TimelineHightlight
          orderLogs={orderLogData}
          orderStatuses={orderStatuses}
          status={status}
          hubs={hubs}
          point={points}
          record={record}
        />
        <TimelineList
          orderLogs={orderLogData}
          orderStatuses={orderStatuses}
          hubs={hubs}
          point={points}
          record={record}
        />
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin />
        </div>
      ) : (
        <TimelineMain
          orderLog={orderLog}
          ordid={ordid}
          orderStatuses={orderStatuses}
          status={status}
        />
      )}
    </>
  );
};

export default Timeline;
