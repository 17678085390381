import * as ActionTypes from "../action-types";

const hub_defaultState = {
    code: JSON.parse(localStorage.getItem("code") || null),
    hubid: JSON.parse(localStorage.getItem("hubid") || null),
    isSelected: JSON.parse(localStorage.getItem("isSelected") || null) || false,
    hubsByOP: []
};

export const hub = (state = hub_defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    if (!action) return newState;

    switch (action.type) {
        case ActionTypes.SET_SELECTED_HUB: {
            const selectedHub = action.selectedHub;

            const { code, hubid } = selectedHub

            newState.isSelected = true;
            newState.code = code;
            newState.hubid = hubid;
            
            localStorage.setItem("hubid", JSON.stringify(newState.hubid));
            localStorage.setItem("isSelected", JSON.stringify(newState.isSelected));
            localStorage.setItem("code", JSON.stringify(newState.code));
            
            return newState;
        }

        case ActionTypes.REMOVE_SELECTED_HUB: {

            localStorage.removeItem("hubid");
            localStorage.removeItem("isSelected");
            localStorage.removeItem("code");
            
            hub_defaultState.isSelected = false;
            hub_defaultState.code = null;
            hub_defaultState.hubid = null;

            return newState;
        }

        case ActionTypes.GET_HUB_BY_HUBID: {
            const hub = action.hub;

            Object.keys(hub).forEach(hkey => {
                newState[hkey] = hub[hkey]
            })
      
            return newState;
        }

        case ActionTypes.GET_HUB_THRU_OPERATOR_UID: {
            const hubs = action.hubs;

            newState.hubsByOP = hubs

            return newState;
        }

        default: {
            return state;
        }
    }
};
