// React Thirty Party Library
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// Components Library
import { SearchCompleted, TableCompleted } from "./components";

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../services/redux/actions";

const { reduxStoreWrapper } = REDUX_UTIL;

const Completed = ({
    status = '',
    number,
    isCompletedOpened = () => {}
}) => {
    const dispatch = useDispatch();

    const hub = useSelector(reduxStoreWrapper('hub'))
    const completed = useSelector(reduxStoreWrapper('order_completed.byID'));

    const { hubid, code } = hub


    const dataSource = Object.values(completed).map((o) => ({
        key: o.ordid,
        code,
        ...o,
    }));

    const [filteredData, setFilteredData] = useState(dataSource);

    useEffect(() => {
        dispatch(
            REDUX_ACTION.v1_orderCompleted.get_order_completed_thru_hubid_status_request({
                hubid,
                status: number,
            })
        );

        dispatch(REDUX_ACTION.v1_admin.get_all_admins_request());
    }, [dispatch, hubid, isCompletedOpened]);

    useEffect(() => {
        if (filteredData !== dataSource) {
            setFilteredData(dataSource);
        }
    }, [dataSource]);

    useEffect(() => {
        setFilteredData(filteredData);
    }, [filteredData]);

    return (
        <>
            <SearchCompleted
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                dataSource={dataSource}
                status={status}
            />

            <TableCompleted
                filteredData={filteredData}
                setFilteredData={setFilteredData}
                dataSource={dataSource}
                // status={status}
            />
        </>
    );
};

export default Completed;
