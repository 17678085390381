import { message } from "antd";
import * as API from "../../api";
import * as ActionTypes from "../action-types";

// Constants and Config

// Services

// Redux Action

// Neccessary Actions

import { setLoadingPending, unsetLoadingPending } from "./style";

export const get_all_statuses_request = (platid) => (dispatch) => {
  dispatch(setLoadingPending());
  
  API.getAllStatuses(platid)
    .then((info) => {
      const { status, orderStatuses, msg } = info;

    //   console.log(info)

      if (status === 200) {
        dispatch(get_order_statuses_success(orderStatuses));
        message.success(msg);
      } else {
        message.error(msg);
      }
    })
    .finally(() => {
      dispatch(unsetLoadingPending());
    });
};

const get_order_statuses_success = (orderStatuses) => {
  return {
    type: ActionTypes.GET_ALL_STATUSES,
    orderStatuses,
  };
};
