import * as ActionTypes from "../action-types";

const orderComment_defaultState = {
    byCreatedAt: {}
}


export const orderComment = (state = orderComment_defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState 

    switch(action.type) {
        case ActionTypes.GET_ORDER_COMMENT: {
            const orderComment = action.orderComment;

            newState.byCreatedAt = {}

            orderComment 
            &&
            orderComment.length 
            &&
            orderComment.map(ocmt => {

                const { createdAt } = ocmt;
    
                newState.byCreatedAt[createdAt] = ocmt;
                
                return null;
            });

            return newState;
        }
    
        case ActionTypes.CREATE_ORDER_COMMENT:
        case ActionTypes.EDIT_ORDER_COMMENT: {
            const orderComment = action.orderComment;
            
            const { createdAt } = orderComment
    
            newState.byCreatedAt[createdAt] = orderComment
    
            return newState;
        }
    
        default: {
            return state;
        }
    }
}