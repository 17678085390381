import * as ActionTypes from "../action-types"

const report_defaultState = {
    byCreatedAt: {},
    allIDs: [],
    rtype: ''
}

export const report = (state = report_defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state))

    if(!action) return newState

    switch(action.type) {
        case ActionTypes.GET_REPORT_LIST: {

            // reset the previous report 
            newState.byCreatedAt = {}
            newState.allIDs = []

            const reports = action.reports
            newState.rtype = action.rtype

            reports
            &&
            reports.length
            &&
            reports.map(report => {
                const { ordid, createdAt } = report 

                // since scan often has same ordid. if using ordid, some value with same ordid will be overlapped
                // it can be a temporary solution
                // const param = action.rtype === 'scan' ? createdAt : ordid

                newState.byCreatedAt[createdAt] = report

                if(!newState.allIDs.includes(ordid)) {
                    newState.allIDs.push(ordid)
                }
            })

            return newState
        }
        default: {
            return state;
        }
    }
    
}