// Constants Library
import { FEATURE_NAME } from "../common/features";

export const ROUTE_URL = {
  // App Info
  [FEATURE_NAME.DASHBOARD]: "/",
  [FEATURE_NAME.ACCOUNT_PROFILE]: "/account",
  [FEATURE_NAME.ORDER_SUMMARY]: "/order/summary",
  [FEATURE_NAME.ORDER_TIMELINE]: "/order/timeline",

  //incoming
  [FEATURE_NAME.CUSTOMER_SELF_LODGE]: "/self/lodge",
  [FEATURE_NAME.PENDING_PICKUP]: "/pending/pickup",
  [FEATURE_NAME.PENDING_INBOUND]: "/pending/inbound",

  //outgoing
  [FEATURE_NAME.PENDING_OUTBOUND]: "/pending/outbound",
  [FEATURE_NAME.READY_FOR_SELF_COLLECTION]: "/self/collection",
  [FEATURE_NAME.PENDING_FOR_DELIVERY]: "/pending/delivery",

    //   Scan 
    [FEATURE_NAME.SCAN_CHECK_IN]: "/scan/checkin",
    [FEATURE_NAME.SCAN_CHECK_OUT]: "/scan/checkout",
    [FEATURE_NAME.SCAN_LOAD_TO_BUS]: "/scan/load",
    [FEATURE_NAME.SCAN_UNLOAD_FROM_BUS]: "/scan/unload",

  //important
  [FEATURE_NAME.PARKED_ORDER]: "/parked/order",

  // report
  [FEATURE_NAME.REPORT]: '/report'

};

export const ROUTE_TITLE = {
  // App Info
  [FEATURE_NAME.DASHBOARD]: "Dashboard",
  [FEATURE_NAME.ORDER_SUMMARY]: "Order List",
  [FEATURE_NAME.ORDER_TIMELINE]: "Order Timeline",

  //incoming
  [FEATURE_NAME.INCOMING]: "Incoming",
  [FEATURE_NAME.CUSTOMER_SELF_LODGE]: "Awaiting Customer Self Lodge",
  [FEATURE_NAME.PENDING_PICKUP]: "Pending Pickup",
  [FEATURE_NAME.PENDING_INBOUND]: "Pending Inbound",

  //outgoing
  [FEATURE_NAME.OUTGOING]: "Outgoing",
  [FEATURE_NAME.PENDING_OUTBOUND]: "Pending Outbound",
  [FEATURE_NAME.READY_FOR_SELF_COLLECTION]:
    "Arrive & Ready For Self Collection",
  [FEATURE_NAME.PENDING_FOR_DELIVERY]: "Arrive & Ready For Pending Delivery",

  //Scan
  [FEATURE_NAME.SCAN_CHECK_IN]: "Scan to Check in",
  [FEATURE_NAME.SCAN_CHECK_OUT]: "Scan to Check out",
  [FEATURE_NAME.SCAN_LOAD_TO_BUS]: "Scan to Load",
  [FEATURE_NAME.SCAN_UNLOAD_FROM_BUS]: "Scan to Unload",

  // Report 
  [FEATURE_NAME.REPORT]: 'Report',

  //important
  [FEATURE_NAME.IMPORTANT]: "Important",
  [FEATURE_NAME.PARKED_ORDER]: "Parked Order",

  [FEATURE_NAME.ACCOUNT_PROFILE]: "My Account",
  [FEATURE_NAME.LOGOUT]: "Logout",
};

export const ROUTE_TITLE_PATH = Object.fromEntries(
  Object.entries(ROUTE_URL).map(([key, value]) => [value, ROUTE_TITLE[key]])
);
