import * as API_CONSTANT from "../constants";
import { APP_INFO } from "../../../config";

export const getHubPendingThruHubid = (items) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/order/get/timeframe/hubid/status`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items),
  }).then((res) => res.json());

export const getHubCompletedThruHubid = (items) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/task/get/timeframe/completed`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items),
  }).then((res) => res.json());

// export const getAllStatuses = ({ platid = APP_INFO.APP_PLATFORM } = {}) =>
export const getAllStatuses = (platid) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/order/status/get/platid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({ platid }),
  }).then((res) => res.json());

export const getAllHubs = () =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/hub/get/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({}),
  }).then((res) => res.json());

export const getAllCustomers = () =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/customer/getInfo/basic/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({}),
  }).then((res) => res.json());

export const getAllAdmin = () =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/admin/getInfo/basic/all`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({}),
  }).then((res) => res.json());

export const getAllOrderIDByHUBID = (items) => 
fetch(`${API_CONSTANT.hub_v1_fe_path}/orderid/get/timeframe/hubid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(items),
  }).then((res) => res.json());
