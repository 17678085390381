import { Select } from "antd";

const { Option } = Select;

const FilterDays = ({ defaultValue, handleOptionDays, optionDay }) => {
  return (
    <Select
      showSearch
      placeholder={defaultValue}
      style={{ width: 200 }}
      onChange={handleOptionDays}
    >
      {optionDay.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FilterDays;
