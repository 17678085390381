import React from "react";
// import { HomeOutlined } from "@ant-design/icons";

// Constants
import { FEATURE_NAME, FEATURE_TYPE } from "../common/features";
import { ROOT_CONSTANT } from "../index";

// Services

const { SIDEBAR } = ROOT_CONSTANT.IMG.ICON;

export const FEATURES = FEATURE_NAME;

export const FEATURE_LABEL = {
  // App Info
  [FEATURE_NAME.DASHBOARD]: "Dashboard",

  //incoming
  [FEATURE_NAME.INCOMING]: "Incoming",
  [FEATURE_NAME.CUSTOMER_SELF_LODGE]: "Awaiting Customer Self Lodge",
  [FEATURE_NAME.PENDING_PICKUP]: "Pending Pickup",
  [FEATURE_NAME.PENDING_INBOUND]: "Pending Inbound",

  //outgoing
  [FEATURE_NAME.OUTGOING]: "Outgoing",
  [FEATURE_NAME.PENDING_OUTBOUND]: "Pending Outbound",
  [FEATURE_NAME.READY_FOR_SELF_COLLECTION]:
    "Arrive & Ready For Self Collection",
  [FEATURE_NAME.PENDING_FOR_DELIVERY]: "Arrive & Ready For Pending Delivery",

  //important
  [FEATURE_NAME.IMPORTANT]: "Important",
  [FEATURE_NAME.PARKED_ORDER]: "Parked Order",
  [FEATURE_NAME.REPORT]: 'Report',

  [FEATURE_NAME.ACCOUNT_PROFILE]: "My Account",
  [FEATURE_NAME.LOGOUT]: "Logout",
};

const MENU_ICON_SIZE = 24;

const MENU_COMPONENT_ITEM = (menu) => ({
  key: menu.feature,
  icon:
    (menu.icon && <menu.icon style={{ fontSize: MENU_ICON_SIZE }} />) || null,
  label: FEATURE_LABEL[menu.feature],
  feature: menu.feature,
  type: menu.type || null,
  children:
    (menu.children && menu.children.map((m) => MENU_COMPONENT_ITEM(m))) || [],
  disabled: menu.disabled,
  function: menu.function,
});

export const FEATURE_MENU_ITEMS_OBJ = {
  [FEATURE_NAME.DASHBOARD]: {
    feature: FEATURE_NAME.DASHBOARD,
    icon: SIDEBAR.icon_dashboard,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },

  // [FEATURE_NAME.INCOMING]: {
  //   feature: FEATURE_NAME.INCOMING,
  //   icon: SIDEBAR.icon_incoming,
  //   routeType: FEATURE_TYPE.ROUTE,
  //   disabled: false,
  // },
  
  [FEATURE_NAME.PENDING_INBOUND]: {
    feature: FEATURE_NAME.PENDING_INBOUND,
    icon: SIDEBAR.icon_inbound,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.PENDING_OUTBOUND]: {
    feature: FEATURE_NAME.PENDING_OUTBOUND,
    icon: SIDEBAR.icon_outbound,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.CUSTOMER_SELF_LODGE]: {
    feature: FEATURE_NAME.CUSTOMER_SELF_LODGE,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.PENDING_PICKUP]: {
    feature: FEATURE_NAME.PENDING_PICKUP,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  // [FEATURE_NAME.PENDING_INBOUND]: {
  //   feature: FEATURE_NAME.PENDING_INBOUND,
  //   routeType: FEATURE_TYPE.ROUTE,
  //   disabled: false,
  // },

  [FEATURE_NAME.OUTGOING]: {
    feature: FEATURE_NAME.OUTGOING,
    icon: SIDEBAR.icon_outgoing,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  // [FEATURE_NAME.PENDING_OUTBOUND]: {
  //   feature: FEATURE_NAME.PENDING_OUTBOUND,
  //   routeType: FEATURE_TYPE.ROUTE,
  //   disabled: false,
  // },
//   [FEATURE_NAME.READY_FOR_SELF_COLLECTION]: {
//     feature: FEATURE_NAME.READY_FOR_SELF_COLLECTION,
//     routeType: FEATURE_TYPE.ROUTE,
//     disabled: false,
//   },
//   [FEATURE_NAME.PENDING_FOR_DELIVERY]: {
//     feature: FEATURE_NAME.PENDING_FOR_DELIVERY,
//     routeType: FEATURE_TYPE.ROUTE,
//     disabled: false,
//   },

  
  [FEATURE_NAME.IMPORTANT]: {
    feature: FEATURE_NAME.IMPORTANT,
    icon: SIDEBAR.icon_alarm,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.PARKED_ORDER]: {
    feature: FEATURE_NAME.PARKED_ORDER,
    icon: SIDEBAR.icon_alarm,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.REPORT]: {
    feature: FEATURE_NAME.REPORT,
    icon: SIDEBAR.icon_report,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  // [FEATURE_NAME.PARKED_ORDER]: {
  //   feature: FEATURE_NAME.PARKED_ORDER,
  //   routeType: FEATURE_TYPE.ROUTE,
  //   disabled: false,
  // },

  [FEATURE_NAME.ACCOUNT_PROFILE]: {
    feature: FEATURE_NAME.ACCOUNT_PROFILE,
    icon: SIDEBAR.icon_my_account,
    routeType: FEATURE_TYPE.ROUTE,
    disabled: false,
  },
  [FEATURE_NAME.LOGOUT]: {
    feature: FEATURE_NAME.LOGOUT,
    icon: SIDEBAR.icon_logout,
    routeType: FEATURE_TYPE.LOGOUT,
    disabled: false,
  },
};

export const FEATURE_MENU_ITEMS = Object.values(FEATURE_MENU_ITEMS_OBJ);
export const FEATURE_MENU_ITEMS_LAYER_FIRST = [
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.DASHBOARD],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.PENDING_INBOUND],
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.PENDING_OUTBOUND],
//   FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.PARKED_ORDER]
  // {
  //   ...FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.INCOMING],
  //   children: [
  //     FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.CUSTOMER_SELF_LODGE],
  //     FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.PENDING_PICKUP],
  //     FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.PENDING_INBOUND],
  //   ],
  // },
  // {
  //   ...FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.OUTGOING],
  //   children: [
  //     FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.PENDING_OUTBOUND],
  //     FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.READY_FOR_SELF_COLLECTION],
  //     FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.PENDING_FOR_DELIVERY],
  //   ],
  // },
  {
    ...FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.IMPORTANT],
    children: [FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.PARKED_ORDER]],
  },
  FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.REPORT],
];
export const FEATURE_MENU_ITEMS_LAYER_SECOND = [
  {
    type: "group",
    children: [
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.ACCOUNT_PROFILE],
      FEATURE_MENU_ITEMS_OBJ[FEATURE_NAME.LOGOUT],
    ],
  },
];

export const FEATURE_MENU_ITEMS_COMP = FEATURE_MENU_ITEMS.map((menu) =>
  MENU_COMPONENT_ITEM(menu)
);

export const FEATURE_MENU_ITEMS_FIRST_LAYER_GROUP =
  FEATURE_MENU_ITEMS_LAYER_FIRST.map((menu) => MENU_COMPONENT_ITEM(menu));
export const FEATURE_MENU_ITEMS_SECOND_LAYER_GROUP =
  FEATURE_MENU_ITEMS_LAYER_SECOND.map((menu) => MENU_COMPONENT_ITEM(menu));
