// Components
import {
    TableSearch,
    InputSearch
} from './components'

// Styles
import coms from "../index.module.css"

const Report = () => {

    return (
        <div className={coms["container"]}>
            <InputSearch />
            <TableSearch />
        </div>
    )
}

export default Report