// React Thirty Party Library
import { useState, useEffect } from "react";
import { Select, Input, Skeleton } from "antd";
import { useSelector } from "react-redux";

// Constants Library
import { APP_CONFIG } from "../../../../config";
import { TEMPLATE } from "../../../../constants";

// Components Library
import {
  ButtonLink,
  ButtonCopy,
  GoogleMapIFrame,
} from "../../../../components";

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

// Redux Actions

// Styles Library
import styles from "../common.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;
const { GET_OPERATION_HOURS_ARR } = TEMPLATE.DASHBOARD

// const optionsHub = Object.keys(HUBS_BY_STATE_ORG).map((i) => ({
//   label: STATE[i].name,
//   options: HUBS_BY_STATE_ORG[i].map((j) => ({
//     label: `(${HUBS[j].code}) ${HUBS[j].name}`,
//     value: HUBS[j].code,
//   })),
// }));

const HubInformation = () => {
  const hubs = useSelector(reduxStoreWrapper("hub"));
  const loading = useSelector(reduxStoreWrapper("style.loading.hubs"))


  // useEffect(() => {
  //   setValue(`${hubs.code} ${hubs.name}`);
  // }, [hubs]);

  // // OptionGroup -- set options value for its child
  // const hubMap = {};

  // hubsIDs.forEach((hubid) => {
  //   const hubState = hubs.state;

  //   if (hubState !== undefined) {
  //     if (!hubMap[hubState]) {
  //       hubMap[hubState] = [];
  //     }

  //     hubMap[hubState].push({
  //       label: hubs.name,
  //       value: hubid,
  //     });
  //   }
  // });

  // // OptionGroup -- Main OptionGroup value
  // const hubList = Object.keys(hubMap).map((hubState) => ({
  //   label: hubState,
  //   options: hubMap[hubState],
  // }));

  const coordinates = {
    lat: parseFloat(hubs?.coordinate?.lat) || null,
    lng: parseFloat(hubs?.coordinate?.lon) || null,
    // lat: parseFloat(hubs && hubs.coordinate && hubs.coordinate.lat),
    // lng: parseFloat(hubs && hubs.coordinate && hubs.coordinate.lon),
  };
  const days = ['Monday','Tuesday','Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
  let businessDays
  let businessDaysCopy

//   if (hubs.biz_hrs?.time){

//     const hour = hubs.biz_hrs.time.split('/');
//     const resultArray = hour.map(item => item.split(','));
//       const openHrs = resultArray[0] || [];
//       const closeHrs = resultArray[1] || [];
//         businessDays = days.map(function(day, index) {
//           const isOpen = hubs.biz_hrs.day[index];
//           const openHr = openHrs[index]
//           const closeHr = closeHrs[index]
          
//           const format =(e)=>{
//               const date = new Date(e * 1000);
//               const hours = ('0' + date.getHours()).slice(-2);
//               const minutes = ('0' + date.getMinutes()).slice(-2);
//             return `${hours}:${minutes}`;
//           }
          
//           return (
            
//           <>
//             <div key={index} style={{display:'flex'}}>
//               <p style={{width:'70px'}}>{day}</p>
//               <p>{isOpen ? `${format(openHr)} - ${format(closeHr)}`  : 'Closed'}</p>
//             </div>
//           </>
//           );
//       });
//       businessDaysCopy = days.map(function(day, index) {
//         const isOpen = hubs.biz_hrs.day[index];
//         const openHr = openHrs[index]
//         const closeHr = closeHrs[index]
        
//         const format =(e)=>{
//             const date = new Date(e * 1000);
//             const hours = ('0' + date.getHours()).slice(-2);
//             const minutes = ('0' + date.getMinutes()).slice(-2);
//            return `${hours}:${minutes}`;
//         }
        
//         return (
//         `${day} = ${isOpen ? `${format(openHr)} - ${format(closeHr)}`  : 'Closed'}`
  
//       )});
  
//   }

  const onHubCopy = () => {
    const str = `
              ${hubs && hubs.code}
  
              ${(hubs && hubs.name) || "-"}
  
              ${(hubs && hubs.address) || "-"}
  
              ${(hubs && hubs.url) || "-"}
  
              ${
                (hubs &&
                  hubs.pic &&
                  hubs.pic.map((p) => `${p.name} ${p.contact}`)) ||
                "-"
              }
  

              ${
                //     typeof hubsList[hubid].biz_hrs.day === "string"? 
                // `${hubsList[hubid].biz_hrs.day} ${hubsList[hubid].biz_hrs.time} `
                //  : 
                 
                //     businessDaysCopy
    
                Array.isArray(hubs.biz_hrs) 
                &&
                GET_OPERATION_HOURS_ARR(hubs.biz_hrs).map(hr => `${hr.day}   ${hr.hour ? TIME.getHoursNameFromEpoch(hr.hour) : 'Closed' } \n`).join('\t      ')
                ||
                // (typeof hubsList[hubid]?.biz_hrs.day === "string"? 
                `${hubs.biz_hrs.day} ${hubs.biz_hrs.time} `
                //     : 
                //     <div>
                //     {businessDays}
                //     </div>
                //     )
                
                 }
                
                `;

    return str;
  };
//   let isLoading;

//     if(hubs){
//         isLoading = Object.keys(hubs).length < 5;
//     }

  return (
    <>
      <h2 className={styles["label-title"]}>Hub Information</h2>

      <Skeleton loading={loading} active>
        {!loading && (
          <>
            <Input
              style={{
                width: 280,
                marginBottom: "15px",
                color: "black",
                backgroundColor: "white",
              }}
              value={`${hubs?.code} ${hubs?.name}`}
              readOnly
              // onChange={(val) => setHub(val)}
              // options={hubList}
            />
            <div className={styles["card-location"]}>
              <GoogleMapIFrame
                title={hubs?.code}
                APIKey={APP_CONFIG.API_KEY.GOOGLE_MAP}
                coordinate={coordinates}
                width="100%"
              />
              <div className={styles["card-location-detail"]}>
                <strong>{`(${hubs?.code}) ${hubs?.name} `}</strong>
                <p className={styles["card-location-content"]}>
                  {hubs?.address}
                </p>
                <ButtonLink
                  onClick={() => window.open(hubs?.url, "_blank", "noreferrer")}
                >
                  {hubs && hubs.url}
                </ButtonLink>
                <ul className={styles["card-location-content"]}>
                  {hubs &&
                    hubs.pic &&
                    hubs.pic.map((i, key) => (
                      <li key={key}>
                        {i.name} {i.contact}
                      </li>
                    ))}
                </ul>
                {
                        Array.isArray(hubs.biz_hrs) 
                        &&
                        // GET_OPERATION_HOURS_LIST(hubs.biz_hrs)
                        GET_OPERATION_HOURS_ARR(hubs.biz_hrs).map((bz, i) => (
                            <div key={i} style={{ display: 'grid', gridTemplateColumns: '40% 80%', width: '10em'}}>
                                <p>{bz.day}</p>
                                <p> {bz.hour ? TIME.getHoursNameFromEpoch(bz.hour) : 'Closed'}</p>
                            </div>
                        ))
                        ||
                        (typeof hubs?.biz_hrs.day === "string"? 
                        `${hubs.biz_hrs.day} ${hubs.biz_hrs.time} `
                         : 
                         <div>
                            {businessDays}
                         </div>)
                    }
              </div>
                <div className={styles["hub-copy"]}>
                  {/* <p className={styles["card-location-content"]}></p> */}
                  <ButtonCopy copiedtext={onHubCopy()} />
                </div>
            </div>
          </>
        )}
      </Skeleton>
    </>
  );
};

export default HubInformation;
