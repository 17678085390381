import { useNavigate } from "react-router";
import logo from "../../assets/logo/logo_title_white.svg";

// Config
import { APP_INFO } from "../../config";

// Constant
import { ROUTE } from "../../constants";

// Styles
import classes from "./index.module.css";

const HeaderBar = () => {
    const navigate = useNavigate();
  
    return (
            <div className={classes.navbar}>
                <img src={logo} className={classes["icon-img"]} alt="" />
                <p
                    role="presentation"
                    className={classes.tagline}
                    onClick={() => navigate(ROUTE.MODULE_ROUTE.Landing_Page)}
                >
                    Hub Portal <span className={classes.version}>v {APP_INFO.APP_VERSION}</span>
                </p>
            </div>
        );
  };

export default HeaderBar;
