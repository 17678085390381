// Third-party Library 
import { Drawer, Typography, List, Tag } from "antd"
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";

// Services Library
import { TIME } from "../../../../services/util";

const { Title } = Typography

const DrawerHistory = ({
    isOpen,
    setIsOpen,
    dataSource
}) => {

    return (
        <Drawer 
            title={<Title level={3} style={{textAlign: 'center'}}>Scan History</Title>}
            placement="right"
            width={410}
            closable={false}
            onClose={() => setIsOpen(false)}
            open={isOpen}
        >
            <List
                itemLayout="horizontal"
                dataSource={dataSource}
                renderItem={(cel, i) => {

                    const Title = () => {
                        return (
                            <div style={{display: 'flex', justifyContent: 'space-between', margin: '15px 0'}}>
                                <div style={{display: 'flex', gap: '8px'}}>
                                    {
                                        cel.scanStatus ?
                                        <CheckCircleTwoTone twoToneColor="#52c41a" />
                                        :
                                        <CloseCircleTwoTone twoToneColor='red'/>
                                    }
                                    <p>{cel.ordid}</p>
                                </div>
                                <div>
                                    <Tag color={cel.scanStatus ? 'success' : 'error'}>
                                        {cel.scanStatus ? 'SUCCESS' : 'FAIL'}
                                    </Tag>
                                </div>
                            </div>
                        )
                    }

                    const Description = () => {
                        return (
                            <>
                                <p>Scanned by : {cel.order.updatedBy}</p>
                                <p>Scanned at : {TIME.parseTimeMinDayFirst(cel.createdAt)}</p><br/>
                                <p>Remark : {cel.remark || '-'}</p>
                            </>
                        )
                    }

                    return (
                        <List.Item style={{padding: "30px 0"}}>
                            <List.Item.Meta
                                title={<Title />}
                                description={<Description />}
                            />
                        </List.Item>
                    )
                }}
            />
        </Drawer>
    )
}

export default DrawerHistory