import * as API_CONSTANT from "../constants";

// export const updateOrder = async ({
//   order,
//   statusChange,
//   orderState,
//   update,
//   customer,
//   attachment,
// }) => {
//   const requestBody = {
//     order,
//     statusChange,
//     orderState,
//     update,
//     customer,
//   };

//   if (attachment && attachment.length > 0) {
//     requestBody.attachment = attachment;
//   }

//   // console.log(requestBody);

//   const res = await fetch(`${API_CONSTANT.hub_v1_fe_path}/order/update`, {
//     method: "POST",
//     headers: API_CONSTANT.headers,
//     body: JSON.stringify(requestBody),
//   });
//   return await res.json();
// };

export const updateOrder = (items) =>
    fetch(`${API_CONSTANT.hub_v1_fe_path}/order/update`, {
        method: "POST",
        headers: API_CONSTANT.headers,
        body: JSON.stringify(items),
      })
    .then(res => res.json())

export const getOrderLogsByORDID = ({ ordid }) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/order/log/get/ordid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({ordid}),
  }).then((res) => res.json());

export const getOrderComment = (items) =>
fetch(`${API_CONSTANT.hub_v1_fe_path}/order/comment/get/timeframe/ordid`, {
  method: "POST",
  headers: API_CONSTANT.headers,
  body: JSON.stringify(items),
}).then((res) => res.json());

export const createUpdateOrderComment = (items) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/order/comment/cu`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(
        items
    ),
  }).then((res) => res.json());

  export const getShipmentOrderByORDID = (ordid) => (
    fetch(`${API_CONSTANT.hub_v1_fe_path}/so/get/ordid`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(ordid)
    })
    .then(res => res.json())
)

  export const getShipmentOrderBySOID = (soid) => (
    fetch(`${API_CONSTANT.hub_v1_fe_path}/so/get/soid`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(soid)
    })
    .then(res => res.json())
)

export const updateOrderLogsByORDID = ({ordid, items}) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/order/log/update`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify({
      ordid,
      items
    }),
  }).then((res) => res.json());

export const addOrderThruScan = (items) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/order/scan/ordid`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(
        items
    ),
  }).then((res) => res.json());

export const getOrderThruScan = (items) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/order/timeframe/scanned`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(
        items
    ),
  }).then((res) => res.json());

export const clearOrderThruScan = (items) =>
  fetch(`${API_CONSTANT.hub_v1_fe_path}/order/scan/clear`, {
    method: "POST",
    headers: API_CONSTANT.headers,
    body: JSON.stringify(
        items
    ),
  }).then((res) => res.json());
