import PIN_ICON from "../../assets/images/pin.svg";

import { ReactComponent as icon_my_account } from "../../assets/sidebar/my-account.svg";
import { ReactComponent as icon_logout } from "../../assets/sidebar/logout.svg";
import { ReactComponent as icon_dashboard } from "../../assets/sidebar/dashboard.svg";
import { ReactComponent as icon_incoming } from "../../assets/sidebar/arrow-right.svg";
import { ReactComponent as icon_outgoing } from "../../assets/sidebar/arrow-left.svg";
import { ReactComponent as icon_inbound } from "../../assets/sidebar/pending-inbound.svg";
import { ReactComponent as icon_outbound } from "../../assets/sidebar/pending-outbound.svg";
import { ReactComponent as icon_alarm } from "../../assets/sidebar/alarm.svg";
import { ReactComponent as icon_report } from "../../assets/sidebar/report.svg";

import ipick from "../../assets/logo/logo.png";
import title_white from "../../assets/logo/logo_title_white.svg";

import icon_bankIcon from "../../assets/images/finance/bankIcon.svg";
import icon_bankName from "../../assets/images/finance/bankName.svg";

import icon_checkin from "../../assets/images/dashboard/check_in.svg";
import icon_checkout from "../../assets/images/dashboard/check_out.svg";
import icon_loadBus from "../../assets/images/dashboard/load_bus.svg";
import icon_unloadBus from "../../assets/images/dashboard/unload_bus.svg";
import icon_sortOrder from "../../assets/images/dashboard/sort_order.svg";
import icon_calculator from "../../assets/images/dashboard/calculator.svg";
import icon_orderTrack from "../../assets/images/dashboard/order_track.svg";

import img_point_fee from "../../assets/images/dashboard/point_fee.png";


import icon_arrow_bottom from "../../assets/images/modal-icon/arrow-bottom.svg";
import icon_arrow_right from "../../assets/images/modal-icon/arrow-right.svg";
import icon_cancel_trip_manifest from "../../assets/images/modal-icon/cancel.svg";
import icon_cancel_order from "../../assets/images/modal-icon/cancel-order.svg";
import icon_park_order from "../../assets/images/modal-icon/park-order.svg";
import icon_park_resolved from "../../assets/images/modal-icon/park-resolved.svg";
import icon_user_no from "../../assets/images/modal-icon/user-no.svg";
import icon_user_yes from "../../assets/images/modal-icon/user-yes.svg";
import icon_checklist from "../../assets/images/modal-icon/checklist.svg";
import icon_logout_modal from "../../assets/images/modal-icon/logout.svg";
import icon_comment from "../../assets/images/modal-icon/icon_comment.svg";
import icon_link from "../../assets/images/modal-icon/icon_link.svg";
import icon_map_color from '../../assets/images/modal-icon/map-color.svg';
import icon_confirmation from '../../assets/images/modal-icon/confirmation.svg';
import icon_update_status from "../../assets/images/modal-icon/status-update.svg"
import icon_remarks from "../../assets/images/remarks.svg"
import IMG_MY_ACCOUNT_DEFAULT from "../../assets/images/my-account/default-profile-photo.svg";
import icon_edit from "../../assets/images/my-account/edit.svg";
import icon_profile from "../../assets/images/my-account/profile-icon.svg";
import icon_changePass from "../../assets/images/my-account/change-pass-icon.svg";
import icon_export from "../../assets/images/excel.svg";
import icon_scan from "../../assets/images/scan-code.svg";
import icon_clip from "../../assets/images/clip.svg";
import icon_comment_parcel from '../../assets/images/comment.svg'

export const ICON = {
    PIN_ICON: PIN_ICON,
  LOGO: {
    ipick,
    title_white,
  },
  SIDEBAR: {
    icon_my_account,
    icon_logout,
    icon_dashboard,
    icon_inbound,
    icon_outbound,
    icon_incoming,
    icon_outgoing,
    icon_alarm,
    icon_report
  },
  FINANCE: {
    icon_bankIcon,
    icon_bankName,
  },
  DASHBOARD: {
    icon_checkin,
    icon_checkout,
    icon_loadBus,
    icon_unloadBus,
    icon_sortOrder,
    icon_calculator,
    icon_orderTrack,
    img_point_fee
  },
  MODAL: {
    icon_logout_modal,
    icon_user_yes,
    icon_user_no,
    icon_arrow_bottom,
    icon_arrow_right,
    icon_cancel_trip_manifest,
    icon_cancel_order,
    icon_park_order,
    icon_park_resolved,
    icon_checklist,
    icon_comment,
    icon_link,
    icon_update_status,
    icon_clip,
    icon_map_color,
    icon_confirmation
  },
  MY_ACCOUNT: {
    icon_edit,
    icon_profile,
    icon_changePass,
  },
  icon_export,
  icon_scan,
  icon_remarks,
  icon_comment_parcel
};
export const PIC = {
	IMG_MY_ACCOUNT_DEFAULT,
};
