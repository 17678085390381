// React Thirty Party Library
import React, { useState } from "react";
import { 
  Input, 
  Button, 
  Select, 
  DatePicker 
} from "antd";
import { 
  SearchOutlined 
} from "@ant-design/icons";

// Constants Library

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library
import classes from "../index.module.css";

const { RangePicker } = DatePicker;

const SearchTableQuery = ({
  dataSource,
  setFilteredData,
  options,
  defaultValue,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState('text');

  const handleSelectChange = (value) => {
    const option = options.find(o => o.value === value) || {}
    const { type = 'text' } = option

    setSelectedValue(value);
    setSearchValue("")
    setSearchType(type)
  };

  const handleSearch = () => {
    let filteredData;

    switch(searchType) {
      case 'timeframe': {
        const startDate = new Date(searchValue[0]);
        const endDate = new Date(searchValue[1]);
        filteredData = dataSource.filter((item) => {
          const itemDate = new Date(item[selectedValue]);
          return itemDate >= startDate && itemDate <= endDate;
        });
        break; 
      }
      case 'text':
      default: {
        filteredData = dataSource.filter((item) => {
          const searchValueLowerCase = searchValue.toLowerCase();
          const dataLowerCase = item[selectedValue]?.toLowerCase();
          return dataLowerCase?.includes(searchValueLowerCase);
        });
        break;
      }
    }

    setFilteredData(filteredData);
  };

  const handleDateChange = (value) => {
    setSearchValue(value);
  };

  const renderInput = () => {
    switch(searchType) {
      case 'timeframe': {
        return (
          <>
            <RangePicker
              onChange={handleDateChange}
              style={{ width: 250, margin: 0 }}
            />
            <Select
              defaultValue={selectedValue}
              onChange={handleSelectChange}
              style={{ width: 150, margin: 0, marginLeft: -10 }}
            >
              {options.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </>
        );
      }
      case 'text':
      default: {
        return (
          <Input
            addonAfter={
              <Select defaultValue={selectedValue} onChange={handleSelectChange}>
                {options.map((option) => (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                ))}
              </Select>
            }
            style={{ width: 300, margin: 0 }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onPressEnter={handleSearch}
          />
        );
      }
    }
  };

  return (
    <div className={classes.search}>
      {renderInput()}
      <Button
        icon={<SearchOutlined />}
        style={{           
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center"  
        }}
        onClick={handleSearch}
      />
    </div>
  );
};

export default SearchTableQuery;
