// React Thirty Party Library
import { useSelector } from "react-redux";

// Constants Library
import { TEMPLATE, ROOT_CONSTANT } from "../../../../constants";

// Components Library
import { BtnSubmit, TableList } from "../../..";

// Handler Librarys
import { exportHDLR } from "../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

// Redux Actions

// Styles Library
import classes from "../../../../pages/index.module.css";

const { PENDING_TABLE_LIST_INDEX, PENDING_TABLE_LIST_TITLE } =
TEMPLATE.TABLELIST;
const { ORDER_STATUS } = TEMPLATE.ORDER
const { COMPLETED_COLUMN } = TEMPLATE.REPORT.COMPLETED;

const { SearchTableQuery } = TableList;

const { parseExportTime } = TIME;
const { reduxStoreWrapper } = REDUX_UTIL;

const SearchCompleted = ({
  filteredData,
  setFilteredData,
  dataSource,
  status
}) => {
  const admins = useSelector(reduxStoreWrapper("users_admin.byID"));
  const operator = useSelector(reduxStoreWrapper("user"));

  const options = [
    {
      value: PENDING_TABLE_LIST_INDEX.ordid,
      label: PENDING_TABLE_LIST_TITLE.ordid,
      type: "text",
    },
  ];

  const statusName = Object.keys(ORDER_STATUS).find(key => ORDER_STATUS[key] === status).replace('PENDING_', '')

  return (
    <div className={classes.headerPage}>
      <SearchTableQuery
        dataSource={dataSource}
        setFilteredData={setFilteredData}
        options={options}
        defaultValue={PENDING_TABLE_LIST_INDEX.ordid}
      />
      <div>
        <BtnSubmit
          display={"inline-block"}
          text={"Export"}
          bgColor="transparent"
          color={"#4CAF50"}
          padding="9px 18px"
          onClick={() =>
            exportHDLR.exportWithTemplate({
              data: filteredData,
              filename: `COMPLETED_${statusName}_SUMMARY_${parseExportTime()}`,
              template: COMPLETED_COLUMN({
                redux: {
                  admins,
                  operator,
                },
                exportState: true,
              }),
            })
          }
          showIcon={true}
          icon={ROOT_CONSTANT.IMG.ICON.icon_export}
          icon_alt={"export"}
        />
        <BtnSubmit
          display={"inline-block"}
          text={"Scan to check in"}
          bgColor="var(--lightGreyColor)"
          color="var(--creamColor)"
          padding="9px 18px"
          disable={true}
        />
      </div>
    </div>
  );
};

export default SearchCompleted;
