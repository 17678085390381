import React from "react";
import classes from "../index.module.css";

const BtnSubmit = (props) => {
  const {
    text,
    bgColor,
    color,
    border,
    display,
    padding,
    margin,
    onClick,
    showIcon,
    disable = false,
    icon,
    icon_alt,
  } = props;

  return (
    <div className={classes.BtnClick}>
      <button
        style={{
          backgroundColor: (!disable && bgColor) || "var(--bs-gray-300)",
          color: color,
          border: border,
          display: display,
          // height: "100%",
          padding: padding,
          margin: "0 0 0 10px" || margin,
        }}
        onClick={onClick}
        type="submit"
        disabled={disable}
      >
        {showIcon && (
          <img
            src={icon}
            alt={icon_alt}
            style={{ marginRight: "7px", marginBottom: "-7px" }}
          ></img>
        )}
        {text}
      </button>
    </div>
  );
};

export default BtnSubmit;
