import React from "react"; // useEffect
import { useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router";
import { Row, Col } from "antd";

// Constants
import { ROUTE } from "../../../constants";

/**
 * Components
 */
import { asyncComponent, HeaderBar } from "../../../components";

/**
 * Pages
 */
import SSOPanel from "../../../pages/SSOPanel";
import SSOLoading from "../../../pages/SSOLoading";
import SSOLogin from "../../../pages/SSOLogin";

// Styles
import classStyles from "./index.module.css";

const AsyncNotFoundPage = asyncComponent(() =>
  import("../../../pages/NotFoundPage")
);

const SSOContainer = ({ pathname, children }) => {
    const RouteContextFullWidth = 24;

    const RouteContextWidth = () => {
        let width = 12;

        switch (pathname) {
            case ROUTE.MODULE_ROUTE.Signup:
            case ROUTE.MODULE_ROUTE.Signup_Success: {
                width = 14;
                break;
            }
            case ROUTE.MODULE_ROUTE.Landing_Page:
            case ROUTE.MODULE_ROUTE.Login:
            default: {
                width = 12;
                break;
            }
        }

        return width;
    };

    return (
        <>
            <HeaderBar />
            <div className={classStyles.container}>
                <Row className={classStyles.contextLayout}>
                    <Col span={RouteContextFullWidth - RouteContextWidth()}>
                        <div className={classStyles["signup-bg"]} alt="" />
                    </Col>
                    <Col span={RouteContextWidth()}>
                        {children}
                    </Col>
                </Row>
            </div>
        </>
    );

};

function AuthSelector(props) {

    const { pathname } = props;

    const auth = useSelector((state) => state.root.auth);

    const _authSelector = () => {
        if (auth.loadingState) {
            return (
                <SSOContainer pathname={pathname}>
                    <SSOLoading />
                </SSOContainer>
            );
        } else {
            if (auth.currentUser) {
                return <SSOPanel currentUser={auth.currentUser} />;
            } else {
                return (
                    <SSOContainer pathname={pathname}>
                        <SSOLogin />
                    </SSOContainer>
                );
            }
        }
    };

    return <>{_authSelector()}</>;
}

const SSORoutes = () => {
    const location = useLocation();
    const { pathname = "" } = location;

    return (
        <>
            <Routes>
                <Route
                    exact
                    path={ROUTE.MODULE_ROUTE.Login}
                    element={<AuthSelector pathname={pathname} />}
                />

                <Route
                    index
                    path={ROUTE.MODULE_ROUTE.Landing_Page}
                    element={<AuthSelector pathname={pathname} />}
                />

                <Route
                    element={
                        <SSOContainer pathname={pathname}>
                            <AsyncNotFoundPage />
                        </SSOContainer>
                    }
                />

            </Routes>
        </>
    );
};

export default SSORoutes;
