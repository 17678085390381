// React Thirty Party Library
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

// Constants Library
import { TEMPLATE, ROOT_CONSTANT, ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library
import { BtnSubmit, TableList } from "../../../../components";

// Handler Librarys
import { exportHDLR } from "../../../../handlers";

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

// Styles Library
import classes from "../../../../pages/index.module.css";

const { 
    PENDING_TABLE_LIST_INDEX, 
    PENDING_TABLE_LIST_TITLE 
} = TEMPLATE.TABLELIST;
const { PENDING_COLUMN } = TEMPLATE.REPORT.PENDING;
const { ROUTE_URL } = ROUTES 
const { FEATURE_NAME } = COMMON_FEATURES
const { ORDER_STATUS } = TEMPLATE.ORDER

const { SearchTableQuery } = TableList;

const { parseExportTime } = TIME;
const { reduxStoreWrapper } = REDUX_UTIL;

const SearchPending = ({
    filteredData,
    setFilteredData,
    dataSource,
    status,
    number,
    selectedRowKeys
}) => {
    const navigate = useNavigate()

    const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"));
    const hubs = useSelector(reduxStoreWrapper("hubs.byID"));
    const points = useSelector(reduxStoreWrapper("points.byID"));
    const customers = Object.values(useSelector(reduxStoreWrapper("users_customer.byID")));

    const options = [
        {
            value: PENDING_TABLE_LIST_INDEX.ordid,
            label: PENDING_TABLE_LIST_TITLE.ordid,
            type: "text",
        },
    ];

    const redirectToScanPageHDLR = () => {
        switch (status) {
            case ORDER_STATUS.ARRIVED_AT_ORIGIN_HUB: 
            case ORDER_STATUS.ARRIVED_AT_ORIGIN_POINT: 
                navigate(ROUTE_URL[FEATURE_NAME.SCAN_CHECK_OUT], { state: { status, number }})
            break;
            case ORDER_STATUS.DEPARTED_FROM_ORIGIN_HUB:
            case ORDER_STATUS.DEPARTED_FROM_ORIGIN_POINT:
                navigate(ROUTE_URL[FEATURE_NAME.SCAN_CHECK_IN], { state: { status, number }})
                break;
            default: 
                break;
        }
    }

    const statusName = number === 1 ? 'INBOUND' : 'OUTBOUND'
    const selectedData = selectedRowKeys.length && filteredData.filter(data => selectedRowKeys.includes(data.key)) || filteredData

    return (
        <div className={classes.headerPage}>
            <SearchTableQuery
                dataSource={dataSource}
                setFilteredData={setFilteredData}
                options={options}
                defaultValue={PENDING_TABLE_LIST_INDEX.ordid}
            />
            <div>
                <BtnSubmit
                    display={"inline-block"}
                    text={"Export"}
                    bgColor="transparent"
                    color={"#4CAF50"}
                    padding="9px 18px"
                    onClick={() =>
                        exportHDLR.exportWithTemplate({
                        data: selectedData,
                        filename: `PENDING_${statusName}_SUMMARY_${parseExportTime()}`,
                        template: PENDING_COLUMN({
                            redux: {
                                orderStatuses,
                                points,
                                hubs,
                                customers,
                            },
                            exportState: true,
                        }),
                    })}
                    showIcon={true}
                    icon={ROOT_CONSTANT.IMG.ICON.icon_export}
                    icon_alt={"export"}
                />
                {
                    !(status === "O0501" || status === "O0502")  
                    && 
                    <BtnSubmit
                        // disable={true}
                        display={"inline-block"}
                        text={`Scan to ${number === 1 ? 'check in' : 'check out'}`}
                        bgColor="var(--themeBackgroundColor)"
                        color="var(--creamColor)"
                        padding="9px 18px"
                        onClick={redirectToScanPageHDLR}
                        showIcon={true}
                        icon={ROOT_CONSTANT.IMG.ICON.icon_scan}
                        icon_alt={"scan"}
                    />
                }
            </div>
        </div>
    );
};

export default SearchPending;
