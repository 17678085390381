// Third Party Library
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, DatePicker, Input } from "antd";

// Components
import StatusUpdateAttachment from "./StatusUpdateAttachment";

// Constants
import { ROOT_CONSTANT, TEMPLATE } from "../../../../constants";

// Redux Actions 
import * as REDUX_ACTION from "../../../../services/redux/actions"

// Services
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

import "./index.css";
import classes from "../ModalStatusUpdate/";

const { 
    icon_update_status,
} = ROOT_CONSTANT.IMG.ICON.MODAL;

const { 
    MODALS_ORDER_UPDATE 
} = TEMPLATE.MODAL;

const { currentTimeMilliSecond } = TIME;

const { reduxStoreWrapper } = REDUX_UTIL

const ModalStatusUpdate = ({
    isModalOpen,
    setIsModalOpen,
    order,
    data, 
    next_status = "",
}) => {
    const dispatch = useDispatch()

    const { ordid, memid, deliveryType, add_on } = order

    const customer = useSelector(reduxStoreWrapper(`users_customer.byID.${memid}`))
    const updatedBy = useSelector(reduxStoreWrapper('user.memid'))
    const hubid = useSelector(reduxStoreWrapper("hub.hubid"))

    const [ val, setVal ] = useState({
        remark: data?.remark || "",
        attachment: data?.attachment || []
    });

    const { remark, attachment } = val // destructuring val

    // OK Button Layout
    const OkButton = () => { 
        return (
            <Button 
                className="yes-button"
                onClick={() => confirmOkModalHDLR()}
            >
                {data ? "Save": 'Yes, Proceed'}
            </Button>
        )
    }

    // Ok Button logic (what happen if OK button clicked)
    const isOk = () => {
        dispatch(REDUX_ACTION.v1_order.update_order_request({
            hubid,
            order,
            statusChange: true,
            orderState: {
                ordid,
                status: next_status,
                remark
            },
            update: {
                updatedAt: currentTimeMilliSecond(),
                updatedBy
            },
            customer,
            attachment
        }))
    };

    // function related to Confirmation Modal opening 
    const confirmOkModalHDLR = () => {
        isOk()
        setIsModalOpen(false)
    }
  
    // Attributes for DatePicker Component
    const datePickerAttr = {
        placeholder: "Now",
        size: "large",
        style: {width: "100%", marginBottom: "20px"},
        disabled: true,
    }

    const textAreaRemarkAttr = {
        name: "remark",
        placeholder: "Add status remark",
        onChange: (e) => setVal({ ...val, remark: e.target.value }),
        autoSize: {
            minRows: 3,
            maxRows: 4,
        },
        defaultValue: data?.remark || null,
    };

    const textAreaAttr = {
        autoSize: {
            minRows: 6,
            maxRows: 10,
        },
    };

    return (
        <>
            <Modal
                open={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                footer={null}
                width={450}
            >
                <div className="content-container">
                    <img src={icon_update_status} alt="update status" />
                    <h3>Status Update</h3>
                    <br />
                    <p className={classes.textCard}>
                        Confirm the {MODALS_ORDER_UPDATE(next_status, deliveryType, add_on)?.action_name} time for {ordid || data?.ordid } at:
                    </p>
                </div>

                <div className="inputs">
                    <DatePicker {...datePickerAttr} />                   {/* DatePicker input field */}
                    <Input.TextArea {...textAreaRemarkAttr} />           {/* Remark input field */}
                    <div className="textarea">                        
                        <Input.TextArea {...textAreaAttr} readOnly/>
                        <StatusUpdateAttachment                           
                            val={val}                                    /* Attachment input field */
                            setVal={setVal}
                        />
                    </div>
                </div>
                <div className="buttons">
                    <OkButton />
                    <Button className="no-button" onClick={() => setIsModalOpen(false)}>
                        No, Back to List
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ModalStatusUpdate;
