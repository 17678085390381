export const FEATURE_NAME = {
  // App Info
  DASHBOARD: "DASHBOARD",
  ORDER_SUMMARY: "ORDER_SUMMARY",
  ORDER_TRACK: 'ORDER_TRACK',

  //INCOMING
  INCOMING: "INCOMING",
  CUSTOMER_SELF_LODGE: "CUSTOMER_SELF_LODGE",
  PENDING_PICKUP: "PENDING_PICKUP",
  PENDING_INBOUND: "PENDING_INBOUND",

  //Outgoing
  OUTGOING: "OUTGOING",
  PENDING_OUTBOUND: "PENDING_OUTBOUND",
  READY_FOR_SELF_COLLECTION: "READY_FOR_SELF_COLLECTION",
  PENDING_FOR_DELIVERY: "PENDING_FOR_DELIVERY",
  ORDER_TIMELINE: "ORDER_TIMELINE",

  //Scan
  SCAN_LOAD_TO_BUS: "SCAN_LOAD_TO_BUS",
  SCAN_UNLOAD_FROM_BUS: "SCAN_UNLOAD_FROM_BUS",
  SCAN_CHECK_IN: "SCAN_CHECK_IN",
  SCAN_CHECK_OUT: "SCAN_CHECK_OUT",

  // Report
  REPORT: 'REPORT',

  //import
  IMPORTANT: "IMPORTANT",
  PARKED_ORDER: "PARKED_ORDER",

  ACCOUNT_PROFILE: "ACCOUNT_PROFILE",
  LOGOUT: "LOGOUT",
};

export const FEATURE_TYPE = {
  ROUTE: "ROUTE",
  LOGOUT: "LOGOUT",
};
