// React Thirty Party Library
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { Row, Col, Card, Skeleton, Button } from "antd";

// Constants Library
import { TEMPLATE } from "../../../../constants";

// Components Library

// Handler Library

// Services Library

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

// Styles Library
import styles from "../common.module.css";

const { DASHBOARD_ICON_FEATURE, DASHBOARD_ICON } = TEMPLATE.DASHBOARD;

const DashboardIcon = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const NavigateScan = (key) => {

        const { route, status, number } = DASHBOARD_ICON[key]

        if(key != DASHBOARD_ICON_FEATURE.track_order) {
            navigate(route, { state : { status, number }})
            dispatch(REDUX_ACTION.v1_order.get_order_pending_thru_hubid_status_request({ hubid: props.hubid, status: number }));
        } else {
            window.open(route, "_blank")
        }
    }

  return (
    <Row gutter={16}>
      {Object.keys(DASHBOARD_ICON_FEATURE).map((key, i) => (
        
        <Col span={8} key={`${key}#${i}`}>
          <Button
            className={styles["card-bg"]}
            key={`${key}#${i}`}
            disabled={DASHBOARD_ICON[key].disable}
            onClick={() => NavigateScan(key)}
          >
            {/* <Card
              bordered={false}
              className={styles["card-bg"]}
              key={`${key}#${i}`}
            > */}
            {/* <div className={styles["card-content"]}> */}
            <img
              src={DASHBOARD_ICON[key].icon}
              className={styles["card-icon-center"]}
              alt={DASHBOARD_ICON_FEATURE[key]}
            />
            <p
              style={{
                marginTop: "8px",
                whiteSpace: "normal",
                textAlign: "center",
                width: "100%",
              }}
            >
              {DASHBOARD_ICON[key].title}
            </p>
            {/* <div style={{ marginTop: "8px", width:"100%" }}>
                  {DASHBOARD_ICON[key].title}
                </div> */}
            {/* </div> */}
            {/* </Card> */}
          </Button>
        </Col>
      ))}
    </Row>
  );
};

export default DashboardIcon;
