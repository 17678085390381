// Third-party Library 
import { message } from 'antd'

// Constants 
import { ROUTES, COMMON_FEATURES, TEMPLATE } from "../../constants";

// Redux actions 
import * as REDUX_ACTION from "../../services/redux/actions"

const { ROUTE_URL } = ROUTES
const { FEATURE_NAME } = COMMON_FEATURES

// Get shipment order
export const SORenavigate = (soid) => dispatch => {
    
    message.loading("Getting shipment order...", 0)

    dispatch(
        REDUX_ACTION.v1_order.get_shipment_order_thru_soid_request(
            soid,
            () => message.destroy()
        )
    );
};

// // Get details of order if ordid clicked
// export const NavigateOrderSummary = (ordid) => navigate => {

//     navigate(ROUTE_URL[FEATURE_NAME.ORDER_SUMMARY], { state: { ordid } });

//     console.log(ordid)
// };