import * as API from "../../api";
import * as ActionTypes from "../action-types";

import {
    setLoadingGetHubThruOPUID,
    setLoadingHub,
    unsetLoadingGetHubThruOPUID,
    unsetLoadingHub
} from "./style"

import { message } from "antd";

export const get_hub_thru_operator_uid_request = (uid) => (dispatch) => {

    dispatch(setLoadingGetHubThruOPUID())

    API.getHubThruOperatorUID(uid)
    .then((info) => {
        const { status, hubs, msg } = info;

        if (status === 200) {
            dispatch(get_hub_thru_operator_uid_success(uid, hubs));
            message.success(msg)
        } else {
            message.error(msg);
        }
    })
    .finally(() => {
        dispatch(unsetLoadingGetHubThruOPUID())
    })
};

const get_hub_thru_operator_uid_success = (uid, hubs) => {
    return {
        type: ActionTypes.GET_HUB_THRU_OPERATOR_UID,
        uid,
        hubs,
    };
};

export const set_hub_selected_success = (selectedHub) => {
    return {
        type: ActionTypes.SET_SELECTED_HUB,
        selectedHub,
    };
};

export const remove_hub_success = () => {
    return {
        type: ActionTypes.REMOVE_SELECTED_HUB,
    };
};

export const get_hub_thru_hubid = (hubid) => (dispatch) => {

    dispatch(setLoadingHub())

    API
        .getHubByHubid(hubid)
        .then((info) => {
            const { status, hub, msg } = info;

            if (status === 200) {
                dispatch(get_hub_thru_hubid_success(hub));
            } else {
                message.error(msg);
            }
        })
    .finally(() => {
        dispatch(unsetLoadingHub())
    })
};

const get_hub_thru_hubid_success = (hub) => {
    return {
        type: ActionTypes.GET_HUB_BY_HUBID,
        hub,
    };
};
