import * as ActionTypes from "../action-types";

// Constants & Config

// Services

const STYLES_INITIAL_STATE = {
  loading: {
    general: false,
    hubs: true,
    order_log: false,
    hub_count: false,
    hubs_thru_opuid: false,
    pending: false,
    completed: false,
    parkOrder: false,
    orderComment: false,
    orderThruScan: false,
    report: false
  },
};

export const style = (state = STYLES_INITIAL_STATE, action) => {
  let newState = JSON.parse(JSON.stringify(state));

  if (!action) return newState;

  switch (action.type) {
    case ActionTypes.SET_LOADING_GENERAL: {
      newState.loading.general = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_GENERAL: {
      newState.loading.general = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_HUBS: {
        newState.loading.hubs = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_HUBS: {
      newState.loading.hubs = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_ORDER_LOG: {
        newState.loading.order_log = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_ORDER_LOG: {
      newState.loading.order_log = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_COUNT_HUBS: {
      newState.loading.hub_count = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_COUNT_HUBS: {
      newState.loading.hub_count = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_GET_HUB_THRU_OPUID: {
      newState.loading.hubs_thru_opuid = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_GET_HUB_THRU_OPUID: {
      newState.loading.hubs_thru_opuid = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_PENDING_TABLE: {
      newState.loading.pending = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_PENDING_TABLE: {
      newState.loading.pending = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_COMPLETED_TABLE: {
      newState.loading.completed = true;
      return newState;
    }

    case ActionTypes.UNSET_LOADING_COMPLETED_TABLE: {
      newState.loading.completed = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_PARK_ORDER: {
      newState.loading.parkOrder = true;
      return newState;
    }
    case ActionTypes.UNSET_LOADING_PARK_ORDER: {
      newState.loading.parkOrder = false;
      return newState;
    }

    case ActionTypes.SET_LOADING_PARK_ORDER_COMMENT: {
        newState.loading.orderComment = true;
        return newState;
    }
    case ActionTypes.UNSET_LOADING_PARK_ORDER_COMMENT: {
        newState.loading.orderComment = false;
        return newState;
    }

    case ActionTypes.SET_LOADING_GET_ORDER_THRU_SCAN: {
        newState.loading.orderThruScan = true;
        return newState;
    }

    case ActionTypes.UNSET_LOADING_GET_ORDER_THRU_SCAN: {
        newState.loading.orderThruScan = false;
        return newState;
    }

    case ActionTypes.SET_LOADING_REPORT: {
        newState.loading.report = true
        return newState;
    }

    case ActionTypes.UNSET_LOADING_REPORT: {
        newState.loading.report = false
        return newState;
    }

    default: {
      return state;
    }
  }
};
