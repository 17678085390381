// Components
// import ButtonModal from "../../../../../../components/ButtonModal";
// import { TEMPLATE } from "../../../../../../constants";

// Styles
import styles from "../../../../common.module.css";

// const { MODAL_ACTION } = TEMPLATE.MODAL;

const HeaderSidebar = (props) => {
  const { name, memid, profilePic } = props;

  return (
    <div className={styles["profile"]}>
      <img src={profilePic} alt="" />
      <h1 className={styles["profile-content"]}>{name || "Lee"}</h1>
      <p className={styles["profile-content"]}>{memid || "IPHQ00001"} </p>
      {/* <ButtonLink>Change Picture</ButtonLink> */}
      {/* <ButtonModal
        setmodal={MODAL_ACTION.cancel_order}
        onClick={() => {
          console.log(MODAL_ACTION.cancel_order);
        }}
      >
        test btn
      </ButtonModal> */}
    </div>
  );
};

export default HeaderSidebar;
