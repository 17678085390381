import { Modal } from "antd";

// eslint-disable-next-line import/namespace

import styles from "../../../../components/ButtonModal/modules/common.module.css";

const ModalImage = (props) => {
  const { isModalOpen, setIsModalOpen, dataOrder } = props;

  if (!dataOrder) {
    return null;
  }

  const modalWidth = 370 * dataOrder.length;

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={setIsModalOpen}
      width={modalWidth}
    >
      <div className={styles["image-container"]}>
        {dataOrder.map((item, index) => (
          <img
            key={index}
            src={item}
            alt={`remark ${index}`}
            className={styles["image"]}
            style={{
              marginLeft: -10,
            }}
          />
        ))}
      </div>
    </Modal>
  );
};

export default ModalImage;
