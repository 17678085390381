import { message } from "antd";
import * as API from "../../api";
import * as ActionTypes from "../action-types";

import { setLoadingPending, unsetLoadingPending } from "./style";

export const get_all_customers = () => (dispatch) => {
  dispatch(setLoadingPending());

  API.getAllCustomers()
    .then((info) => {
      const { status, users, msg } = info;

      if (status === 200) {
        dispatch(get_all_customers_success(users));
        message.success(msg);
      } else {
        message.error(msg);
      }
    })
    .finally(() => {
      dispatch(unsetLoadingPending());
    });
};

const get_all_customers_success = (customers) => {
  return {
    type: ActionTypes.GET_ALL_CUSTOMERS,
    customers,
  };
};
