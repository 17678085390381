// Third-party Library
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { List, Tag, Spin } from "antd";
import { EditOutlined, DeleteOutlined, LoadingOutlined } from "@ant-design/icons";

// Component
import TextArea from "../TextArea";

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../services/redux/actions";

// Styles
import classStyles from "../index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL;

const CommentLists = ({
    setEdit,
    setShowEditTextArea,
    setShowTextArea,
    edit,
    showEditTextArea,
    value,
    setValue,
    handleOkButton,
}) => {
    const dispatch = useDispatch();

    const user = useSelector(reduxStoreWrapper("user"))

    const orderStatus = useSelector(reduxStoreWrapper("orderStatus.byID"))
    const loading = useSelector(reduxStoreWrapper('style.loading.orderComment'))
    const comments = useSelector(reduxStoreWrapper(`orderComment.byCreatedAt`))

    const [index, setIndex] = useState("");

    const dataSource = 
        Object.values(comments)
            .sort((oca, ocb) => ocb.updatedAt - oca.updatedAt)
            .filter(oc => oc.active === 1)

    return (
        <>
            {
                loading
                &&
                <Spin 
                    size={'large'} 
                    indicator={<LoadingOutlined style={{fontSize: '5em'}} />} 
                    style={{width: '100%', marginTop: '20vh'}} 
                />
                ||
                <List
                    itemLayout="horizontal"
                    dataSource={dataSource}
                    renderItem={(cel, i) => {

                        // function for Edit and Delete
                        const isEditHDLR = () => {
                            setIndex(i);
                            setEdit(true);
                            setValue(cel);
                            setShowTextArea(false);
                            setShowEditTextArea(true);
                        };
                        const isDeleteHDLR = () => {
                            dispatch(
                                REDUX_ACTION.v1_orderComment.archive_order_comment_data(cel)
                            );
                        };

                        // Render the title of lists (name/memid) + edit/delete icons
                        const Title = () => {
                            return (
                                <div className={classStyles["list-header"]}>
                                    <>{cel.commentedBy} / {user.name}</>
                                    <div>
                                        <EditOutlined
                                            className={classStyles["icons"]}
                                            onClick={isEditHDLR}
                                        />

                                        <DeleteOutlined
                                            className={classStyles["icons"]}
                                            onClick={isDeleteHDLR}
                                        />
                                    </div>
                                </div>
                            );
                        };

                        // Render the contents (the comments itself and/or attachment link)
                        const Description = () => {
                            var url = cel.url;

                            return (
                            <>
                                Last updated at {TIME.parseTimeMin(cel.createdAt)}
                                <br />
                                <br />
                                <Tag color={orderStatus[cel.orderStatus]?.colorTag} key={cel.orderStatus}>
                                    {orderStatus[cel.orderStatus]?.display}
                                </Tag><br/><br/>
                                {cel.comment}
                                <br />
                                <br />
                                {(url && url !== "-") && (
                                    <a href={url} target="_blank">
                                        {url.substr(0, 20)}...
                                    </a>
                                )}
                            </>
                            );
                        };

                        // Main render
                        return (
                            <>
                                {/* 
                                    If it's in edit state, and in specific sequence of list, 
                                    the edit TextArea will be shown 
                                */}
                                {edit && i === index && showEditTextArea ? (
                                    <TextArea
                                        value={value}
                                        setValue={setValue}
                                        handleOkButton={handleOkButton}
                                        setShowTextArea={setShowEditTextArea}
                                    />
                                ) : (
                                    <List.Item style={{ padding: "30px 0" }}>
                                        <List.Item.Meta
                                            title={<Title />}
                                            description={<Description />}
                                        />
                                    </List.Item>
                                )}
                            </>
                        );
                    }}
                />
            }
        </>
    );
};
export default CommentLists;