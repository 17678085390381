// Components
import { ButtonLink } from "../../../../../../components";
import ButtonModal from "../../../ButtonModal";

// Constants
import { TEMPLATE } from "../../../../constants";

const { ORDER_STATUS } = TEMPLATE.ORDER;
// const { MODALS_ORDER_UPDATE } = TEMPLATE.MODAL;


export const ActionItems = (
    record,                         /**                       Notes                        **/
    addCommentHDLR,                 /*  The ActionItems sequences must be in tally          */
    SORenavigateHDLR,               /*  with the ActionItems in Pending (when passing)      */
    // status,                      /*  if not, the data will passed not as expected        */
    nextStatus,                     /*  following the rules from previous section (Pending) */
    action_link,

    ) => {

    return [
        {
            key: 0,
            label: (
                <ButtonModal
                    model="link"
                    order={record}
                    next_status={nextStatus}
                >
                    {action_link} { /* Action to Update Status */}
                </ButtonModal>
            ),
        },
        {
            key: 1,
            label: (
                <ButtonModal 
                    model="link" 
                    order={record}
                    next_status={ORDER_STATUS.PARKED}
                >
                    Park order
                </ButtonModal>
            ),
            onClick: () => {},
        },
        {
            key: 2,
            label: <ButtonLink>Print shipment order</ButtonLink>,
            onClick: () => {

                const {ordid} = record

                SORenavigateHDLR(ordid)
            },
        },
        {
            key: 3,
            label: <ButtonLink>Add comment</ButtonLink>,
            onClick: () => {

                const {ordid, status} = record

                addCommentHDLR({ordid, status})
            },
        },
    ];
};
