import React from "react";
import { Result } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

/**
 * Constant
 */
import { ROOT_CONSTANT } from '../../../../constants'

/**
 * Styles
 */
import './index.css'

const {
    THEME
} = ROOT_CONSTANT

const SSOLoadingPanel = () => {

    return (
        <Result
            icon={<LoadingOutlined style={{ fontSize: 96, color: THEME.THEME_COLOR }} spin/>}
            title="Loading..."
        />
    )

}

export default React.memo(SSOLoadingPanel);