// Third-party Library
import { useEffect, useState } from "react";
import { Table, Result } from "antd"
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";

// Constant
import { TEMPLATE, ROOT_CONSTANT } from "../../../../constants"

// Components Library
import { BtnSubmit } from "../../../../components";

// Handler Librarys
import { exportHDLR } from "../../../../handlers";

// Services Library
import { TIME, REDUX as REDUX_UTIL } from "../../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../../services/redux/actions";

const { reduxStoreWrapper } = REDUX_UTIL
const { parseExportTime } = TIME;

const {
    STATUS_LOG_COLUMN, 
    SCAN_LOG_COLUMN
} = TEMPLATE.REPORT.REPORT_HISTORY

const TableSearch = () => {

    const dispatch = useDispatch()

    // const customer = useSelector(reduxStoreWrapper("users_customer.byID"))
    const admin = useSelector(reduxStoreWrapper("users_admin.byID"))
    const hub = useSelector(reduxStoreWrapper("hubs.byID"))
    const point = useSelector(reduxStoreWrapper("points.byID"))
    const orderStatuses = useSelector(reduxStoreWrapper("orderStatus.byID"))
    const report = useSelector(reduxStoreWrapper('report'))
    const loading = useSelector(reduxStoreWrapper('style.loading.report'))

    const { byCreatedAt: reports, rtype: type } = report

    const [ filteredData, setFilteredData ] = useState([])
    const [ selectedRowKeys, setSelectedRowKeys ] = useState();

    // get admin credentials
    useEffect(() => {
        dispatch(REDUX_ACTION.v1_admin.get_all_admins_request());
    }, []) 

    // initialization for dataSource. since it did not immediately got as initialValue 
    useEffect(() => {
        setFilteredData(Object.values(reports))
    }, [reports])


    // selected data used for exporting selected order
    const selectedData = selectedRowKeys?.length > 0 && selectedRowKeys.map(key => ({
        key,
        ...reports[key]
    })) || Object.values(reports)

    // handler for search, for filtering data shown in table
    const handleSearch = (name, keyword) => {

        const filteredKeyword = Object.values(reports).filter(report => report[name]?.includes(keyword))

        setFilteredData(filteredKeyword)
    };

    // to determine the column, whether Status or Scan Log
    const SELECTED_COLUMN = (xp) => {  // xp is export param. truthy for export
        switch(type) {
            case "status": {
                return STATUS_LOG_COLUMN({
                    redux: {
                        admin,
                        // customer,
                        hub,
                        point,
                        orderStatuses
                    },
                    func: {
                        handleSearch,
                    },
                    exportState: xp ? true : false
                })
            }
            case "scan": {
                return SCAN_LOG_COLUMN({
                    redux: {
                        hub,
                        point,
                    },
                    func: {
                        handleSearch
                    },
                    exportState: xp ? true : false
                })
            }
            default:
                break;
        }
    }

    return (
        <> 
            {
                loading
                &&
                <Result
                    icon={<LoadingOutlined style={{ fontSize: 96, color: '#F08800', marginTop: '25vh' }} spin/>}
                    title="Loading..."
                />
                ||
                (
                    type
                    &&
                    <div style={{ margin: '1.5em auto' }}>
                        <div style={{ display: 'flex', justifyContent: 'right', marginBottom: '1.5em' }}>
                            <BtnSubmit
                                text={"Export"}
                                bgColor="transparent"
                                color={"#4CAF50"}
                                padding="9px 18px"
                                onClick={() =>
                                    exportHDLR.exportWithTemplate({
                                        data: selectedData,
                                        filename: `${type === 'status' ? 'STATUS' : 'SCAN'}_LOG_REPORT_SUMMARY_${parseExportTime()}`,
                                        template: SELECTED_COLUMN(1)
                                })}
                                showIcon={true}
                                icon={ROOT_CONSTANT.IMG.ICON.icon_export}
                                icon_alt={"export"}
                            />
                        </div>
                        <Table 
                            columns={SELECTED_COLUMN().map((c) => ({
                                ...c,
                                textWrap: "word-break",
                                ellipsis: true,
                            }))}
                            dataSource={filteredData.map(data => ({ key: data.createdAt, ...data }))}
                            pagination={{
                                position: ['topRight']
                            }}
                            rowSelection={{
                                selectedRowKeys,
                                onChange: (newSelectedRowKeys) => setSelectedRowKeys(newSelectedRowKeys)
                            }}
                            // scroll={{
                            //     x: 1200
                            // }}
                        />
                    </div>
                )
            }
        </>
    )
}

export default TableSearch