// React Thirty Party Library
import { TreeSelect } from "antd";

// Constants Library

// Components Library

// Handler Library

// Services Library

// Redux Actions

// Styles Library

const { SHOW_PARENT } = TreeSelect;

const FilterSelect = ({
  selection,
  selectedData,
  onChangeSelect,
  text,
  style
}) => {
  return (
    <>
      <TreeSelect
        allowClear
        treeCheckable
        showCheckedStrategy={SHOW_PARENT}
        value={selectedData}
        maxTagCount="responsive"
        onChange={onChangeSelect}
        placeholder={text}
        style={ style || { width: 250 }}
        treeData = {selection}
        // popupMatchSelectWidth={false}
      />
    </>
  );
};

export default FilterSelect;
