import { 
    message
} from "antd"

import styles from "./index.module.css"

const ButtonCopy = (props) => {

    const {copiedtext} = props

    const handleCopy = (copiedtext) => {
        navigator.clipboard.writeText(copiedtext);
        message.success("Copied!");
    }

    return (
        <div {...props}>
            <button
                className={styles.copyBTN}
                onClick={() => handleCopy(copiedtext)}
            >
                Copy
            </button>
        </div>
    )
}

export default ButtonCopy;