// Third-party Library 
import { useNavigate } from "react-router";
import { Form, Row, Col, Input } from "antd";
import { LeftOutlined } from "@ant-design/icons";

// Components
import { BtnSubmit } from "../../../../../../components"

// Styles Library
import commonStyle from "../../../../pages/index.module.css";
import style from '../index.module.css'

const HeaderScan = ({
    setOrdid,
    onFinish,
    action,
    clearTableHDLR
}) => {

    const navigate = useNavigate()
    const [ form ] = Form.useForm()

    return (
        <div className={commonStyle.header}>
            <div className={style["back-main"]}>
                <LeftOutlined onClick={() => navigate(-1)} style={{fontSize: '20px'}}/>
                <p className={style["back-text"]}>Scan to {action}</p>
            </div>
            <Row justify={'space-between'}>
                <Col>
                    <Form
                        form={form}
                        onFinish={onFinish}
                        style={{display: 'flex'}}
                    >
                        <Form.Item name='ordid'>
                            <Input 
                                className={style['form-input']}
                                type="text"
                                placeholder="Enter Order ID"
                                onChange={e => setOrdid(e.target.value.replace(/\s/g, ''))}
                            />
                        </Form.Item>
                        <BtnSubmit 
                            text={'Enter'}
                            padding="9px 18px"
                            bgColor="var(--themeBackgroundColor)"
                            color="var(--creamColor)"
                        />
                    </Form>
                </Col>
                <Col>
                    <BtnSubmit 
                        text={'Clear'}
                        padding="9px 18px"
                        bgColor="var(--themeBackgroundColor)"
                        color="var(--creamColor)"
                        onClick={clearTableHDLR}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default HeaderScan