// React Thirty Party Library
import React from "react";
import { Row, Col, Card } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

// Constants Library
import { TEMPLATE, ROUTES, COMMON_FEATURES } from "../../../../constants";

// Components Library

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL, TIME } from "../../../../services/util";

// Redux Actions

// Styles Library
import styles from "../common.module.css";

const {
    INCOMING_INDEX,
    INCOMING_FEATURE,
    OUTCOMING_INDEX,
    OUTCOMING_FEATURE,
    IMPORTANT_INDEX,
    IMPORTANT_FEATURE,
} = TEMPLATE.DASHBOARD;

const { ORDER_STATUS } = TEMPLATE.ORDER;

const { ROUTE_URL } = ROUTES;

const { reduxStoreWrapper } = REDUX_UTIL;

const ProgressSummary = () => {
    const status = useSelector(reduxStoreWrapper("hubs.byCount"));
    const hub = useSelector(reduxStoreWrapper("hub.code"))
    // const loading = useSelector(reduxStoreWrapper("style.loading.hub_count"));

    const navigate = useNavigate();

    let incomingTotal = 0;
    let outgoingTotal = 0;

    Object.values(INCOMING_INDEX).forEach((key) => {
        incomingTotal += status[key];
    });

    Object.values(OUTCOMING_INDEX).forEach((key) => {
        outgoingTotal += status[key];
    });

    // Object.values(IMPORTANT_INDEX).forEach((key) => {
    //   importantTotal += status[key];
    // });

    const navigatePage = (page) => {
        navigate(ROUTE_URL[COMMON_FEATURES.FEATURE_NAME[page]]);
    }

    return (
        <Row gutter={24}>
            <Col span={8}>
                {/* <h2 className={styles["label-title"]}>
                    Incoming ({incomingTotal})
                </h2> */}
                {/* {
                    Object.values(INCOMING_INDEX).map((key, i) => {
                    
                        const route = INCOMING_FEATURE[key].route;

                        return ( */}
                            <Card
                                bordered={false}
                                className={styles["progress-card-bg"]}
                                // key={`${key}#${i}`}
                                // loading={loading}
                                onClick={() => navigatePage(INCOMING_FEATURE[INCOMING_INDEX.pending_inbound].route)} 
                            >
                                {/* {
                                loading ? (
                                    <Skeleton />
                                ) : ( */}
                                    <div className={styles["progress-card-content"]}>
                                        <div>Pending Inbound to {hub}</div>
                                        <div>
                                            <span style={{ marginRight: "5px" }}>
                                                <strong>{status.INBOUND}</strong>
                                            </span>
                                            <CaretRightOutlined onClick={() => navigatePage(INCOMING_FEATURE[INCOMING_INDEX.pending_inbound].route)} />
                                        </div>  
                                    </div>
                            {/* )} */}
                        </Card>
                    {/* );
                })} */}
            </Col>
            <Col span={8}>
                {/* <h2 className={styles["label-title"]}>
                    Outgoing ({outgoingTotal})
                </h2> */}
                {/* {
                    Object.values(OUTCOMING_INDEX).map((key, i) => {
                        const route = OUTCOMING_FEATURE[key].route;
                        return ( */}
                            <Card
                                bordered={false}
                                className={styles["progress-card-bg"]}
                                // key={`${key}#${i}`}
                                // loading={loading}
                                onClick={() => navigatePage(OUTCOMING_FEATURE[OUTCOMING_INDEX.pending_outbound].route)} 
                            >
                                {/* {loading ? (
                                    <Skeleton />
                                ) : ( */}
                                    <div className={styles["progress-card-content"]} >
                                        <div>Pending Outbound from {hub}</div>
                                        <div>
                                            <span style={{ marginRight: "5px" }}>
                                            <strong>{status.OUTBOUND}</strong>
                                            </span>
                                            <CaretRightOutlined onClick={() => navigatePage(OUTCOMING_FEATURE[OUTCOMING_INDEX.pending_outbound].route)} />
                                        </div>
                                    </div>
                                {/* )} */}
                            </Card>
                        {/* );
                    })
                } */}
            </Col>
            <Col span={8}>
                {/* <h2 className={styles["label-title"]}>
                    Important ({status[ORDER_STATUS.PARKED]})
                </h2> */}
                {/* {Object.values(IMPORTANT_INDEX).map((key, i) => {

                    const route = IMPORTANT_FEATURE[key].route;
                    const isActive = key === "O4200"; */}

                    {/* return ( */}
                        <Card
                            bordered={false}
                            className={
                                // isActive ?
                                styles["progress-card-bg"]
                                // : 
                                // styles["progress-card-bg-disable"]
                            }
                                // key={`${key}#${i}`}
                                // loading={loading}
                                onClick={() => navigatePage(IMPORTANT_FEATURE[IMPORTANT_INDEX.parked].route)} 
                        >
                            {/* {loading ? (
                                <Skeleton />
                            ) : ( */}
                                <div className={styles["progress-card-content"]}>
                                <div>Parked Order</div>
                                {/* {isActive && ( */}
                                    <div>
                                        <span style={{ marginRight: "5px" }}>
                                            <strong>{status.PARKED}</strong>
                                        </span>
                                        <CaretRightOutlined onClick={() => navigatePage(IMPORTANT_FEATURE[IMPORTANT_INDEX.parked].route)} />
                                    </div>
                                 {/* )}  */}
                                </div>
                            {/* )} */}
                        </Card>
                    {/* );
                })} */}
            </Col>
        </Row>
    );
};

export default ProgressSummary;
