import { useState } from "react";
import { Button, Tag } from "antd";

import { ButtonLink } from "../../components";

import { ModalStatusUpdate } from "./modules";

const ButtonModal = (props) => {
  const {
    children,
    setmodal,
    model = "",
    disabled,
    data,
    order = {}, 
    next_status = ""
  } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = () => {
    setIsModalOpen(true);
  };

  const handleModalContext = (setmodal) => {
        return (
          <ModalStatusUpdate
            isModalOpen={isModalOpen}
            setIsModalOpen={() => setIsModalOpen(false)}
            data={data}
            next_status={next_status}
            order={order}
          />
        );
  };

  return (
    <>
      {handleModalContext(setmodal)}

      {(model === "link" && (
        <ButtonLink {...props} onClick={handleModal} disabled={disabled}>
          {children}
        </ButtonLink>
      )) ||
        (model === "tag" && <Tag color="orange">{children}</Tag>) || (
          <Button {...props} onClick={handleModal}>
            {children}
          </Button>
        )}
    </>
  );
};
export default ButtonModal;
