// Third-party Library
import { useNavigate } from "react-router";
import { LeftOutlined } from "@ant-design/icons";

// Styles
import classes from "../../index.module.css";

const TimelineHeader = ({ ordid }) => {
  const navigate = useNavigate();

  return (
    <span className={classes["header-timeline"]}>
      <LeftOutlined onClick={() => navigate(-1)} />
      <p>{ordid}</p>
    </span>
  );
};

export default TimelineHeader;
