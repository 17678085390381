// Third-party Library
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Radio, Button, Spin, Row, Col } from "antd";
import { CheckCircleFilled, LoadingOutlined } from "@ant-design/icons";

// Components
import { CopyrightFooter, HeaderBar } from "../../components";

// Services Library
import { REDUX as REDUX_UTIL } from "../../services/util";

// Redux Actions 
import * as REDUX_ACTION from "../../services/redux/actions"


import classStyles from "./index.module.css";

const { reduxStoreWrapper } = REDUX_UTIL

const SelectHubPage = () => {
    const dispatch = useDispatch()

    const hubsByOP = useSelector(reduxStoreWrapper("hub.hubsByOP"));
    const uroleid = useSelector(reduxStoreWrapper("user.uroleid"))
    const loading = useSelector(reduxStoreWrapper("style.loading.hubs_thru_opuid"))

    const [ value, setValue ] = useState()
    
    const setCurrentHubHDLR = () => {
        dispatch(REDUX_ACTION.v1_hub.set_hub_selected_success(value))
    }

    return (
        <>
            <HeaderBar />
            <div className={classStyles.container}>
                <Row className={classStyles.contextLayout}>
                    <Col span={12}>
                        <div className={classStyles["signup-bg"]} alt="" />
                    </Col>
                    <Col span={12}>
                        <div className={classStyles["container-select"]}>
                            <h1 className={classStyles["title"]}>Select a {uroleid === 'HOP' ? 'Hub' : 'Point'}.</h1>
                    
                            {
                                loading
                                &&
                                <Spin 
                                    size={'large'} 
                                    indicator={<LoadingOutlined style={{fontSize: '5em'}} />} 
                                    style={{ margin: '7vh 0'}}
                                />
                                ||
                                <Radio.Group 
                                    onChange={(e) => setValue(e.target.value)} 
                                    value={value}
                                    className={classStyles["select-hub-button"]}
                                >
                                    {hubsByOP.map((hub, i) => {
                                        
                                        return (
                                            <div key={i}>
                                                <Radio.Button
                                                    value={{ hubid: hub?.hubid, code: hub?.code }}
                                                    className={classStyles["hub-radio"]}
                                                >
                                                    <div className={classStyles["hub-name"]}>
                                                        {hub?.code} {uroleid === 'HOP' ? 'Hub' : 'Point'}
                                                        {
                                                            (value && hub?.hubid === value?.hubid) 
                                                            && 
                                                            <CheckCircleFilled
                                                                style={{
                                                                    position: "absolute",
                                                                    right: "3px",
                                                                    top: "5px",
                                                                    fontSize: "20px",
                                                                }}
                                                            />
                                                        }
                                                    </div>
                                                </Radio.Button>
                                                <br />
                                            </div>
                                        )
                                    })}
                                </Radio.Group>
                            }

                            <div>
                                <Button 
                                    className={classStyles["hub-btn"]} 
                                    onClick={setCurrentHubHDLR}
                                    disabled={loading || !hubsByOP.length || !value}
                                >
                                    Next
                                </Button>
                        <CopyrightFooter />
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
        </>
    );
};

export default SelectHubPage;
