import { Input } from 'antd'

import { BtnSubmit } from '../../../../../components'
import CommentAttachment from '../CommentAttachment'

import classStyles from "../index.module.css"

const TextArea = ({
    value, 
    setValue,
    handleOkButton,
    setShowTextArea,
}) => {
  
    return (
        <div className={classStyles["text-area"]}>
            <Input.TextArea
                value={value.comment}
                onChange={(e) => setValue({
                    ...value,
                    comment: e.target.value
                })}
                placeholder="Type your remarks here..."
                autoSize={{
                    minRows: 10,
                    maxRows: 20,
                }}
            />
                <div className={classStyles["buttons"]}>
                    <div className={classStyles["attachment"]}>
                        <CommentAttachment 
                            value={value}
                            setValue={setValue}
                        />
                    </div>
                    <div className={classStyles["button"]}>
                        <BtnSubmit
                            display="inline-block"
                            text="Save"
                            bgColor="var(--themeBackgroundColor)"
                            color="var(--creamColor)"
                            padding="10px 30px"
                            onClick={handleOkButton}
                        />
                        <BtnSubmit
                            display="inline-block"
                            text="Cancel"
                            bgColor="transparent"
                            color="var(--bs-gray-600)"
                            padding="10px 18px"
                            onClick={() => {
                                setShowTextArea(false)
                                setValue(false)
                            }}
                        />
                    </div>
            </div>
        </div>
    )
}

export default TextArea
