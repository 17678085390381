// React Thirty Party Library
import { Tag, Typography, Tooltip } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

// Constant
import { TEMPLATE } from "../..";
import { IMG } from "../../../../../constants";

// Components
import { ButtonLink } from "../../../components";

//handlers 
import { filter, reactHDLR } from "../../../handlers";

// Service
import { TIME } from "../../../services/util";

const { parseTimeMin, parseTime, parseTimeMinDayFirst } = TIME;

const { searchHDLR, filterHDLR } = filter;

const { Text } = Typography;

// const { icon_comment_parcel } = IMG.ICON;
const {
  COMPLETED_TABLE_LIST_INDEX,
  COMPLETED_TABLE_LIST_TITLE,
  OPTIONS_COMPLETED_STATUS,
} = TEMPLATE.TABLELIST;
const { ORDER_STATUS } = TEMPLATE.ORDER;

export const COMPLETED_COLUMN = ({
  redux = {},
  func = {},
  exportState = false,
}) => {
  const { uroleid, admins, operator, orderStatuses } = redux;
  const { handleModal, NavigateOrderSummary } = func;
  return [
    {
      title: COMPLETED_TABLE_LIST_TITLE.ordid,
      key: COMPLETED_TABLE_LIST_INDEX.ordid,
      width: 100,
      fixed: "left",
      render: (record) => {
        const { ordid } = record;

        // return exportState ? ordid : <ButtonLink onClick={() => NavigateOrderSummary(ordid)}>{ordid}</ButtonLink>;
        return exportState ? ordid : <>{ordid}</>;
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE.status,
      key: COMPLETED_TABLE_LIST_INDEX.status,
      width: 360,
      render: (rowData) => {
        const { status } = rowData;

        const statusName = 'COMPLETED ' + (orderStatuses[status]?.display.toUpperCase() || '')
        const colorTag = orderStatuses[status]?.colorTag

        // // const { name, colorTag } = OPTIONS_COMPLETED_STATUS[status];

        return exportState ? (
          statusName
        ) : (
          <>
            <Tag color={colorTag} key={status}>
              {statusName}
            </Tag>
          </>
        );
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE[`${uroleid === 'HOP' ? 'hub':'point'}`],
      key: COMPLETED_TABLE_LIST_INDEX[`${uroleid === 'HOP' ? 'hub':'point'}`],
      width: 100,
      render: (rowData) => {
        const { code, hubid } = rowData;

        let hubName 

        if (hubid?.includes('#')) {
            const [hs, hn] = hubid.split('#')
            hubName =  `${hn.toUpperCase()} ${hs.charAt(0).toUpperCase()  + hs.slice(1)}`
        } 
        else if (hubid?.includes('-')) hubName = 'Admin'

        return exportState ? hubName : <>{hubName || `${code} ${uroleid === 'HOP' ? 'Hub':'Point'}`}</>;
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE.init_time,
      key: COMPLETED_TABLE_LIST_INDEX.init_time,
      width: 120,
      render: (rowData) => {
        const { initiatedAt } = rowData;

        return exportState ? (
          parseTimeMinDayFirst(initiatedAt)
        ) : (
          <>{parseTimeMinDayFirst(initiatedAt)}</>
        );
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE.complete_time,
      key: COMPLETED_TABLE_LIST_INDEX.complete_time,
      width: 120,
      render: (rowData) => {
        const { completedAt } = rowData;

        return exportState ? (
          parseTimeMinDayFirst(completedAt)
        ) : (
          <>{parseTimeMinDayFirst(completedAt)}</>
        );
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE.createdAt,
      key: COMPLETED_TABLE_LIST_INDEX.createdAt,
      width: 120,
      render: (record) => {
        const { createdAt } = record;

        return exportState ? (
          parseTimeMinDayFirst(createdAt)
        ) : (
          <>{parseTimeMinDayFirst(createdAt)}</>
        );
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE.updatedAt,
      key: COMPLETED_TABLE_LIST_INDEX.updatedAt,
      width: 120,
      render: (record) => {
        const { updatedAt } = record;
        return exportState ? (
          parseTimeMinDayFirst(updatedAt)
        ) : (
          <>{parseTimeMinDayFirst(updatedAt)}</>
        );
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE.createdBy,
      key: COMPLETED_TABLE_LIST_INDEX.createdBy,
      width: 200,
      render: (rowData) => {
        const { updatedBy, code } = rowData;

        const adminData = admins[updatedBy];
        const adminName = adminData ? adminData.name : "-";

        const name = adminData ? adminName : operator.name;
        const role = adminData ? "Admin" : `Hub Operator ${code}`;

        return exportState ? (
          `${updatedBy}/ ${name}/ ${role}`
        ) : (
          <>
            {updatedBy}/ {name}/ {role}
          </>
        );
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE.revision,
      key: COMPLETED_TABLE_LIST_INDEX.revision,
      width: 70,
      render: (rowData) => {
        const { revision } = rowData;
        return exportState ? revision : <>{revision}</>;
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE.remark,
      key: COMPLETED_TABLE_LIST_INDEX.remark,
      width: 180,
      render: (rowData) => {
        const { remark } = rowData;
        return exportState ? remark : <>{remark}</>;
      },
    },
    {
      title: COMPLETED_TABLE_LIST_TITLE.attachment,
      key: COMPLETED_TABLE_LIST_INDEX.attachment,
      width: 180,
      render: (rowData) => {
        const { attachment } = rowData;

        let urls = [];

        if (Array.isArray(attachment) && attachment.length > 0) {
          urls = attachment.map((obj) => obj.url);
        }

        if (exportState === true) {
          return urls;
        } else {
          if (
            (attachment && attachment.length === 0) ||
            attachment === undefined
          ) {
            return "";
          } else {
            return (
              <ButtonLink onClick={() => handleModal(urls)} target="_blank">
                View
              </ButtonLink>
            );
          }
        }
      },
    },
  ];
};
