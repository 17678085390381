// React Thirty Party Library
import React from "react";

// Components Library
import BankInformation from "./BankInformation";

// Styles Library
import styles from "../common.module.css";

const TopUpInformation = () => {

    return (
        <div className={styles.containerTopUpInformation}>
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <h2 className={styles["label-title"]}>Top Up Information</h2>
            </div>
            <BankInformation
                style={{
                    backgroundColor: "#FBFAFF",
                }}
            />
        </div>
    );
};

export default TopUpInformation;
