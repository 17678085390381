// React Thirty Party Library
import { useSelector, useDispatch } from "react-redux";
import {
  Layout as LayoutAntd,
  Avatar,
  Space,
  Button,
  Select,
  Col,
  Row
} from "antd";
import { useLocation } from "react-router";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from "@ant-design/icons";

// Constants Library
import { ROOT_CONSTANT, ROUTES } from "../../../constants";

// Components Library

// Handler Library

// Services Library
import { REDUX as REDUX_UTIL } from "../../../services/util";

// Redux Actions
import * as REDUX_ACTION from "../../../services/redux/actions";

// Styles Library
import "../../index.css";

const { Header } = LayoutAntd;

const { LOGO } = ROOT_CONSTANT.IMG.ICON;
const { ROUTE_TITLE_PATH } = ROUTES;

const { reduxStoreWrapper } = REDUX_UTIL;

const LayoutHeader = ({
    collapsed,
    setCollapsed
}) => {
    const dispatch = useDispatch();

    const selectedHub = useSelector(reduxStoreWrapper("hub.code"));
    const user = useSelector(reduxStoreWrapper("user"));
    const hubsByOP = useSelector(reduxStoreWrapper("hub.hubsByOP"))

    const path = useLocation();
    const title = ROUTE_TITLE_PATH[path.pathname] || "";

    const setCurrentHubHDLR = (value) => {
        const [hubid, code] = value.split(",");

        dispatch(REDUX_ACTION.v1_hub.set_hub_selected_success({ hubid, code }));
    };

    return (
        <Header
            className="sidebar-header-container"
            style={{
                width: collapsed ? "calc(100% - 80px)" : "calc(100% - 28vh)",
                transition: "width 0.2s",
            }}
        >
            <div className="sidebar-header-title">
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: "16px",
                        width: 64,
                        height: 64,
                    }}
                />
                <h3 style={{ fontSize: "20px", fontWeight: "bold" }}>{title}</h3>
            </div>
            <Space style={{ marginRight: "25px" }}>
                <Row className="sidebar-header-edge" gutter={24}>
                    <Col>
                        {
                            user.uroleid
                            &&
                            <Select
                                defaultValue={`${selectedHub} ${user?.uroleid === 'HOP' ? 'Hub' : 'Point'}`}
                                style={{
                                    width: 120,
                                    textTransform: "uppercase",
                                }}
                                bordered={false}
                                onChange={e => setCurrentHubHDLR(e)}
                            >
                                {hubsByOP.map((hub, index) => {
                                    return (
                                        <Select.Option
                                            key={index}
                                            value={`${hub?.hubid},${hub?.code}`}
                                            label={`${hub?.code} ${user.uroleid === 'HOP' ? 'Hub' : 'Point'}`}
                                            style={{ textTransform: "uppercase" }}
                                        >
                                            {`${hub?.code} ${user.uroleid === 'HOP' ? 'Hub' : 'Point'}`}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        }
                    </Col>
                    <Col>
                        <Space>
                            <Avatar size={40} src={LOGO.ipick} className="header-avatar" />
                            <div>
                                <p className="adm-name">{user.name}</p>
                                <p className="adm-id">{user.memid}</p>
                            </div>
                        </Space>
                    </Col>
                </Row>
            </Space>
        </Header>
    );
};

export default LayoutHeader;
