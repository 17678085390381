import { Modal } from "antd";

// eslint-disable-next-line import/namespace

import styles from "../../index.module.css";

const ModalImage = (props) => {
  const { isModalOpen, setIsModalOpen, dataOrder } = props;

  if (!dataOrder) {
    return null;
  }

  const modalWidth = 370 * dataOrder.length;

  return (
    <>
      {typeof dataOrder === "string" && (
        <Modal
          open={isModalOpen}
          footer={null}
          onCancel={setIsModalOpen}
          width={360}
        >
          <div className={styles["image-container"]}>
            <img
              src={dataOrder}
              alt="transaction"
              style={{ width: 290, height: 450 }}
            />
          </div>
        </Modal>
      )}

      {Array.isArray(dataOrder) && (
        <Modal
          open={isModalOpen}
          footer={null}
          onCancel={setIsModalOpen}
          width={modalWidth}
        >
          <div className={styles["image-container"]}>
            {dataOrder.map((item, index) => (
              <img
                key={index}
                src={item.url}
                alt={`transaction ${index}`}
                className={styles["image"]}
                style={{
                  marginLeft: -10,
                }}
              />
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};

export default ModalImage;
