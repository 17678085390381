import { combineReducers } from "redux";

// V1 Reducer
import { user } from "./user";
import { hub } from "./hub"
import { hubs } from "./hubs";
import { points } from './points';
import { report } from "./report"
import { users_customer } from "./users_customer";
import { users_admin } from "./users_admin";
import { order } from "./order";
import { order_completed } from "./order_completed"
import { order_park } from "./order_park";
import { order_scan } from "./order_scan"
import { orderComment } from "./orderComment"
import { orderStatus } from "./orderStatus";
import { orderLog } from "./orderLog"
import { style } from "./style";
import { vehicle } from "./vehicle";

export const v1Reducer = combineReducers({
    user,
    hub,
    hubs,
    points,
    report,
    order,
    order_completed,
    order_park,
    order_scan,
    orderComment,
    orderStatus,
    orderLog,
    users_customer,
    users_admin,
    style,
    vehicle,
});
