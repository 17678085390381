import * as API_CONSTANT from '../constants'

export const getReportList = (items) => (
    fetch(`${API_CONSTANT.hub_v1_fe_path}/report/timeframe/generate`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(items)
    })
    .then(res => res.json())
)

export const getReportStatusLog = (items) => (
    fetch(`${API_CONSTANT.hub_v1_fe_path}/report/status/log`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(items)
    })
    .then(res => res.json())
)

export const getReportScanLog = (items) => (
    fetch(`${API_CONSTANT.hub_v1_fe_path}/report/scan/log`, {
        method: 'POST',
        headers: API_CONSTANT.headers,
        body: JSON.stringify(items)
    })
    .then(res => res.json())
)
